export function getUrlParams(urlParam:string) {
    let params = {}

    //URLパラメータを文字列で取得(?含む)
    if (!urlParam) {return params;}
    //?を除去
    urlParam = urlParam.substring(1)

    //urlパラメータをオブジェクトにまとめる
    urlParam.split('&').forEach( param => {
        const temp = param.split('=')
        //pramsオブジェクトにパラメータを追加
        params = {
        ...params,
        [temp[0]]: temp[1]
        }
    })
    return params;
}


export function shuffleArrayValues(array:number[]){
        // Fisher–Yates アルゴリズムでシャッフルする
    for(var i = array?.length - 1; i > 0; i--){
        var rand = Math.floor(Math.random() * (i + 1));
        [array[i], array[rand]] = [array[rand], array[i]];
    }

    return array;
}
