import React from 'react';
import * as common from "../../common";

import TextField from '@material-ui/core/TextField';
import { Layout } from '../Layout';
import { createUseStyles } from 'react-jss';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { Learner ,APIResponse ,AnswerCSV} from '../../react-app-env';
import {saveTextFile} from "../../common";
import { Exam } from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import { initialExamStateValue} from '../Exam/Index';



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    homeRoot: {
        fontSize: common.FONT_SIZE.mainText,
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },

    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px"
    },
    buttonStyle: {
        justifyContent: "center",
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginRight:"18px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    links: {
        "& :hover":{
            color:"white",
            opacity: 1,
            textDecoration:"none"
        },
        marginBottom:"20px"
    },
    formStyle: {
        height:"8rem",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        pointerEvents :"none",
        "& .MuiInputBase-input": {
            width:"210px"
        },
        "& input ,.MuiInputBase-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& th":{
            fontSize:"1.8rem"
        }
    },
    labelStyle: {
        paddingRight: "60px"
    },
    mainContainer: {
          display:"flex",
          justifyContent:"center",
    },
    centerItemStyle: {
      margin:"50px auto",
      textAlign:"center"
    },
    inputFormContainer : {
        display: "flex",
        justifyContent: "space-between",
    }
});


export const initialAnswerStateValue:AnswerCSV = {
    learnerID:999999,
    learnerNumber:"初期値",
    email:"初期値",
    executionId:999999,
    questionId:999999,
    subjectText:"初期値",
    choiceId:999999,
    answerNum:1,
    hitFlag:false,
    selectedId:999999,
    selectedNum:0,
    pass:false,
    points:0
};






export function ExportAnswer() {
 
    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();



    // -----API-----
    //試験IDを引数に渡して受験者リスト取得
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    //試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    //試験データを取得する
    function getAnswerData(args?: any):Promise<APIResponse<AnswerCSV[]>> {
        return api("/api/o-answerCSV", "GET", args)
    }



    // -----Handler-----
    //受付済みの受験者データリストをダウンロード
    const getAcceptedCSV=()=>{
       
            const answerData:any = answerStateValue.map(data=>(
                {
                    "受験者ID" : data.learnerID,
                    "受験番号" : data.learnerNumber,
                    "受験者メールアドレス": data.email,
                    "試験ID": data.executionId,
                    "問題ID": data.questionId,
                    "問題件名" : data.subjectText,
                    "問題選択肢ID": data.choiceId,
                    "正解番号":data.answerNum,
                    "正解フラグ": data.hitFlag,
                    "解答選択肢ID": data.selectedId,
                    "解答番号":data.selectedNum,
                    "解答判定フラグ":data.pass,
                    "点数":data.points
                }
            ));
            getAnswerCSV(answerData)
        
    }


    




    // // -----汎用関数の宣言-----
    
    const getAnswerCSV = (answerData: any) => {
        // CSVヘッダー
        const answerHeader: string[] = ["受験者ID", "受験番号", "受験者メールアドレス", "試験ID", "問題ID", "問題件名", "問題選択肢ID",
            "正解番号", "正解フラグ", "解答選択肢ID", "解答番号", "解答判定フラグ", "点数"];
        //const learnerData:any = getAcceptedCSV();
        common.toCsv(answerData, { noHeader: false, cols: answerHeader })
        .then((result)=>{
            saveTextFile(result,examStateValue.name+"解答データ.csv");
        }).catch((err) => {
            alert(err);
        }); 
    };
    

    //受験済み人数を算出する
    const getTestedLearnerNum = (learnersStateValue:Learner[])=>{
        let count = 0;

        for (const learner of learnersStateValue) {
            if(learner.executionStartDatetime !== null){
                count = count + 1;
            }
        }

        setTestedLearnerNum(count);
        
    }
   
  

    // -----スタイルの宣言-----
    const classNames = useStyles();

   



    // -----state-----
    
    const [examStateValue,setExamStateValue]=React.useState<Exam>(initialExamStateValue)
    const [answerStateValue,setAnswerStateValue]=React.useState<AnswerCSV[]>([initialAnswerStateValue])

    const [testedLearnerNum,setTestedLearnerNum]=React.useState(0)
    
    // -----use effefct-----
    React.useEffect(() => {
        //試験名を取得
        getExamData({id:params.examId})
        .then((data:APIResponse<Exam[]>) => {
            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }
            
            if(data.value[0] !== null) setExamStateValue(data.value[0]);


        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });


        //受験者解答情報を取得
        getAnswerData({executionId:params.examId})
        .then((data:APIResponse<AnswerCSV[]>) => {
            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }
            
            if(data.value[0] !== null) setAnswerStateValue(data.value);


        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });


        // 受験者情報を取得
        getLearnerData({executionId:params.examId})
            .then((data:APIResponse<Learner[]>) => {
                if (data.errorCode !==20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null) getTestedLearnerNum(data.value);

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);
  


    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.homeRoot}>

                <div className={classNames.centerItemStyle}>
                    <h4>{examStateValue.name} 解答データのエクスポート</h4>
                </div>

                <div className={classNames.mainContainer}>

                    <table>
                        <tbody>
                            <tr className={classNames.formStyle}>
                                <th className={classNames.labelStyle}><label>試験名称</label></th>
                                <td><TextField fullWidth multiline value={examStateValue.name}  required InputProps={{
                                    readOnly: true,disableUnderline: true 
                                }}/></td>
                            </tr>
                            
                            <tr className={classNames.formStyle}>
                                <th  className={classNames.labelStyle}><label>実施期間</label></th>
                                <td className={classNames.inputFormContainer}>
                                    <div >
                                        {common.dateFormat(examStateValue.executeStart, "LLL")}
                                    </div>
                                    
                                    <div style={{margin:"0 10px"}}>～</div>                          
                                    
                                    <div>
                                        {common.dateFormat(examStateValue.executeEnd, "LLL")}
                                    </div>
                                </td>
                            </tr>

                            <tr className={classNames.formStyle}>
                                <th  className={classNames.labelStyle}><label>試験時間</label></th>
                                <td><TextField style={{width:"100px"}} fullWidth value={examStateValue.executeTime+"　分"}  required InputProps={{
                                    readOnly: true,disableUnderline: true 
                                }}/></td>
                            </tr>

                            <tr className={classNames.formStyle}>
                                <th  className={classNames.labelStyle}><label>最大人数</label></th>
                                <td><TextField style={{width:"100px"}} fullWidth value={examStateValue.learnerMax+"　人"}  required InputProps={{
                                    readOnly: true,disableUnderline: true 
                                }}/></td>
                            </tr>
                            
                            <tr className={classNames.formStyle}>
                                <th  style={{paddingRight: "44px"}}><label>受験済人数</label></th>
                                <td><TextField style={{width:"100px"}} fullWidth value={testedLearnerNum+"　人"}  required InputProps={{
                                    readOnly: true,disableUnderline: true 
                                }}/></td>
                            </tr>
                            
                        </tbody>
                    </table>

                </div>


                <div >
                    <ButtonContainer className={classNames.buttonStyle} style={{marginTop:"50px"}}>
                        <DefaultBackButton className="backBtn" onClick={() => { go({pathname:"/exam/index/"+params.examId}) }}>戻る</DefaultBackButton>
                        <DefaultButton  onClick={getAcceptedCSV}>エクスポート</DefaultButton>
                    </ButtonContainer>
                </div>

            </div>
        </Layout>
    );
}
