import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import {RichEditor,toPlainText} from '../RichEditor/RichEditor'
import { Question as IQuestion } from '../../react-app-env';
import * as common from "../../common";
import { ChoicesTable } from '../Table/ChoicesTable';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import {DefaultButton} from "../StylesUI/CommonLayouts";
import { makeStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    questionRoot:{
        "& svg":{
            fontSize:"2rem"
        },
        fontSize:common.FONT_SIZE.mainText
    },
    toolbar:{
        color:"white",
        background:"gray",
        minHeight:"36px"
    },
    titleText : {
        margin:"auto",
        fontSize:common.FONT_SIZE.mainText,
        fontWeight:"bold"
    },
    operateChoice:{
        display:"flex",
        alignItems: "flex-end",
        "& .MuiSelect-nativeInput":{
            fontSize:common.FONT_SIZE.mainText
        },
        "& .MuiSelect-select.MuiSelect-select ,.MuiMenuItem-root":{
            fontSize:"1.7rem",
            minHeight:0
        },
        "& .MuiButtonBase-root": {
            paddingLeft: "40px"
        }
    },
    buttonStyle: {
        padding: "0 0 16px 40px",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& button:disabled": {
            backgroundColor: "#a6a6a6",
            border: "solid 1px #a6a6a6",
        }
    },
    settingRoot: {
        padding: "20px"
    },
    choiceType: {
        "& .MuiRadio-root": {
            paddingLeft: "28px"
        },
        "& .MuiRadio-root:first-child": {
            paddingLeft: "8px"
        }
    },
    buttonIconStyle: {
        padding: "0 0 16px 40px",
        "& button": {
            minWidth: "6.5rem",
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        }
    },
    changeOrder_buttonIconStyle: {
        padding: "0 0 16px 40px",
        "& button": {
            minWidth: "6.5rem",
            backgroundColor: "#e97132",
            border: "solid 1px #e97132",
        },
        "& button:hover": {
            backgroundColor: "#c04f15",
        },
        "& button:focus": {
            outline: "none !important",
        }
    },
}));

interface Props {
    question: IQuestion,
    choiceNumberingTypeChangeHandler: any,
    hitFlagChangeHandler: any,
    onClickDeleteChoiceHandler: any,
    onChangeChoiceTextHandler: any,
    onClickAddNewChoiceHandler: any,
    onChangeQuestionBodyHtmlHandler: any,
    onChangeShuffleChoiceHandler: any,
    onChangeAnswerTypeHandler: any,
    onChangeChoiceOrder: any,
    reorderFlag: boolean,
    onChangeReorderFlag: any,
    activeId: number,
    onChangeActiveId: (id: number) => void
}

export function Question(props: Props) {
    const {
        history,
        params,
        getUser, // ログインユーザー取得
        go, // 画面遷移 
        logout, // 強制ログアウト
        api  // API呼び出し
    } = common.useCommon();

    // const [answerType,setAnswerType] = React.useState(-1);

    const classNames = useStyles();

    const handleChange = (text:string) => {
        props.onChangeQuestionBodyHtmlHandler(props.question.id,text,toPlainText(text));
    };

    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.questionRoot}>
            <Card>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        問題設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <div>
                        <Box fontWeight="fontWeightBold" color="#003f71">問題文</Box>
                        <RichEditor key={props.question?.id} defaultValue={props.question?.bodyHtml} onChange={handleChange}/>
                    </div>
                </Box>
            </Card>

            <Card style={{marginTop:"16px"}}>
            <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                    解答設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <div>
                        <div style={{padding:"4px"}} >
                        <Box fontWeight="fontWeightBold" color="#003f71">
                                選択番号種別
                            </Box>
                            <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                                <Radio
                                    checked={props.question?.choiceNumberingType===0}
                                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{props.choiceNumberingTypeChangeHandler(props.question?.id,0);}}
                                    value={0}
                                />
                                数字
                                <Radio
                                    checked={props.question?.choiceNumberingType===1}
                                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{props.choiceNumberingTypeChangeHandler(props.question?.id,1);}}
                                    value={1}
                                />
                                カタカナ
                                <Radio
                                    checked={props.question?.choiceNumberingType===2}
                                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{props.choiceNumberingTypeChangeHandler(props.question?.id,2);}}
                                    value={2}
                                />
                                ローマ数字（小）
                                <Radio
                                    checked={props.question?.choiceNumberingType===3}
                                    onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{props.choiceNumberingTypeChangeHandler(props.question?.id,3);}}
                                    value={3}
                                />
                                ローマ数字（大）
                                <Radio
                                    checked={props.question?.choiceNumberingType === 4}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.choiceNumberingTypeChangeHandler(props.question?.id, 4); }}
                                    value={4}
                                />
                                なし
                            </Box>
                            <div className={classNames.operateChoice}>
                                <div>
                                    <Box fontWeight="fontWeightBold" color="#003f71"> 
                                        解答種別
                                    </Box>
                                    <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center">
                                        <Select
                                                    value={!props.question?.answerType ? 0 : props.question?.answerType}
                                                    onClick={(event)=>{props.onChangeAnswerTypeHandler(props.question?.id,event)}}
                                                >
                                            <MenuItem value={0}>択一選択肢</MenuItem>
                                            <MenuItem value={1}>複数選択肢</MenuItem>
                                            <MenuItem value={2}>半角数字入力</MenuItem>
                                            <MenuItem value={3}>文字入力</MenuItem>
                                        </Select>
                                        <Checkbox checked={!props.question?.choiceShuffleFlag ? false : props.question?.choiceShuffleFlag} onClick={(event)=>{props.onChangeShuffleChoiceHandler(props.question?.id)}}/>選択肢をシャッフル出題する
                                    </Box>
                                </div>
                                <div style={{textAlign:"right"}} className={classNames.buttonStyle}>
                                    {
                                        props.question.answerType > 1 ?
                                            <DefaultButton onClick={(event) => { props.onClickAddNewChoiceHandler(props.question.id) }} disabled={props.reorderFlag}>正答を追加</DefaultButton>
                                            :
                                            <DefaultButton onClick={(event) => { props.onClickAddNewChoiceHandler(props.question.id) }} disabled={props.reorderFlag}>選択肢を追加</DefaultButton>
                                    }
                                </div>
                                <div className={props.reorderFlag ? classNames.changeOrder_buttonIconStyle : classNames.buttonIconStyle}>
                                    <DefaultButton onClick={() => props.onChangeReorderFlag(!props.reorderFlag)}>{props.reorderFlag ? <CloseIcon /> : <SwapVertIcon />}</DefaultButton>
                                </div>
                            </div>
                        </div>
                        <ChoicesTable question={props.question} hitFlagChangeHandler={props.hitFlagChangeHandler} onClickDeleteChoiceHandler={props.onClickDeleteChoiceHandler} onChangeChoiceTextHandler={props.onChangeChoiceTextHandler}
                            onChangeChoiceOrder={props.onChangeChoiceOrder} reorderFlag={props.reorderFlag} activeId={props.activeId} onChangeActiveId={props.onChangeActiveId} />
                    </div>
                </Box>
            </Card>
        </div>
    );
}
