import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { PhotoCamera } from '@material-ui/icons';
import { AtomicBlockUtils, ContentBlock, convertFromRaw, convertToRaw, DraftBlockType, DraftEditorCommand, DraftHandleValue, DraftInlineStyleType, DraftStyleMap, Editor, EditorState, getDefaultKeyBinding, RawDraftContentState, RichUtils } from 'draft-js';
import React from 'react';
import * as common from "../../common";

const useStyles = makeStyles((props: RichEditorProps) => ({
    hidden: {
        display: "none"
    },
    "RichEditor-root": {
        background: "#fff",
        border: "1px solid #ddd",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: common.FONT_SIZE.richEditorRoot,
        padding: "15px",
        textAlign: "left", /* add jouno */
        width: "100%", /* add jouno */
    },
    'RichEditor-blockquote': {

    },
    'RichEditor-editor': {
        borderTop: (props: RichEditorProps) => props.readOnly ? undefined : "1px solid #ddd",
        cursor: (props: RichEditorProps) => props.readOnly ? "auto" : "text",
        fontSize: common.FONT_SIZE.richEditorText,
        marginTop: "10px",
        "& .public-DraftEditorPlaceholder-root": {
            margin: "0 -15px -15px",
            padding: "15px"
        },
        "& .public-DraftEditor-content": {
            margin: "0 -15px -15px",
            padding: "15px",
            minHeight: "100px"
        },
        "& $RichEditor-blockquote": {
            borderLeft: "5px solid #eee",
            color: "#666",
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
            fontStyle: "italic",
            margin: "16px 0",
            padding: "10px 20px"
        },
        "& .public-DraftStyleDefault-pre": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
            fontFamily: "'Inconsolata', 'Menlo', 'Consolas', monospace",
            fontSize: common.FONT_SIZE.richEditorText,
            padding: "20px"
        }
    },
    'RichEditor-hidePlaceholder': {
        "& .public-DraftEditorPlaceholder-root": {
            display: "none"
        }
    },
    'RichEditor-controls': {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: "16px",
        marginBottom: "5px",
        userSelect: "none",
        "& .makeStyles-questionRoot-31 svg":{
            fontSize:30
        },
        "& span": {
            border: "solid 1px transparent",
            "& span": {
                border: "none"
            }
        },
        "& span:hover": {
            color: "#000000",
            border: "solid 1px #999",
            backgroundColor: "#f5f5f5",
            "& span:hover": {
                border: "none",
                backgroundColor: "transparent"
            }
        }
    },
    'RichEditor-styleButton': {
        color: "#999",
        cursor: "pointer",
        marginRight: "12px",
        padding: "2px 4px",
        display: "inline-block"
    },
    'RichEditor-activeButton': {
        color: "#ffffff",
        border: "solid 1px #5890ff",
        backgroundColor: "#5890ff"
    },
    'photoIcon': {
        fontSize:"30px"
    }
}));

// インラインスタイル定義
// type DraftInlineStyleType = 'BOLD' | 'CODE' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE';
const INLINE_STYLES: { label: string; styleType: DraftInlineStyleType | string }[] = [
    { label: '太字', styleType: 'BOLD' },
    { label: '斜体', styleType: 'ITALIC' },
    { label: '下線', styleType: 'UNDERLINE' },
    { label: '取消', styleType: 'STRIKETHROUGH' },

    // { label: 'オレンジ', styleType: 'BG_ORANGE' },
    // { label: '黄色', styleType: 'BG_YELLOW' },

    // { label: '文字オレンジ', styleType: 'FONT_ORANGE' },
    // { label: '文字黄色', styleType: 'FONT_YELLOW' },

    // { label: '小', styleType: 'SMALL' },
    // { label: '中', styleType: 'MIDIUM' },
    // { label: '大', styleType: 'LARGE' },
];

// インラインスタイルの表示（CSS）を定義
const CUSTOM_STYLE_MAP = {
    BG_ORANGE: {
        backgroundColor: "orange",
        display: "inline-block"
    },
    BG_YELLOW: {
        backgroundColor: "yellow",
        display: "inline-block"
    },
    FONT_ORANGE: {
        color: "orange",
    },
    FONT_YELLOW: {
        color: "yellow",
    },
    ITALIC: {
        fontStyle: "italic",
        fontFamily: "'Century', serif"
    },
    SMALL: { fontSize: "12px" },
    MIDIUM: { fontSize: "16px" },
    LARGE: { fontSize: "20px" },
} as DraftStyleMap;

// type CoreDraftBlockType =
//     | 'unstyled'
//     | 'paragraph'
//     | 'header-one'
//     | 'header-two'
//     | 'header-three'
//     | 'header-four'
//     | 'header-five'
//     | 'header-six'
//     | 'unordered-list-item'
//     | 'ordered-list-item'
//     | 'blockquote'
//     | 'code-block'
//     | 'atomic';

// type CustomBlockType = string;

// type DraftBlockType = CoreDraftBlockType | CustomBlockType;

const BLOCK_TYPES: { label: string, style: DraftBlockType }[] = [

    { label: '段落', style: 'paragraph' },
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: '引用', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'コード', style: 'code-block' },

];

// Atomicブロックに対して、カスタムレンダリングを指定する
function customBlockRenderer(block: ContentBlock) {
    if (block.getType() === 'atomic' && block.getEntityAt(0)) {
        return {
            // 自力実装のMediaコンポーネントを表示する
            component: Media,
            editable: false,
        };
    }
    return null;
}

// 上で使用するMediaコンポーネントの実装
function Media(props: any) {
    const entityKey = props.block.getEntityAt(0);

    if (!entityKey) {
        return <div />;
    }

    // 挿入時に作成したEntityを取得
    const entity = props.contentState.getEntity(entityKey);

    // Entityに保存していたURLを取得
    const { src } = entity.getData();
    const type = entity.getType();

    // typeに対応するReactコンポーネントを作成して返す
    let media;
    if (type === 'audio') {
        media = <audio controls src={src} />;
    } else if (type === 'image') {
        media = <img src={src} alt="" style={{ maxWidth: "98%", maxHeight: "98%" }} />;
    } else if (type === 'video') {
        media = <video controls src={src} />;
    }

    return media;
}

function insertImageBlock(editorState: EditorState, dataURL: string) {

    // EditorContent取得
    const contentState = editorState.getCurrentContent();

    // EditorContentにEntityを作成（これが画像ファイルについてのデータ）
    const contentStateWithEntity = contentState.createEntity(
        "image",
        'IMMUTABLE',
        { src: dataURL }
    );

    // 作成したEntityの一意キーを取得
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // 作成したEntityを含む新しいEditorStateを作成
    // この時点では追加されただけで、挿入されていない
    const newEditorState = EditorState.set(
        editorState,
        { currentContent: contentStateWithEntity }
    );

    // カーソル位置にブロックを挿入
    return AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
    );
}

/**
 * ファイル読み込みのユーティリティ関数
 * @param input 
 */
function readAsDataURL(input: HTMLInputElement) {
    return new Promise<string>((resolve, reject) => {
        if (!input.files || input.files.length === 0) {
            resolve("");
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            reader.onloadend = (ev) => {
                resolve(reader.result as string);
            };
            reader.onerror = (ev) => {
                reject(ev);
            };
            reader.onabort = (ev) => {
                reject(ev);
            }
        }
    });
}

function jsonToEditorState(json: string | undefined) {
    if (!json || json.trim() === "") {
        return EditorState.createEmpty();
    }
    try {
        const raw = JSON.parse(json) as RawDraftContentState;
        return EditorState.createWithContent(convertFromRaw(raw));
    } catch (e) {
        return EditorState.createEmpty();
    }
}

/**
 * 内容をプレインテキストに変換する
 * @param jsonContentValue 
 */
export function toPlainText(jsonContentValue: string) {
    if (!jsonContentValue || jsonContentValue.trim() === "") {
        return "";
    }
    try {
        const raw = JSON.parse(jsonContentValue) as RawDraftContentState;
        return raw.blocks.map((block) => {
            return (block?.text?.trim()) ?? "";
        }).join("\n").trim();
    } catch (e) {
        return ""
    }

}

/**
 * リッチテキスト編集コントロールプロパティ
 */
export interface RichEditorProps {
    defaultValue?: string;
    onChange?: (value: string) => void;
    readOnly?: boolean;
}

/**
 * リッチテキスト編集コントロール
 * @param props 
 */
export function RichEditor(props: RichEditorProps) {

    const classes = useStyles(props);

    const editor = React.useRef<Editor>(null);

    const [editorState, setEditorState] = React.useState(() => {
        // 初期stateを生成
        return jsonToEditorState(props.defaultValue)
    });

    // 変更ハンドラ
    const onChange = React.useCallback((newEditorState: EditorState) => {
        const oldJson = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        setEditorState(newEditorState);
        if (typeof (props.onChange) === "function") {
            const json = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
            if (json !== oldJson) {
                props.onChange(json);
            }
        }
    }, [props, editorState, setEditorState]);


    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState, eventTimeStamp: number) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return "handled" as DraftHandleValue;
        }
        return "not-handled" as DraftHandleValue;
    };

    const mapKeyToEditorCommand = (e: React.KeyboardEvent) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return null;
        }
        return getDefaultKeyBinding(e);
    };

    const toggleBlockType = (blockType: string) => {
        onChange(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    };

    const toggleInlineStyle = (inlineStyle: string) => {
        onChange(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    };

    const handleImageChange = (e: React.ChangeEvent) => {
        const input = e.nativeEvent.target as HTMLInputElement;
        readAsDataURL(input)
            .then((dataURL) => {
                // input側はクリア
                input.value = null as unknown as string;

                // 新しいAtomicBlockを、作成したEntityに紐付けた形で作成し、
                // 現在の選択位置に挿入
                const newEditorState = insertImageBlock(editorState, dataURL);

                onChange(newEditorState);
            });
    };

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = classes['RichEditor-editor'];
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' ' + classes['RichEditor-hidePlaceholder'];
        }
    }

    const getBlockStyle = (block: ContentBlock) => {
        switch (block.getType()) {
            case 'blockquote':
                return classes['RichEditor-blockquote'];
            default:
                return null as unknown as string;
        }
    }

    return (
        <div className={classes["RichEditor-root"]}>
            <Grid container direction="row" wrap="wrap" alignItems="center" className={
                props.readOnly ? classes.hidden : undefined
            }>
                <Grid item>
                    <BlockStyleControls
                        classes={classes}
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                </Grid>
                <Grid item>
                    <InlineStyleControls
                        classes={classes}
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                </Grid>
                <Grid item>
                    <ImageUploadControls
                        classes={classes}
                        onChange={handleImageChange}
                    />
                </Grid>
            </Grid>
            <div className={className} onClick={() => editor?.current?.focus()}>
                <Editor
                    blockRendererFn={customBlockRenderer}
                    blockStyleFn={getBlockStyle}
                    customStyleMap={CUSTOM_STYLE_MAP}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    onChange={onChange}
                    placeholder={props.readOnly ? "" : "入力してください"}
                    ref={editor}
                    readOnly={props.readOnly}
                />
            </div>
        </div>
    );
}

///////////////

function StyleButton(props: {
    key: string;
    label: string;
    style: string;
    onToggle: (style: string) => void;
    active: boolean;
    classes: Record<string, string>;
    icon?: string;
}) {
    let className = props.classes['RichEditor-styleButton'];
    if (props.active) {
        className += ' ' + props.classes['RichEditor-activeButton'];
    }
    const onToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        props.onToggle(props.style);
    }
    return (
        <span className={className} onMouseDown={onToggle}>
            {props.label}
        </span>
    );
}

function InlineStyleControls(props: {
    editorState: EditorState;
    onToggle: (style: string) => void;
    classes: Record<string, string>;
}) {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    return (
        <div className={props.classes["RichEditor-controls"]}>
            {INLINE_STYLES.map((style) =>
                <StyleButton
                    classes={props.classes}
                    key={style.label}
                    active={currentStyle.has(style.styleType)}
                    label={style.label}
                    onToggle={props.onToggle}
                    style={style.styleType}
                />
            )}
        </div>
    );
};

function BlockStyleControls(props: {
    editorState: EditorState;
    onToggle: (style: string) => void;
    classes: Record<string, string>;
}) {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType: DraftBlockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    return (
        <div className={props.classes["RichEditor-controls"]}>
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    classes={props.classes}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

function ImageUploadControls(props: {
    onChange: (ev: React.ChangeEvent) => void;
    classes: Record<string, string>;
}) {
    return (
        <div className={props.classes["RichEditor-controls"]}>
            <div>
                <input
                    id="contained-button-file"
                    className={props.classes["hidden"]}
                    accept="image/*"
                    type="file"
                    onChange={props.onChange}
                />
                <label htmlFor="contained-button-file" >
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera style={{fontSize:"2.3rem"}}/>
                    </IconButton>
                </label>
            </div>
        </div>);
}