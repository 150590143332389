import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Choice, Question as IQuestion } from '../../react-app-env';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import {ButtonContainer, DefaultButton} from "../StylesUI/CommonLayouts";
import * as common from "../../common";
import { CustomModal } from "../StylesUI/CustomModal"

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    choiceTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& .MuiInputBase-input ,.MuiTableCell-head": {
            fontSize: common.FONT_SIZE.mainText,
        },
        "& #choiceField":{
            lineHeight:"2rem",
            fontSize: common.FONT_SIZE.mainText,
            width:"120%"
        }
    },
    changeOrder_buttonIconStyle: {
        width: "50%",
        height:"6rem",
        margin:"0 0 0 auto",
        flexDirection:"column",
        "& button": {
            backgroundColor: "#e97132",
            border: "solid 1px #ffa500",
        },
        "& button:hover": {
            backgroundColor: "#c04f15",
        },
        "& button:focus": {
            outline: "none !important",
        }
    },
    btn_inListitem: {
        padding: "0",
        margin: "0 0 1rem 0",
        fontSize: "1rem",
        minWidth: "5rem"
    },
    activeRow: {
        backgroundColor: "#7fb911",
    },
    modalText: {
        fontSize: "2rem",
        fontWeight: "bold",
        padding: "2rem 0",
        textAlign: "center",
    }
}));

interface Props {
    question: IQuestion,
    hitFlagChangeHandler:any,
    onClickDeleteChoiceHandler:any,
    onChangeChoiceTextHandler: any,
    onChangeChoiceOrder: any,
    reorderFlag: boolean,
    activeId: number,
    onChangeActiveId: (id: number) => void
}

export function ChoicesTable(props:Props) {
  const classNames = useStyles();
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得

    // モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalFunction, setModalFunction] = React.useState<any>();
    const [modalText, setModalText] = React.useState<JSX.Element>(<div />);

    // モーダル表示
    const openConfirmModal = (executionFunc: any, modalText: JSX.Element) => {
        setModalFunction(() => executionFunc);
        setModalText(modalText);
        setOpenModal(true);
    }

    // 選択肢の削除ボタン押下モーダル
    const confirmModalElement = <div className={classNames.modalText}>
        <p>選択肢を削除しますか？</p>
    </div>

    //文字列の行数を返す関数
    const getRowsNum = (text:string) => {
        let lineNum = text.match(/\r\n|\n|\r/g);
        let rowsNum = 1;
        if(lineNum != null){
            rowsNum = lineNum.length + 1;
        }
        return rowsNum
    }

    // 選択肢の順序入れ替え
    const moveChoices = (choiceId: number, moveUp: boolean) => {
        props.onChangeActiveId(choiceId);
        props.onChangeChoiceOrder(props.question.id, choiceId, moveUp);
    }

    function orderByAsc(a: { editOrder: number }, b: { editOrder: number }) {
        if (a.editOrder < b.editOrder) return -1;
        if (a.editOrder > b.editOrder) return 1;
        return 0;
    };

    //選択肢はEditOrderの昇順で並べ、試験受験時はViewOrderで並べる(Preview時も同様)
    return (
        <div className={classNames.choiceTableRoot}>
            <TableContainer component={Paper}>
                <Table /*className={classes.table}*/>
                    <TableHead>
                        <TableRow>
                            {
                              props.question?.answerType > 1 ?
                                <>
                                  <TableCell component="th" scope="row" style={{ width: 50 }}><div></div></TableCell>
                                  <TableCell align="left"><div>正答</div></TableCell>
                                </>
                                :
                                <>
                                  <TableCell component="th" scope="row" style={{ width: 50 }}><div>正答</div></TableCell>
                                  <TableCell align="left"><div>選択肢</div></TableCell>
                                </>
                            }
                            <TableCell align="center"><div>{props.reorderFlag ? "" : "削除" }</div></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.question?.choices?.filter(c=>c.deleteFlag!==true).sort(orderByAsc).map((choice:Choice) => (
                            <TableRow key={choice.id} className={props.activeId === choice.id ? classNames.activeRow : "" }>
                                <TableCell component="th" scope="row">
                                    <div>
                                    {
                                      props.question?.answerType > 1 ?
                                        <></>
                                        :
                                        props.question?.answerType === 1 ?
                                          <Checkbox
                                            checked={choice.hitFlag === true}
                                            onClick={(event) => { props.hitFlagChangeHandler(props.question.id, choice.id) }}
                                            value={choice.id}
                                          />
                                          :
                                          <Radio
                                            checked={choice.hitFlag === true}
                                            onClick={(event) => { props.hitFlagChangeHandler(props.question.id, choice.id) }}
                                            value={choice.id}
                                          />
                                    }                
                                    </div>
                                </TableCell>
                                <TableCell align="left">
                                    
                                    {/* <TextField id="choiceField" multiline onChange={(event)=>{props.onChangeChoiceTextHandler(props.question.id,choice.id,event.target.value)}} 
                                                value={choice.bodyText} fullWidth={true}  type="text" ></TextField> */}
                                    <textarea id="choiceField" rows={3} onChange={(event)=>{props.onChangeChoiceTextHandler(props.question.id,choice.id,event.target.value)}} value={choice.bodyText}></textarea>
                                    
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        props.reorderFlag ?
                                            <ButtonContainer className={classNames.changeOrder_buttonIconStyle}>
                                                <DefaultButton className={classNames.btn_inListitem} onClick={() => { moveChoices(choice.id, true) }} ><ArrowDropUpIcon /></DefaultButton>
                                                <DefaultButton className={classNames.btn_inListitem} onClick={() => { moveChoices(choice.id, false) }} ><ArrowDropDownIcon /></DefaultButton>
                                            </ButtonContainer>
                                            :
                                            <Button onClick={() => { openConfirmModal(() => { props.onClickDeleteChoiceHandler(props.question.id, choice.id) }, confirmModalElement) }} ><DeleteOutlineOutlinedIcon /></Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <CustomModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                modalFunc={modalFunction}
            >
                {modalText}
            </CustomModal>

        </div>
    );
}
