import React from 'react';
import { Route, Switch } from 'react-router';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
// import { FetchData } from './components/FetchData/FetchData';
// import { Home } from './components/Home/Home';
import { Layout } from './components/Layout';

// トップ画面
import { Top } from './components/Top/Top';
// トップ画面
import { ChangePassword } from './components/Password/ChangePassword';
// 主催者ユーザー
import { AdminUserIndex } from './components/AdminUser/Index';
import { AdminUserRegister } from './components/AdminUser/Register';
import { AdminUserEdit } from './components/AdminUser/Edit';
import { AdminUserConfirm } from './components/AdminUser/Confirm';
// 試験一覧
import { ExamIndex } from './components/Exam/Index';
import { ExamRegister } from './components/Exam/Register';
import { ExamConfirm } from './components/Exam/Confirm';
// 試験問題編集
import { EditQuestion } from './components/EditQuestion/EditQuestion';
import { ImportQuestion } from './components/Excel/ImportQuestion';
// 受験者
import { LearnerIndex } from './components/Learner/Index';
import { LearnerRegister } from './components/Learner/Register';
import { LearnerConfirm } from './components/Learner/Confirm';
// 録画再生画面
import { RecordingPlayback } from './components/RecordingPlayback/RecordingPlayback';
// CSV
import { CsvUpload } from './components/Csv/Upload';
import { CsvDownload } from './components/Csv/Download';
import { ExportAnswer } from './components/Csv/ExportAnswer';
import { TKCCExportAnswer } from './components/Csv/TKCCExportAnswer';
import { IAppContext } from './react-app-env';
//試験結果管理画面
import { TestResultsIndex } from './components/Results/Index';
import { TestResultsLearner } from './components/Results/Learner';
import { RecordingPlaybackMulti } from './components/RecordingPlayback/RecordingPlaybackMulti';
//成績集計画面
import { ResultsAnalysisIndex } from './components/ResultsAnalysis';

/**
 * Context作成
 */
export const AppContext = React.createContext({} as {
    appContext: IAppContext,
    setAppContext: (action: (currentContext: IAppContext) => Partial<IAppContext>) => void
});

/**
 * Context更新用Reducer定義
 */
function AppContextReducer(state: IAppContext, action: (currentContext: IAppContext) => Partial<IAppContext>) {
    const updates = action ? action(state) : {};
    return { ...state, ...updates } as IAppContext;
}

export default function App() {
    const [context, dispatch] = React.useReducer(AppContextReducer, { backdropIsopen: false });
    return (
        <AppContext.Provider value={{ appContext: context, setAppContext: dispatch }}>
            <Switch>
                <AuthorizeRoute path='/admin-user/index' component={AdminUserIndex} />
                <AuthorizeRoute path='/admin-user/register/:userId' component={AdminUserRegister} />
                <AuthorizeRoute path='/admin-user/edit/:userId' component={AdminUserEdit} />
                <AuthorizeRoute path='/admin-user/confirm/:type/:userId' component={AdminUserConfirm} />
                <AuthorizeRoute path='/exam/index' component={ExamIndex} />
                <AuthorizeRoute path='/exam/confirm/:type' component={ExamConfirm} />
                <AuthorizeRoute path='/exam/:type/:examId' component={ExamRegister} />
                <AuthorizeRoute path='/edit-question/:examId/preview/:order' component={EditQuestion} />
                <AuthorizeRoute path='/edit-question/:examId/:category/:id' component={EditQuestion} />
                <AuthorizeRoute path='/edit-question/:examId/:category' component={EditQuestion} />
                <AuthorizeRoute path='/importQuestion/:examId' component={ImportQuestion} />
                <AuthorizeRoute path='/learner/index/:examId' component={LearnerIndex} />
                <AuthorizeRoute path='/learner/confirm/:type/:examId/:userName' component={LearnerConfirm} />
                <AuthorizeRoute path='/learner/:type/:examId/:userName' component={LearnerRegister} />
                <AuthorizeRoute path='/recording-playback/:examId/:learnerId/:tabIndex' component={RecordingPlayback} />
                <AuthorizeRoute path='/recording-playbacks/:examId/:pageIndex/:tabIndex' component={RecordingPlaybackMulti} />
                <AuthorizeRoute path='/csv/upload/:examId' component={CsvUpload} />
                <AuthorizeRoute path='/csv/download/:examId' component={CsvDownload} />
                <AuthorizeRoute path='/csv/ExportAnswer/:examId' component={ExportAnswer} />
                <AuthorizeRoute path='/csv/tkccExportAnswer/:examId' component={TKCCExportAnswer} />
                <AuthorizeRoute path='/test-results/index/:examId' component={TestResultsIndex} />
                <AuthorizeRoute path='/test-results/learner/:examId/:userName' component={TestResultsLearner} />
                <AuthorizeRoute path='/test-results-analysis/:examId' component={ResultsAnalysisIndex} />
                <AuthorizeRoute exact path='/' component={Top} />
                <AuthorizeRoute exact path='/change-password' component={ChangePassword} />

                {/* テスト用 */}
                {/* <AuthorizeRoute path='/home' component={Home} /> */}
                {/* テスト用 */}
                {/* <AuthorizeRoute path='/fetch-data' component={FetchData} /> */}

                <Route path={ApplicationPaths.ApiAuthorizationPrefix}
                    render={(props) => {
                        return (<Layout><ApiAuthorizationRoutes {...props} /></Layout>);
                    }} />
            </Switch>
        </AppContext.Provider>
    );
}
