import React from 'react';
import { useRef } from 'react'

import { SideBar } from './SideBar'
import Grid from '@material-ui/core/Grid';
import { DevideScreen } from './DevideScreen'
import { Section } from './Section'
import { Question } from './Question'
import { SettingAllocation } from './SettingAllocation'
import { Confirm } from './Confirm'
import * as common from "../../common";
import { Choice, Question as IQuestion, ExamQuestion, APIArgsQuestionGet, APIResponse, Exam, ExamExtensions } from '../../react-app-env';
import { Layout } from '../Layout';
import { PreviewQuestion } from './Preview/Question'
import { PreviewStartScreen } from './Preview/StartScreen'
import { PreviewEndScreen } from './Preview/EndScreen'
import { shuffleArrayValues } from '../Utils/Utils'
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';
import { initialExamStateValue } from '../Exam/Index';
import { CustomModal } from "../StylesUI/CustomModal"
import { ExamConfirm } from '../Exam/Confirm';
import { ButtonContainer } from "../StylesUI/CommonLayouts";
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import Button from '@material-ui/core/Button';

interface ExamExtendsPlace extends Exam {
    executionPlaces: [{ id: number, placeCode: string, placeName: string }]
}

const useStyles = makeStyles((theme) => ({
    editQeustionRoot: {
        flexGrow: 1,
        fontSize: common.FONT_SIZE.mainText,
        display: "flex",
        "& button": {
            fontSize: common.FONT_SIZE.button
        },
        "& button:focus": {
            outline: "none !important",
        }
    },
    previewQeustionRoot: {
        flexGrow: 1,
        fontSize: common.FONT_SIZE.mainText,
        display: "flex",
        justifyContent: "center"
    },
    sideBarBackground: {
        background: "lightgray"
    },
    remainingTime: {
        display: "inline-block",
        padding: "0 0 0 12px",
        fontSize: "28px",
        fontWeight: "bold",
        color: "#ffffff",
    },
    remainingTimeBox: {
        display: "inline-block",
        padding: " 9px 32px 7px",
        backgroundColor: "#4378b6",
        boxShadow: " 4px 4px 8px rgb(0 0 0 / 15%) inset",
        fontSize: "20px",
        color: "#ffffff",
        height: " 44px",
        borderRadius: "22px"
    },
    modalText: {
        fontSize: "2rem",
        fontWeight: "bold",
        padding: "2rem 0",
        textAlign: "center"
    },
    fullButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginRight: "40px",
        marginLeft: "20px",
        width: "45px",
        "& button": {
            fontSize: common.FONT_SIZE.mainText,
            width: "44px",
            height: "44px",
            padding: "6px 6px",
            color: "white",
            backgroundColor: "#4378b6"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        }
    }
}));

export const initialQuestion: IQuestion = {
    id: 999999,
    executionId: 999999,
    sectionFlag: false,
    parentQuestionId: 999999,
    editOrder: 999999,
    viewOrder: 999999,
    parentOrder: 999999,
    subjectText: "初期値",
    bodyHtml: "初期値",
    points: 999999,
    answerType: 999999,
    choiceShuffleFlag: false,
    choiceNumberingType: 999999,
    deleteFlag: true,
    modified: "初期値",
    startViewFlag: false,
    choices: [{
        id: 999999,
        editOrder: 999999,
        viewOrder: 999999,
        bodyText: "初期値",
        hitFlag: false,
        deleteFlag: true
    }],
    figures: [{
        id: 999999,
        body: "初期値"
    }]
}


export function EditQuestion() {
    //--------カスタマイズしたmaterialUIのコンポーネント--------------
    const PrettoSlider = withStyles({
        root: {
            color: '#003f7',
            height: 8,
            padding: 0,
            verticalAlign: "top",
            cursor: "default"
        },
        thumb: {
            height: 34,
            width: 34,
            backgroundColor: '#7fb911',
            marginTop: -12,
            marginLeft: -12,
            '&:focus, &:hover, &$active': {
                boxShadow: 'inherit',
            },
        },
        active: {},
        valueLabel: {
            left: 'calc(-50% + 4px)',
        },
        track: {
            height: 12,
        },
        rail: {
            height: 12,
        },
    })(Slider);




    const initialExamQuestion: ExamQuestion = {
        executionId: 999999,
        name: "初期値",
        startViewFlag: false,
        startBodyHtml: "初期値",
        shuffleFlag: false,
        choiceDeviceFlag: false,
        afterReadFlag: false,
        passPoints: 999999,
        endViewFlag: false,
        endBodyHtml: "初期値",
        status: 999999,
        modified: "初期値",
        force: false,
        allPoints: 999999,
        questions: [initialQuestion]
    }

    //問題追加、削除中フラグ用
    const processing = useRef(false);

    // -----共通関数の宣言-----
    const {
        params,
        getUser, // ログインユーザー取得
        go, // 画面遷移 
        logout, // 強制ログアウト
        api  // API呼び出し
    } = common.useCommon();

    // -----API-----
    function getQuestion(args?: APIArgsQuestionGet) {
        return api("/api/o-question", "GET", args)
    }

    function getExecution(args?: any) {
        return api("/api/o-exam", "GET", args)
    }

    function putQuestion(args?: any) {
        return api("/api/o-question", "PUT", args)
    }

    function getExtension(executionId: number) {
        return api<any, APIResponse<ExamExtensions>>("/api/o-extension", "GET", {
            executionId
        });
    }

    function putExtension(args: ExamExtensions) {
        return api<ExamExtensions, APIResponse<ExamExtensions>>("/api/o-extension", "PUT", args);
    }

    // -----Handler-----
    // --開始画面--
    // 開始画面の使用フラグ変更
    const onChangeRadioStartViewFlag = (argBool: boolean) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, startViewFlag: argBool };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // 開始画面の本文変更
    const onChangeStartViewBodyHtml = (text: string) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, startBodyHtml: text };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // --終了画面--
    // 終了画面の使用フラグ変更
    const onChangeRadioEndViewFlag = (argBool: boolean) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, endViewFlag: argBool };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    const onChangeEndViewBodyHtml = (text: string) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, endBodyHtml: text };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // --問題編集--
    // 本文変更
    const onChangeQuestionBodyHtml = (questionId: number, text: string, plainText: string) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.bodyHtml = text;
        newQuestion.subjectText = plainText?.slice(0, 20);

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);
        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 選択肢のシャッフルフラグ変更
    const onChangeChoiceNumberingType = (questionId: number, choiceNumberingType: number) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.choiceNumberingType = choiceNumberingType;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);

        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 選択肢のシャッフルフラグ変更
    const onChangeShuttleChoice = (questionId: number) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.choiceShuffleFlag = !newQuestion.choiceShuffleFlag;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);

        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 解答タイプ変更
    const onChangeAnswerType = (questionId: number, event: React.ChangeEvent<{ value: string }>) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.answerType = parseInt(event.target.value);

        // 半角数字入力に変更された場合、8桁を上限とする
        if (newQuestion.answerType === 2) {
            newQuestion.choices.forEach(c => c.bodyText = c.bodyText.substring(0, 8));
        }

        if (newQuestion.choices?.length > 0) {
            newQuestion.choices.forEach(c => c.hitFlag = false);
            newQuestion.choices.filter(c => c.deleteFlag === false)[0].hitFlag = true;
        }

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);
        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 正答フラグ変更
    const onChangeSelectionHitFlag = (questionId: number, choiceId: number) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];

        // 単一の選択肢の場合は
        const answerType = newQuestion.answerType;

        // 単一選択の場合のみ元の正答フラグチェックを外す
        if (answerType === 0 && newQuestion.choices.filter(c => c.hitFlag === true).length > 0) {
            newQuestion.choices.filter(c => c.hitFlag === true)[0].hitFlag = false;
        }

        newQuestion.choices.filter(c => c.id === choiceId)[0].hitFlag = !newQuestion.choices.filter(c => c.id === choiceId)[0].hitFlag;

        // 複数選択の場合正答が0になることがあるため、チェックして最初の選択肢があれば正答にする
        if (newQuestion.choices.filter(c => c.deleteFlag === false).filter(c => c.hitFlag === true).length === 0) {
            newQuestion.choices.filter(c => c.deleteFlag === false)[0].hitFlag = true;
        }

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);
        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 選択肢の削除
    const onClickDeleteChoice = (questionId: number, choiceId: number) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];

        let choice = newQuestion.choices.filter(c => c.id === choiceId)[0];
        // 選択肢が0件になる場合は削除しない
        if (newQuestion.choices.filter(c => c.deleteFlag === false).length > 1) {
            choice.deleteFlag = true;
        } else {
            setModalText(<div className={classNames.modalText}>
                <p>選択肢を0件にすることは出来ません</p>
            </div>);

            openConfirmModal(() => { });
            return;
        }

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        if (choice.hitFlag) {
            choice.hitFlag = false;

            if (newQuestion.choices.filter(c => c.deleteFlag !== true).length > 0) {
                newQuestion.choices.filter(c => c.deleteFlag !== true)[0].hitFlag = true;
            }
        }

        questions.push(newQuestion);
        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 選択肢の文言変更
    const onChangeChoiceText = (questionId: number, choiceId: number, text: string) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];

        // 解答種別が半角数字入力の場合、9桁以上を切り捨てる
        let newText = "";
        if (newQuestion.answerType === 2 && text.length > 8) {
            newText = text.substring(0, 8);
        } else {
            newText = text;
        }
        newQuestion.choices.filter(c => c.id === choiceId)[0].bodyText = newText;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);

        questions.push(newQuestion);
        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // 選択肢の新規追加
    const onClickAddNewChoice = (questionId: number) => {
        const aryMax = function (a: number, b: number) { return Math.max(a, b); }

        const choices = state.examQuestion.questions.filter(q => q.id === questionId)[0].choices;

        const newId = state.choiceIdIndex + 1;
        //choices?.map(c => c.id).reduce(aryMax) + 1;
        const newEditOrder = choices?.map(c => c.editOrder).reduce(aryMax) + 1;
        const newViewOrder = choices?.map(c => c.viewOrder).reduce(aryMax) + 1;
        const newHitFlag = choices?.filter(c => c.deleteFlag === false).length === 0 ? true : false;
        const newText = "";

        let newChoice: Choice = { id: newId, bodyText: newText, editOrder: newEditOrder, viewOrder: newViewOrder, deleteFlag: false, hitFlag: newHitFlag };

        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.choices.push(newChoice);

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);

        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } ,choiceIdIndex: newId});
    }

    // 選択肢の順序入れ替えフラグ変更
    const onChangeReorderChoiceFlag = (changedFlag: boolean) => {
        setReorderChoiceFlag(changedFlag);
        setActiveChoiceId(-1);
    }

    // 選択肢の順序入れ替え
    const onChangeChoiceOrder = (questionId: number, choiceId: number, moveUp: boolean) => {
        const choices = state.examQuestion.questions.filter(q => q.id === questionId)[0].choices;

        let currentItem = choices.find(x => x.id === choiceId);
        if (!currentItem) return;

        const currentEditOrder = { ...currentItem }.editOrder;
        const currentViewOrder = { ...currentItem }.viewOrder;
        if (currentEditOrder <= 0) return;

        let targetItem = moveUp
            ? choices.filter(x => x.editOrder < currentEditOrder && x.deleteFlag === false).sort(orderByDesc)[0]
            : choices.filter(x => x.editOrder > currentEditOrder && x.deleteFlag === false).sort(orderByAsc)[0];
        if (!targetItem) return;

        const targetEditOrder = { ...targetItem }.editOrder;
        const targetViewOrder = { ...targetItem }.viewOrder;
        if (targetEditOrder <= 0) return;

        // 上下の選択肢とEditOrder,ViewOrderを入れ替える
        currentItem.editOrder = targetEditOrder;
        targetItem.editOrder = currentEditOrder;

        currentItem.viewOrder = targetViewOrder;
        targetItem.viewOrder = currentViewOrder;

        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];
        newQuestion.choices = choices;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);
        questions.push(newQuestion);

        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions }});
    }

    // --セクション開始画面--
    // セクション開始画面の使用フラグ
    const onChangeRadioSectionStartViewFlag = (questionId: number, argBool: boolean) => {
        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];

        newQuestion.startViewFlag = argBool;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);

        questions.push(newQuestion);

        questions.sort(orderByAsc);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions } });
    }

    // --サイドバー--
    // 問題の追加
    const onClickAddNewQuestion = () => {

        //処理中は問題追加を無効化
        if(processing.current)  return;
        processing.current = true
        
        const aryMax = function (a: number, b: number) { return Math.max(a, b); }

        const currentQuestions = state.examQuestion?.questions.filter(c => c.deleteFlag === false);

        const newId = state.examQuestion?.questions.length > 0 ? state.examQuestion?.questions?.map(c => c.id).reduce(aryMax) + 1 : 1;
        const newEditOrder = currentQuestions.length > 0 ? currentQuestions.map(c => c.editOrder).reduce(aryMax) + 1 : 1;
        const newViewOrder = currentQuestions.length > 0 ? currentQuestions.map(c => c.viewOrder).reduce(aryMax) + 1 : 1;
        const newChoiceId = state.choiceIdIndex +1;

        const initialQuestion: any = {
            id: newId,
            executionId: state.examQuestion.executionId,
            sectionFlag: false,
            parentQuestionId: 0,
            editOrder: newEditOrder,
            viewOrder: newViewOrder,
            subjectText: "",
            bodyHtml: "",
            points: 0,
            answerType: 0,
            choiceShuffleFlag: false,
            choiceNumberingType: 0,
            deleteFlag: false,
            startViewFlag: false,
            choices: [{
                id: newChoiceId,
                editOrder: 1,
                viewOrder: 1,
                bodyText: "",
                hitFlag: true,
                deleteFlag: false
            }],
            figures: []
        }

        let newQuestion = state.examQuestion.questions;
        newQuestion.push(initialQuestion);

        
        setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestion } ,choiceIdIndex: newChoiceId });

        //多重クリック防止
        setTimeout(() => {
            processing.current = false
        }, 100)
    }

    // 問題の削除
    const deleteQuestion = (questionId: number) => {

        //処理中は問題追加を無効化
        if(processing.current)  return;
        processing.current = true

        let newQuestions = state.examQuestion.questions;
        let parentQuestionEditOrder = 0;
        let childQuestionMaxEditOrder = 0;

        // 引数のquestionIdを持つ問題のdeleteFlagをtrueにし、editOrderを格納
        newQuestions.forEach(q => {
            if (q.id === questionId) {
                parentQuestionEditOrder = q.editOrder;
                q.deleteFlag = true;
                q.editOrder = 0;
            }
        })

        // 格納したeditOrderを親とする子の問題を削除
        newQuestions.forEach(q => {
            if (q.deleteFlag === false && q.parentQuestionId === questionId) {
                if (q.editOrder > childQuestionMaxEditOrder) {
                    childQuestionMaxEditOrder = q.editOrder;
                }
                q.editOrder = 0;
                q.deleteFlag = true;
            }
        })

        // 上記までの処理で順序がずれた分後ろの問題のeditOrderを前にずらす
        let refPoint = childQuestionMaxEditOrder === 0 ? parentQuestionEditOrder : childQuestionMaxEditOrder; // 削除された問題のうち最後の問題のeditOrderを基準点として格納
        let offset = childQuestionMaxEditOrder === 0 ? 1 : childQuestionMaxEditOrder - parentQuestionEditOrder + 1; // 後ろの問題が、前方向にずれる量
        // 削除された問題の後ろにある問題の順序をずらすためのループ
        newQuestions.forEach(q => {
            // 基準点より後ろに存在する問題のeditOrderから、ずれる量を引く
            if (q.editOrder > refPoint) {
                q.editOrder -= offset;

                // 基準点より後ろに存在し、かつ子の問題が存在する場合、子の問題のparentOrderに親の新しいeidtOrderを代入する
                if (q.parentOrder !== 0 && q.parentOrder !== undefined && q.parentOrder > refPoint ) {
                    q.parentOrder -= offset;
                }
            }
        });

        // 選択中の問題を削除した場合は次の問題を選択する
        if (newQuestions.find(q => q.id === parseInt(params.id))?.editOrder === 0) {
            if (newQuestions.filter(q => q.deleteFlag === false).length === 0) {
                // 問題がない場合は開始画面を選択する
                go("/edit-question/" + state.examQuestion.executionId + "/start-screen");
            }
            else {
                let nextQuestion = newQuestions.find(q => q.editOrder === parentQuestionEditOrder)
                    ?? (newQuestions.find(q => q.editOrder === parentQuestionEditOrder - 1) ?? null); // 次の問題がない場合は前の問題を選択する
                if (nextQuestion !== null) {
                    nextQuestion.sectionFlag
                        ? go("/edit-question/" + state.examQuestion.executionId + "/section/" + nextQuestion.id)
                        : go("/edit-question/" + state.examQuestion.executionId + "/question/" + nextQuestion.id);
                }
                else {
                    go("/edit-question/" + state.examQuestion.executionId + "/start-screen");
                }
            }
        }            

        const allPoints = newQuestions.filter(q => !q.sectionFlag && q.deleteFlag === false).length > 1
            ? newQuestions?.filter(q => !q.sectionFlag && q.deleteFlag === false)?.map(q => q.points).reduce((prev, current) => prev + current) ?? 0 : 0;
 
        // 問題を削除したことによって合計点が合格点を下回った場合、合格点に合計点を設定
        const totalPonts = newQuestions.filter(q => q.deleteFlag !== true && !q.sectionFlag).reduce((sum, current) => sum + current.points, 0)
        if (totalPonts < state.examQuestion.passPoints) {
            setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestions, allPoints: allPoints, passPoints: totalPonts } });
        }
        else {
            setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestions, allPoints: allPoints } });
        }

        //多重クリック防止
        setTimeout(() => {
            processing.current = false
        }, 100)
    }

    // 新規セクションの追加
    const addNewSectionHandler = () => {
        //処理中は問題追加を無効化
        if(processing.current)  return;
        processing.current = true

        const aryMax = function (a: number, b: number) { return Math.max(a, b); }

        const currentQuestions = state.examQuestion?.questions.filter(c => c.deleteFlag === false);

        //console.log(currentQuestions)
       //console.log(state.examQuestion)

        const newId = state.examQuestion?.questions.length > 0 ? state.examQuestion?.questions?.map(c => c.id).reduce(aryMax) + 1 : 1;
        const newEditOrder = currentQuestions.length > 0 ? currentQuestions.map(c => c.editOrder).reduce(aryMax) + 1 : 1;
        const newViewOrder = currentQuestions.length > 0 ? currentQuestions.map(c => c.viewOrder).reduce(aryMax) + 1 : 1;

        const initialQuestion: IQuestion = {
            id: newId,
            executionId: state.examQuestion.executionId,
            sectionFlag: true,
            parentQuestionId: 0,
            editOrder: newEditOrder,
            viewOrder: newViewOrder,
            parentOrder: 0,
            subjectText: "",
            bodyHtml: "",
            points: 0,
            answerType: 0,
            choiceShuffleFlag: false,
            choiceNumberingType: 0,
            deleteFlag: false,
            modified: "",
            startViewFlag: true,
            choices: [],
            figures: []
        }

        let newQuestion = state.examQuestion.questions;
        newQuestion.push(initialQuestion);

        //console.log(newId)
        //console.log(newEditOrder)
        //console.log(newViewOrder)
        
        setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestion } });

        //多重クリック防止
        setTimeout(() => {
            processing.current = false
        }, 100)
    }

    // セクションの子問題を追加
    const addNewSectionQuestion = (questionId: number) => {
        //処理中は問題追加を無効化
        if(processing.current)  return;
        processing.current = true

        // 追加するsectionの定義
        const aryMax = function (a: number, b: number) { return Math.max(a, b); }

        const parentOrder = state.examQuestion?.questions.filter(q => q.id === questionId)[0].editOrder;
        const newId = state.examQuestion?.questions.map(c => c.id).reduce(aryMax) + 1;
        const childQuestions = state.examQuestion?.questions.filter(q => q.parentQuestionId === questionId && q.deleteFlag === false);
        const newChoiceId = state.choiceIdIndex +1;

        const newEditOrder = childQuestions.length > 0 ? childQuestions?.map(q => q.editOrder).reduce(aryMax) + 1 : state.examQuestion.questions.filter(q => q.id === questionId)[0].editOrder + 1;
        const newViewOrder = childQuestions.length > 0 ? childQuestions?.map(q => q.viewOrder).reduce(aryMax) + 1 : state.examQuestion.questions.filter(q => q.id === questionId)[0].viewOrder + 1;

        // 後の問題の順序を一つ後ろに下げる
        let newQuestions: IQuestion[] = [];
        state.examQuestion.questions.forEach(q => {
            let newQuestion = q;
            if (newQuestion.editOrder >= newEditOrder) {
                newQuestion.editOrder++;
                newQuestion.viewOrder++;
            }

            newQuestions.push(newQuestion);
        })

        const sectionQuestion: IQuestion = {
            id: newId,
            executionId: state.examQuestion.questions[0].executionId,
            sectionFlag: false,
            parentQuestionId: questionId,
            editOrder: newEditOrder,
            viewOrder: newViewOrder,
            parentOrder: parentOrder,
            subjectText: "",
            bodyHtml: "",
            points: 0,
            answerType: 0,
            choiceShuffleFlag: false,
            choiceNumberingType: 0,
            deleteFlag: false,
            modified: "",
            startViewFlag: false,
            choices: [{
                id: newChoiceId,
                editOrder: 1,
                viewOrder: 1,
                bodyText: "",
                hitFlag: true,
                deleteFlag: false
            }],
            figures: []
        }

        //push
        newQuestions.push(sectionQuestion);

        newQuestions.sort(orderByAsc);

        
        // stateの更新
        setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestions } ,choiceIdIndex: newChoiceId});

        //多重クリック防止
        setTimeout(() => {
            processing.current = false
        }, 100)
    }

    // 問題の順序入れ替え
    const onChangeQuestionOrder = (questionId: number, moveUp: boolean) => {
        const newQuestions = state.examQuestion.questions;

        // 選択した問題
        let currentItem = newQuestions.find(x => x.id === questionId && x.deleteFlag === false);
        if (!currentItem) return;

        const currentEditOrder = { ...currentItem }.editOrder;
        if (currentEditOrder <= 0) return;

        // 選択した問題の子問題も含めて配列を作成
        let currentItems = newQuestions.filter(x => (x.id === questionId || x.parentQuestionId === questionId) && x.deleteFlag === false).sort(orderByAsc);
        if (currentItems.length <= 0) return;

        // editOrder,viewOrderだけの配列を作成
        let currentEditOrders: number[] = new Array(currentItems.length);
        let currentViewOrders: number[] = new Array(currentItems.length);
        for (let i: number = 0; i < currentItems.length; i++) {
            currentEditOrders[i] = { ...currentItems[i] }.editOrder;
            currentViewOrders[i] = { ...currentItems[i] }.viewOrder;
        }
        if (currentEditOrders.sort((a, b) => (a - b))[0] <= 0) return;

        // 入れ替え先の問題
        let targetItem: IQuestion;
        if (currentItem.parentQuestionId > 0) { // セクションの子問題は同セクションの子問題同士のみ入れ替え可能
            targetItem = moveUp
                ? newQuestions.filter(x => x.editOrder < currentEditOrder && x.parentQuestionId === currentItem?.parentQuestionId && x.deleteFlag === false).sort(orderByDesc)[0]
                : newQuestions.filter(x => x.editOrder > currentEditOrder && x.parentQuestionId === currentItem?.parentQuestionId && x.deleteFlag === false).sort(orderByAsc)[0];
        } else { // セクションの子問題を除いて入れ替え先を取得
            targetItem = moveUp
                ? newQuestions.filter(x => x.editOrder < currentEditOrder && x.parentQuestionId <= 0 && x.deleteFlag === false).sort(orderByDesc)[0]
                : newQuestions.filter(x => x.editOrder > currentEditOrder && x.parentQuestionId <= 0 && x.deleteFlag === false).sort(orderByAsc)[0];
        }
        if (!targetItem) return;

        // 入れ替え先の問題の子問題も含めて配列を作成
        let targetItems = newQuestions.filter(x => (x.id === targetItem.id || x.parentQuestionId === targetItem.id) && x.deleteFlag === false).sort(orderByAsc);
        if (targetItems.length <= 0) return;

        // editOrder,viewOrderだけの配列を作成
        let targetEditOrders: number[] = new Array(targetItems.length);
        let targetViewOrders: number[] = new Array(targetItems.length);
        for (let i: number = 0; i < targetItems.length; i++) {
            targetEditOrders[i] = { ...targetItems[i] }.editOrder;
            targetViewOrders[i] = { ...targetItems[i] }.viewOrder;
        }
        if (targetEditOrders.sort((a, b) => (a - b))[0] <= 0) return;

        // 選択した問題と入れ替え先の問題のeditOrder,viewOrderを繋げた配列を作成
        const changeEditOrders = moveUp ? targetEditOrders.concat(currentEditOrders) : currentEditOrders.concat(targetEditOrders);
        const changeViewOrders = moveUp ? targetViewOrders.concat(currentViewOrders) : currentViewOrders.concat(targetViewOrders);
        // 選択した問題と入れ替え先の問題の順序を入れ替えて繋げた配列を作成
        let changedItems = moveUp ? currentItems.concat(targetItems) : targetItems.concat(currentItems);

        // 順序入れ替え後の問題の配列に順序入れ替え前のeditOrder,viewOrderの値を設定
        for (let i: number = 0; i < changedItems.length; i++) {
            changedItems[i].editOrder = changeEditOrders[i];
            changedItems[i].viewOrder = changeViewOrders[i];
        }
        // セクションの子問題にparentOrderの値を設定
        newQuestions.forEach(q => {
            if (q.parentQuestionId === questionId) {
                q.parentOrder = currentItem?.editOrder ?? 0;
            }
            if (q.parentQuestionId === targetItem.id) {
                q.parentOrder = targetItem.editOrder;
            }
        });

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: newQuestions } })
    }

    // --出題・配点画面--
    // 問題のシャッフルフラグ変更
    const onChangeRadioShuffleQuestion = (argBool: boolean) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, shuffleFlag: argBool };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // 問題の解答デバイスの変更フラグ
    const onChangeChoiceDeviceFlag = (argBool: boolean) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, choiceDeviceFlag: argBool };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // 解答終了後の問題アクセス変更
    const onChangeAfterReadFlag = (argBool: boolean) => {
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, afterReadFlag: argBool };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // 合格点変更
    const onChangePassPoints = (argNumber: number) => {
        if (!argNumber || argNumber < 0) {
            argNumber = 0;
        }

        if (argNumber > state.examQuestion.allPoints) {
            argNumber = state.examQuestion.allPoints;
        }

        const points = String(argNumber).replace(/^0+/, '');
        //console.log(Number(points))
        const newExamQuestion: ExamQuestion = { ...state.examQuestion, passPoints: Number(points) };

        setState({ ...state, examQuestion: newExamQuestion });
    }

    // 問題の配点変更
    const onChangeQuestionPoints = (questionId: number, points: number) => {

        if (!points || points < 0) {
            points = 0;
        }

        let newQuestion = state.examQuestion.questions.filter(q => q.id === questionId)[0];

        newQuestion.points = points;

        let questions = state.examQuestion.questions.filter(q => q.id !== questionId);

        questions.push(newQuestion);

        questions.sort(orderByAsc);

        const allPoints = questions.filter(q => q.deleteFlag !== true && !q.sectionFlag).map(q => q.points).reduce((prev, current) => prev + current);

        setState({ ...state, examQuestion: { ...state.examQuestion, questions: questions, allPoints: allPoints } });
    }

    const onChangeExtensionHandler = async (newValue: ExamExtensions) => {
        try {
            const res = await putExtension(newValue);
            if (!res || res.errorCode !== 20000) {
                throw new Error(res.errorTitle);
            }
            setState(s => { return { ...s, extensions: newValue } });
        } catch (err) {
            throw err;
        }
    };

    // 試験会場コードの変更
    const onChangePlaceText = (id: number, placecode: string, placeName: string) => {
        if (!placecode) {
            placecode = "";
        }
        if (!placeName) {
            placeName = "";
        }

        let newPlace = state.executionPlace.filter(p => p.id === id)[0];
        newPlace.placeCode = placecode;
        newPlace.placeName = placeName;

        let places = state.executionPlace.filter(p => p.id !== id);
        places.push(newPlace);
        places.sort((a, b) => a.id - b.id);

        setState({ ...state, executionPlace: places});
    }

    // 試験会場コードの削除
    const onClickDeletePlace = (id: number) => {
        let newPlaces = state.executionPlace.filter(p => p.id !== id);
        setState({ ...state, executionPlace: newPlaces });
    }

    // 試験会場コードの追加
    const onClickAddNewPlace = () => {
        const places = state.executionPlace;
        //const newId = places != null ? places.sort((a, b) => a.id - b.id)[places.length - 1].id + 1 : 1;
        const newId = places != null && places.length != 0 ? places.sort((a, b) => a.id - b.id)[places.length - 1].id + 1 : 1;
        const newPlace = { id: newId, placeCode: "", placeName: "" };
        if (places != null) {
            places.push(newPlace);
            places.sort((a, b) => a.id - b.id);
            setState({ ...state, executionPlace: places });
        } else {
            setState({ ...state, executionPlace: [newPlace] });
        }
    }

    // --プレビュー--
    // プレビューボタンクリック
    const onClickPreviewBtn = () => {
        if (state.examQuestion.questions.filter(q => q.deleteFlag !== true).length === 0) {
            setModalText(<div className={classNames.modalText}>
                <p>プレビューする試験問題が存在しません</p>
            </div>);

            openConfirmModal(() => { });
            return;
        }

        setIsFinishedTest(false);

        let originQuestion = ShuffleQuestionAndChoices(state.examQuestion);
        let question = removeNoStartViewSection(originQuestion);


        setState({ ...state, previewQuestion: question ?? [], originQuestion: originQuestion ?? [] });

        go(state.examQuestion.startViewFlag ? "/edit-question/" + state.examQuestion.executionId + "/preview/start-screen" : "/edit-question/" + state.examQuestion.executionId + "/preview/1");
    }

    // ----- 保存確認画面 -----
    // 保存
    const onClickSave = (isDraft: boolean) => {
        const args: any = state.examQuestion;
        args.status = isDraft ? 0 : 1;

        if (args.questions.length === 0) addNewSectionHandler();

        args.questions.forEach((q: any) => {
            if (q.modified === "") {
                delete q.modified;
            }
        });
        
        args.executionPlaces = null;
        let isCheckedPlace = true;
        if (state.executionPlace !== null) {
            state.executionPlace.forEach((p: any) => {
                // 試験会場コード未入力、試験会場名称入力済みの場合、出題・配点設定画面に遷移しエラー表示
                if ((p.placeCode == null || p.placeCode == "") && (p.placeName !== null && p.placeName !== "")) {
                    isCheckedPlace = false;
                    setModalText(<div className={classNames.modalText}>
                        <p>未入力の試験会場コードがあります</p>
                        <p>出題・配点設定画面を確認してください</p>
                    </div>);

                    openConfirmModal(() => go("/edit-question/" + state.examQuestion?.executionId + "/setting-allocation"));
                }
                // 試験会場コードが入力済みの場合、登録する
                if ((p.placeCode !== null && p.placeCode !== "" )) {
                    args.executionPlaces === null ? args.executionPlaces = [p] : args.executionPlaces.push(p);
                }
            });
            if (args.executionPlaces !== null) {
                args.executionPlaces.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
            }
        }

        if (isCheckedPlace) {
            putQuestion(args)
                .then((res: APIResponse<any>) => {

                    if (res.errorCode !== 20000) {
                        common.alertError(res.errorTitle, res.errorDetail);
                        return;
                    }

                    setModalText(<div className={classNames.modalText}>
                        <p>試験問題を保存しました</p>
                        <p>試験名称：{state.examQuestion.name}</p>
                        <p>状態：{isDraft ? "下書き" : "正書"}</p>
                    </div>);

                    openConfirmModal(() => go("/exam/index"));

                })
                .catch((e: any) => {
                    alert(common.ResponseMessages.Error_PostQuestion)
                })
        }
    }


    // -----汎用関数定義-----
    //処理の確認モーダルを表示(実行する関数を渡す)
    const openConfirmModal = (executionFunc: any) => {
        setModalFunc(() => executionFunc);
        setOpenModal(true);
    }

    const getBlankQuestion = () => {
        const blankQuestion: IQuestion = {
            id: 0,
            executionId: parseInt(params.examId),
            sectionFlag: false,
            parentQuestionId: 999999,
            editOrder: 999999,
            viewOrder: 999999,
            parentOrder: 999999,
            subjectText: "初期値",
            bodyHtml: "初期値",
            points: 999999,
            answerType: 0,
            choiceShuffleFlag: false,
            choiceNumberingType: 0,
            deleteFlag: true,
            modified: "",
            startViewFlag: false,
            choices: [{
                id: 0,
                editOrder: 999999,
                viewOrder: 999999,
                bodyText: "初期値",
                hitFlag: false,
                deleteFlag: true
            }],
            figures: [{
                id: 0,
                body: "初期値"
            }]
        }
        return blankQuestion;
    }




    function orderByAsc(a: { editOrder: number, viewOrder?: number }, b: { editOrder: number, viewOrder?: number }) {
        if (a.editOrder < b.editOrder) return -1;
        if (a.editOrder > b.editOrder) return 1;
        if (a.editOrder === b.editOrder && a.viewOrder && b.viewOrder) {
            if (a.viewOrder < b.viewOrder) return -1;
            if (a.viewOrder > b.viewOrder) return 1;
        }
        return 0;
    };

    function orderByDesc(a: { editOrder: number }, b: { editOrder: number }) {
        if (a.editOrder < b.editOrder) return 1;
        if (a.editOrder > b.editOrder) return -1;
        return 0;
    };

    const shuffleChoices = (question: IQuestion): IQuestion => {
        let newChoices = question.choices;

        //let viewOrderArr = newChoices?.map(q => q.viewOrder);
        let viewOrderArr = Array.from(Array(newChoices.length), (v, k) => k).map(n => n + 1);
        viewOrderArr = shuffleArrayValues(viewOrderArr);

        newChoices?.forEach((q, idx) => {
            q.viewOrder = viewOrderArr[idx];
        });

        return { ...question, choices: newChoices };
    }

    // プレビュー問題の作成
    const buildPreviewQuestions = (examQuestion: ExamQuestion) => {

        if (!examQuestion) {
            return null;
        }

        const aryMax = function (a: number, b: number) { return Math.max(a, b); }

        // ディープコピー     
        let newQuestions = examQuestion.questions.map(x => Object.assign({}, x));

        newQuestions = newQuestions.filter(q => q.deleteFlag === false && (q.sectionFlag === false || q.startViewFlag));

        let editOrderArr = Array.from(Array(newQuestions.length), (v, k) => k).map(n => n + 1);
        newQuestions.forEach((q, idx) => {
            q.editOrder = editOrderArr[idx];
        });

        // 問題シャッフル
        if (examQuestion.shuffleFlag) {
            let parentQuestions = newQuestions.filter(q => q.parentQuestionId === 0);

            let editOrderArr = Array.from(Array(parentQuestions.length), (v, k) => k).map(n => n + 1);

            editOrderArr = shuffleArrayValues(editOrderArr);

            parentQuestions.forEach((q, idx) => {
                q.editOrder = editOrderArr[idx];
            });


            let childQuestions = newQuestions.filter(q => q.parentQuestionId !== 0);
            childQuestions.forEach(cq => {
                let editOrder: number = parentQuestions.filter(pq => pq.id === cq.parentQuestionId)[0].editOrder + 1

                let childQuestions = parentQuestions.filter(pq => pq.parentQuestionId === cq.parentQuestionId)
                if (childQuestions.length > 0) {
                    editOrder = childQuestions.map(q => q.editOrder).reduce(aryMax) + 1;
                };

                parentQuestions.filter(q => q.editOrder >= editOrder).forEach(q => {
                    q.editOrder++;
                })

                cq.editOrder = editOrder;

                parentQuestions.push(cq);
            })

            newQuestions = parentQuestions;
        }

        // 選択肢シャッフル
        newQuestions.forEach((q => {
            q = q.choiceShuffleFlag ? shuffleChoices(q) : q;
        }))

        return newQuestions;
    }

    //サイドバーの問題番号振り分け
    const setQuestionHeadnum = (questionArg: IQuestion, questions: IQuestion[]) => {
        let sectionNum = 0;
        let commonNum = 0;
        let headNum = "";
        let sectionHeadNum = "";

        for (const question of questions) {
            if (question.sectionFlag) {
                sectionNum = sectionNum + 1;

                if (questionArg.id === question.id) {
                   return sectionHeadNum = sectionNum.toString();
                }
            }
            else if (question.parentQuestionId === 0) {
                commonNum = commonNum + 1;

                if (questionArg.id === question.id) {
                    return headNum = "問" + commonNum;
                }

            }
            else {
                    commonNum = commonNum + 1;

                if (questionArg.id === question.id) {
                    return headNum = "問" + commonNum;
                }
            }
        }

        return headNum;

    }


    //開始画面を持たないセクションを取り除く
    const removeNoStartViewSection = (Questions: IQuestion[]) => {
        if (!Questions) {
            return [];
        }

        // ディープコピー     
        let newQuestions = Questions.map(x => Object.assign({}, x));
        newQuestions.sort(orderByAsc);
        newQuestions = newQuestions.filter(q => q.deleteFlag === false && (q.sectionFlag === false || q.startViewFlag));


        let editOrderArr = Array.from(Array(newQuestions.length), (v, k) => k).map(n => n + 1);
        newQuestions.forEach((q, idx) => {
            q.editOrder = editOrderArr[idx];
        });

        return newQuestions

    }




    //選択肢、問題のシャッフルを適宜実行する
    const ShuffleQuestionAndChoices = (examQuestion: ExamQuestion) => {

        if (!examQuestion) {
            return [];
        }

        const aryMax = function (a: number, b: number) { return Math.max(a, b); }
        let newQuestions = examQuestion.questions.map(x => Object.assign({}, x));

        // 問題シャッフル
        if (examQuestion.shuffleFlag) {

            let parentQuestions = newQuestions.filter(q => q.parentQuestionId === 0);
            let editOrderArr = Array.from(Array(parentQuestions.length), (v, k) => k).map(n => n + 1);

            editOrderArr = shuffleArrayValues(editOrderArr);

            parentQuestions.forEach((q, idx) => {
                q.editOrder = editOrderArr[idx];
            });



            let childQuestions = newQuestions.filter(q => q.parentQuestionId !== 0);

            childQuestions.forEach(cq => {
                let editOrder: number = parentQuestions.filter(pq => pq.id === cq.parentQuestionId)[0].editOrder + 1

                let childQuestions = parentQuestions.filter(pq => pq.parentQuestionId === cq.parentQuestionId)
                if (childQuestions.length > 0) {
                    editOrder = childQuestions.map(q => q.editOrder).reduce(aryMax) + 1;
                };

                parentQuestions.filter(q => q.editOrder >= editOrder).forEach(q => {
                    q.editOrder++;
                })

                cq.editOrder = editOrder;

                parentQuestions.push(cq);
            })

            newQuestions = parentQuestions;
        }

        // 選択肢シャッフル
        newQuestions.forEach((q => {
            q = q.choiceShuffleFlag ? shuffleChoices(q) : q;
        }))

        return newQuestions;

    }

    //強制レンダリング
    const focusUpdate = () => {
        setUpdata(update ? false : true);
    }


    const countDown = (time: number) => {
        let count = time;
        let nowDate = new Date();
        const endDate = new Date(nowDate.getTime() + time * 1000);
        let id = setInterval(() => {
            count--;
            nowDate = new Date();

            setRemainingTime(count);
            if (nowDate.getTime() >= endDate.getTime()) {
                clearInterval(id);
                setRemainingTime(0);
                setIsFinishedTest(true);
            }
        }, 1000)
        return id;
    }

    //残り時間表示関数
    const dispRemainingTime = () => {
        const time = remainingTime;

        const allMinutes = Math.floor(time / 60);
        const hours = Math.floor(allMinutes / 60);
        const minutes = allMinutes - hours * 60;
        const seconds = time - (minutes * 60) - (hours * 60 * 60);
        return hours + ":" + paddZero(minutes) + ":" + paddZero(seconds)
    }

    const paddZero = (argNum: number) => {
        let retVal = String(argNum);
        if (argNum < 10) retVal = "0" + retVal;
        return retVal;
    }

    const resetRemainingTime = () => {
        setRemainingTime(state.examData.executeTime * 60);
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();

    // -----state-----
    const [state, setState] = React.useState({
        examData: initialExamStateValue,
        examQuestion: initialExamQuestion,
        previewQuestion: [initialQuestion],
        originQuestion: [initialQuestion],
        extensions: {} as ExamExtensions,
        loading: true,
        editMode: true,
        choiceIdIndex: -1,
        executionPlace: [{ id: 999999, placeCode: "", placeName: "" }]
    });
    const [update, setUpdata] = React.useState<boolean>(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [remainingTime, setRemainingTime] = React.useState(0);
    const [previewSelectionChoices, setPreviewSelectionChoices] = React.useState([-1]);
    const [previewAnswerText, setPreviewAnswerText] = React.useState([{ id: -1, text: "" }]);
    const [isFinishedTest, setIsFinishedTest] = React.useState<boolean>(false);
    const [reorderChoiceFlag, setReorderChoiceFlag] = React.useState(false);
    const [activeChoiceId, setActiveChoiceId] = React.useState(-1);

    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState(<div></div>);
    const [modalFunc, setModalFunc] = React.useState<any>();

    // -----use effefct-----
    React.useEffect(() => {

        //プレビュー中にブラウザ更新した際にeditmodeへ遷移させる
        if (state.editMode === false) {
            go("/edit-question/" + params.examId + "/start-screen")
        }

        const argsGetQuestion: APIArgsQuestionGet = { executionId: parseInt(params.examId) }
        getQuestion(argsGetQuestion)
            .then((data: APIResponse<ExamQuestion>) => {
                if (!data) {
                    alert(common.ResponseMessages.Error_GetQuestion);
                    return;
                }

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                getExecution({ id: Number(params.examId) })
                    .then((exam: APIResponse<ExamExtendsPlace[]>) => {
                        if (!exam) {
                            alert(common.ResponseMessages.Error_GetExam);
                            return;
                        }
                        if (exam.errorCode !== 20000) {
                            common.alertError(exam.errorTitle, exam.errorDetail);
                            return;
                        }

                        if (exam.value && data.value) {
                          setState({
                            ...state,
                            examData: exam.value[0],
                            examQuestion: {
                              ...data.value,
                              questions: data.value.questions ?? [],
                                allPoints: data.value.questions.filter(q => !q.sectionFlag && q.deleteFlag === false).length < 1 ? 0 : data.value.questions?.filter(q => !q.sectionFlag && q.deleteFlag === false)?.map(q => q.points).reduce((prev, current) => prev + current) 
                            },
                            previewQuestion: removeNoStartViewSection(data.value.questions),
                            originQuestion: ShuffleQuestionAndChoices(data.value),
                            executionPlace: exam.value[0].executionPlaces
                          });
                          setRemainingTime(exam.value[0].executeTime * 60)
                        }
                        return getExtension(parseInt(params.examId));
                    })
                    .then((extensionRes) => {
                        if (extensionRes && extensionRes.errorCode === 20000) {
                            setState(s => { return { ...s, extensions: extensionRes.value }; });
                        }
                        setIsLoaded(true);
                    })
                    .catch((err: any) => {
                        alert(err);
                    });

            })
            .catch((err: any) => {
                alert(err);
            });




    }, []);


    if (params.category && !state.editMode) {
        setState({ ...state, editMode: true });
    }

    if (!params.category && state.editMode) {
        setState({ ...state, editMode: false });
    }

    let contents;
    switch (params.category) {
        case "question":
            contents = <Question
                question={state.examQuestion.questions.filter(q => q.id === parseInt(params.id))[0]}
                choiceNumberingTypeChangeHandler={onChangeChoiceNumberingType}
                hitFlagChangeHandler={onChangeSelectionHitFlag}
                onClickDeleteChoiceHandler={onClickDeleteChoice}
                onChangeChoiceTextHandler={onChangeChoiceText}
                onClickAddNewChoiceHandler={onClickAddNewChoice}
                onChangeQuestionBodyHtmlHandler={onChangeQuestionBodyHtml}
                onChangeShuffleChoiceHandler={onChangeShuttleChoice}
                onChangeAnswerTypeHandler={onChangeAnswerType}
                onChangeChoiceOrder={onChangeChoiceOrder}
                reorderFlag={reorderChoiceFlag}
                onChangeReorderFlag={onChangeReorderChoiceFlag}
                activeId={activeChoiceId}
                onChangeActiveId={(id) => setActiveChoiceId(id)}
            />;
            break;
        case "section":
            contents = <Section question={state.examQuestion.questions.filter(q => q.id === parseInt(params.id))[0]} onChangeQuestionBodyHtmlHandler={onChangeQuestionBodyHtml} onChangeRadioSectionStartViewFlagHandler={onChangeRadioSectionStartViewFlag} />;
            break;
        case "start-screen":
            contents = <DevideScreen editorKey="start-screen" screenName={"開始"} onChangeRadioViewFlagHandler={onChangeRadioStartViewFlag} onChangeViewBodyHtmlHandler={onChangeStartViewBodyHtml} curViewFlag={state.examQuestion.startViewFlag} curBodyHtml={state.examQuestion.startBodyHtml} />;
            break;
        case "end-screen":
            contents = <DevideScreen editorKey="end-screen" screenName={"終了"} onChangeRadioViewFlagHandler={onChangeRadioEndViewFlag} onChangeViewBodyHtmlHandler={onChangeEndViewBodyHtml} curViewFlag={state.examQuestion.endViewFlag} curBodyHtml={state.examQuestion.endBodyHtml} />;
            break;
        case "setting-allocation":
            contents = <SettingAllocation
                question={state.examQuestion.questions.filter(q => q.deleteFlag !== true).sort(orderByAsc)}
                exam={state.examQuestion}
                extensions={state.extensions}
                executionPlace={state.executionPlace}
                onChangeRadioShuffleQuestionHandler={onChangeRadioShuffleQuestion}
                onChangeChoiceDeviceFlagHandler={onChangeChoiceDeviceFlag}
                onChangeAfterReadFlagHandler={onChangeAfterReadFlag}
                onChangePassPointsHandler={onChangePassPoints}
                onChangeQuestionPointsHandler={onChangeQuestionPoints}
                onChangeExtensionHandler={onChangeExtensionHandler}
                onChangePlaceTextHandler={onChangePlaceText}
                onClickDeletePlaceHandler={onClickDeletePlace}
                onClickAddNewPlaceHandler={onClickAddNewPlace}
                setQuestionHeadnum={setQuestionHeadnum}
            />;
            break;
        case "confirm":
            contents = <Confirm question={state.examQuestion.questions.filter(q => q.deleteFlag !== true)} examQuestion={state.examQuestion} onClickSaveHandler={onClickSave} />;
            break;
        case "preview":
            break;
        default:
            break;
    }

    const editContents = <Grid container justify="center" spacing={2}>
        <Grid item xs={3} >
        <SideBar exam={state.examQuestion} question={state.examQuestion.questions.filter(q => q.deleteFlag === false).sort(orderByAsc)} addNewQuestionHandler={onClickAddNewQuestion} addNewSectionQuestionHandler={addNewSectionQuestion} addNewSectionHadnler={addNewSectionHandler} deleteQuestionHandler={deleteQuestion} setQuestionHeadnum={setQuestionHeadnum} focusUpdate={focusUpdate}
            onChangeQuestionOrder={onChangeQuestionOrder} onChangeReorderChoiceFlag={onChangeReorderChoiceFlag } />
        </Grid>
        <Grid item xs={9} >
            <Box >
                {contents}
            </Box>
        </Grid>
    </Grid>


    let previewContents;

    switch (params.order) {
        case "start-screen":
            previewContents = state.examQuestion?.startViewFlag ? <PreviewStartScreen examQuestion={state.examQuestion} /> : <PreviewQuestion previewSelectionChoices={previewSelectionChoices} setPreviewSelectionChoices={setPreviewSelectionChoices} previewAnswerText={previewAnswerText} setPreviewAnswerText={setPreviewAnswerText} isFinishedTest={isFinishedTest} remainingTime_sec={remainingTime} exam={state.examQuestion} originQuestion={state.originQuestion.filter(q => q.deleteFlag !== true)} question={state.previewQuestion} curQuestion={state.previewQuestion.filter(q => q.editOrder === 1)[0]} setQuestionHeadnum={setQuestionHeadnum} countDown={countDown} resetRemainingTime={resetRemainingTime} extensions={state.extensions.extensions} />;
            break;
        case "end-screen":
            previewContents = state.examQuestion?.endViewFlag ? <PreviewEndScreen isFinishedTest={isFinishedTest} examQuestion={state.examQuestion} /> : <PreviewQuestion previewSelectionChoices={previewSelectionChoices} setPreviewSelectionChoices={setPreviewSelectionChoices} previewAnswerText={previewAnswerText} setPreviewAnswerText={setPreviewAnswerText} isFinishedTest={isFinishedTest} remainingTime_sec={remainingTime} exam={state.examQuestion} originQuestion={state.originQuestion.filter(q => q.deleteFlag !== true)} question={state.previewQuestion} curQuestion={state.previewQuestion.filter(q => q.editOrder === 1)[0]} setQuestionHeadnum={setQuestionHeadnum} countDown={countDown} resetRemainingTime={resetRemainingTime} extensions={state.extensions.extensions} />;
            break;
        default:
            previewContents = <PreviewQuestion previewSelectionChoices={previewSelectionChoices} setPreviewSelectionChoices={setPreviewSelectionChoices} previewAnswerText={previewAnswerText} setPreviewAnswerText={setPreviewAnswerText} isFinishedTest={isFinishedTest} remainingTime_sec={remainingTime} exam={state.examQuestion} originQuestion={state.originQuestion.filter(q => q.deleteFlag !== true)} question={state.previewQuestion} curQuestion={state.previewQuestion.filter(q => q.editOrder === parseInt(params.order))[0]} setQuestionHeadnum={setQuestionHeadnum} countDown={countDown} resetRemainingTime={resetRemainingTime} extensions={state.extensions.extensions } />;
            break;
    }

    const editNavLinks = [
        {
            text: "戻る",
            to: "/Exam/Index"
        },
        {
            text: state.examQuestion?.name + " 問題編集",
            to: ""
        }
    ];

    const previewNavLinks = [
        {
            text: state.examQuestion?.name + " プレビュー",
            to: ""
        }
    ];


    let headerRemainingTime =
        <span className={classNames.remainingTimeBox}>
            残り時間：<span className={classNames.remainingTime}>{dispRemainingTime()}</span>
        </span>

    let headerSlider = <PrettoSlider min={0} max={state.examData.executeTime * 60} value={(state.examData.executeTime * 60) - remainingTime}></PrettoSlider>

    let fullScreen = (
        <ButtonContainer className={classNames.fullButtonContainer}>
            <Button id={"fullscreenBtn"} onClick={() => document.documentElement.requestFullscreen()} className={classNames.fullButtonContainer}>
                <ZoomOutMapIcon style={{ fontSize: "26" }} />
            </Button>
        </ButtonContainer>
    );

    return (
        <Layout headerRemainingTime={state.editMode ? "" : headerRemainingTime} headerSlider={state.editMode ? "" : headerSlider} navLinks={state.editMode ? editNavLinks : previewNavLinks}
            width={state.editMode ? "auto" : "question"} fullScreen={fullScreen}
            buttons={
                state.editMode ?
                    [
                        { text: "出題・配点", handler: (ev: any) => { go("/edit-question/" + state.examQuestion?.executionId + "/setting-allocation") } },
                        { text: "プレビュー", handler: (ev: any) => { onClickPreviewBtn(); } },
                        { text: "編集完了", handler: (ev: any) => { go("/edit-question/" + state.examQuestion?.executionId + "/confirm") } },
                    ]
                    :
                    [

                    ]
            }>
            {isLoaded ?

                <div className={state.editMode ? classNames.editQeustionRoot : classNames.previewQeustionRoot} >
                    <CustomModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        modalFunc={modalFunc}
                        isNotice={true}
                    >
                        {modalText}
                    </CustomModal>

                    {
                        state.editMode ? editContents : previewContents
                    }
                </div>

                : <div>Loading...</div>

            }
        </Layout>
    );
}
