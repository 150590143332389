import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import Paper from '@material-ui/core/Paper/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import React from 'react';
import { JsxElement, StringLiteralType } from 'typescript';

import * as common from "../../common";
import { APIResponse } from '../../react-app-env';
import { Layout } from '../Layout';
import { DefaultButton ,DefaultBackButton} from '../StylesUI/CommonLayouts';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    changePasswordRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },
        "& button":{
            fontSize: common.FONT_SIZE.button
        },
        "& button:focus": {
            outline:"none !important", 
        }
    },
    formStyle: {
        height:"8rem",
        "& input ,textarea ,.MuiFormHelperText-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& td": {
            width:"260px"
        },
        "& #remark":{
            padding:5,
            lineHeight:1.2
        },
        "& .MuiOutlinedInput-multiline":{
            padding:"12px 14px"
        }
    },
    labelStyle: {
        paddingRight: "60px"
    },
    buttonStyle: {
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    }
}));



export function ChangePassword() {

    // -----API-----
    //ユーザーパスワードを変更
    function putUserPassword(args?: any):Promise<APIResponse<any>> {
        return api("/api/o-userPassword", "PUT", args)
    };

    // -----汎用関数宣言-----

    // -----スタイルの宣言-----
    const classNames = useStyles();

    // -----共通関数の宣言-----
    const {
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();


    const initialCurrentPasswordError : {currentPassword : null|JSX.Element} = {currentPassword:null}
    const initiaNewPasswordError : {newPassword : null|JSX.Element} = {newPassword:null}
    const initiaConfirmPasswordError : {newPasswordConfirm : null|JSX.Element} = {newPasswordConfirm:null}
    // -----state-----
    const [stateInputValues, setStateInputvalues] = React.useState({currentPassword:"",newPassword:"",newPasswordConfirm:""});
    const [stateCurrentPasswordError, setStateCurrentPasswordError] = React.useState(initialCurrentPasswordError);
    const [stateNewPasswordError, setStateNewPasswordError] = React.useState(initiaNewPasswordError);
    const [stateConfirmPasswordError,setStateConfirmPasswordError] = React.useState(initiaConfirmPasswordError);
    
        // -----Handler-----
    //登録ボタンクリック時の処理
    const onClickChange=()=>{

        if (!validateAllInputs()){
            return;
        }

        putUserPassword({oldPassword:stateInputValues.currentPassword, newPassword:stateInputValues.newPassword})
            .then((res: APIResponse<any> ) => {

                if (res.errorCode !==20000) {
                    common.alertError(res.errorTitle , res.errorDetail);
                    return;
                }

                alert(common.ResponseMessages.Sucess_PutUserPassword);
            }).catch((reason) => {
                alert(common.ResponseMessages.Error_PutUserPassword);
            });
    }

    //フォーム入力時の処理
    const handleInput=(event: React.ChangeEvent<HTMLInputElement>)=>{
        const name = event.target.name as keyof typeof stateInputValues;
        
        setStateInputvalues({...stateInputValues,[name]:event.target.value}) 

        switch(name){
            case "newPassword":
                // executeValidatiion(name,event.target.value,validateNewPassword)
                break;
            default:
                break;
        }
    }

    const validateAllInputs= ()=>{
        let retB :boolean = true;

        if(!validateCurrentPassword(stateInputValues.currentPassword)){
            retB = false;
        }

        if(!validateNewPassword(stateInputValues.newPassword)){
            retB = false;
        }

        if(!validateConfirmPassword(stateInputValues.newPasswordConfirm)){
            retB = false;
        }

        return retB;
    }

    const validateCurrentPassword= (value:string)=>{
        if(!stateInputValues.currentPassword){
            setStateCurrentPasswordError({currentPassword:<span>{"入力してください"}</span>})
            return false;
        }else{
            setStateCurrentPasswordError({currentPassword:null})
        }
        return true;
    }

    const validateNewPassword = (value:string)=>{
        const res = getPasswordErrorMessage(value);
        if (res){
            setStateNewPasswordError({newPassword:res})
            return false;
        }else{
            setStateNewPasswordError({newPassword:null})
        }
        return true;
    }

    const validateConfirmPassword = (value:string)=>{
        if(!stateInputValues.newPasswordConfirm){
            setStateConfirmPasswordError({newPasswordConfirm:<span>{"入力してください"}</span>})
            return false;
        }else if(stateInputValues.newPasswordConfirm !== stateInputValues.newPassword){
            setStateConfirmPasswordError({newPasswordConfirm:<span>{"入力値が新しいパスワードと一致しません"}</span>})
            return false;
        }else{
            setStateConfirmPasswordError({newPasswordConfirm:null})
        }

        return true;
    }

    const getPasswordErrorMessage = (password:string) =>{
        let errorMessages : string[] = [];
        // パスワードの文字数チェック 
        //validatorSettings.fields.filter((f: { selector: string; }) => f.selector === "#password")[0]; 
        if (password.length < 6 || password.length > 100) { 
            errorMessages.push("パスワードは最小6文字、最大100文字で入力してください");
        }  

        // 数字が存在するかチェック 
        if (password.match(/[0-9]/) === null) { 
            errorMessages.push("パスワードは数字を最低1文字含めてください");
        }  

        // 英大文字が存在するかチェック 
        if (password.match(/[A-Z]/) === null) { 
            errorMessages.push("パスワードは英大文字を最低1文字含めてください");
        }  

        // 英小文字が存在するかチェック 
        if (password.match(/[a-z]/) === null) { 
            errorMessages.push("パスワードは英子文字を最低1文字含めてください");
        }  

        // 半角記号が存在するかチェック 
        if (password.match(/[!@#$%^&*()_\+\-=\[\]{};:?,.\/\\<>|~`'"]/) === null) { 
            errorMessages.push("パスワードは英数字以外の文字を最低1文字含めてください");
        } 

        return errorMessages.length > 0 ? (<React.Fragment>
            {
                errorMessages?.map((e,index)=>(
                    <span key={index}>{e}<br /></span>
                ))
            }
        </React.Fragment>) : null;
    }

    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
        <div className={classNames.changePasswordRoot}>
            <h4>パスワード変更</h4>
            <table>
                <tbody>
                    <tr className={classNames.formStyle}>
                        <th className={classNames.labelStyle}><label>現在のパスワード</label></th>
                        <td><TextField fullWidth value={stateInputValues.currentPassword} name="currentPassword" required onChange={handleInput} helperText={stateCurrentPasswordError.currentPassword} error={stateCurrentPasswordError.currentPassword ? true : false} onBlur={(event)=>validateCurrentPassword(event.target.value)}/></td>
                    </tr>
                    <tr className={classNames.formStyle}>
                        <th className={classNames.labelStyle}><label>新しいパスワード</label></th>
                        <td><TextField fullWidth value={stateInputValues.newPassword} name="newPassword" required onChange={handleInput} helperText={stateNewPasswordError.newPassword} error={stateNewPasswordError.newPassword ? true : false} onBlur={(event)=>validateNewPassword(event.target.value)}/></td>
                    </tr>
                    <tr className={classNames.formStyle}>
                        <th className={classNames.labelStyle}><label>新しいパスワード（確認）</label></th>
                        <td><TextField fullWidth value={stateInputValues.newPasswordConfirm} name="newPasswordConfirm" required onChange={handleInput} helperText={stateConfirmPasswordError.newPasswordConfirm} error={stateConfirmPasswordError.newPasswordConfirm ? true : false} onBlur={(event)=>validateConfirmPassword(event.target.value)}/></td>
                    </tr>
                    
                </tbody>
            </table>
            <div style={{marginTop:"30px"}}  className={classNames.buttonStyle}>
                <DefaultBackButton className="backBtn" color="primary" style={{marginRight:"8px"}} onClick={()=>{go("/")}}>戻る</DefaultBackButton>
                <DefaultButton color="primary" style={{marginRight:"8px"}} onClick={onClickChange}>変更</DefaultButton>
            </div>
        </div>
        </Layout>
    );
}
