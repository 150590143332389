import React from 'react';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { createUseStyles } from 'react-jss';

import * as common from "../../common";
import { Layout } from '../Layout';
import { goExamRegister, normalLinks } from '../NavLinks/NavLinks';

import { Box } from '@material-ui/core';
import { Editor, EditorState, convertToRaw } from 'draft-js';
import { APIArgsQuestionGet, APIResponse, Exam, ExamQuestion, Question } from '../../react-app-env';
import { initialExamStateValue } from '../Exam/Index';
import { ButtonContainer, DefaultBackButton, DefaultButton } from '../StylesUI/CommonLayouts';
import { CustomModal } from "../StylesUI/CustomModal";

const ExcelJS = require('exceljs');

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    uploadRoot: {
        fontSize: common.FONT_SIZE.mainText,
        textAlign: "center",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize: common.FONT_SIZE.title
        },
        "& h5": {
            fontSize: common.FONT_SIZE.mainText,
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    dropZone: {
        position: "relative",
        width: 500,
        marginLeft: "auto",
        marginRight: "auto"
    },
    fileDropZone: {
        width: 500,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderStyle: 'dashed',
        borderRadius: 5,
        marginBottom: 16,
        cursor: "pointer"
    },
    filePreview: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        display: "inline-block",
        height: 120,
        borderRadius: 2,
        listStyle: "none",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 2,
        flexGrow: 1,
        overflow: "auto",
        textAlign: "left",
        fontSize: 13
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor: "#4378b6",
        padding: "6px 16px",
        borderRadius: "4px",
        color: "white",
        marginRight: "5px"
    },
    buttonStyle: {
        display: "block",
        "& button": {
            fontSize: common.FONT_SIZE.button,
            marginTop: "50px",
            marginRight: "15px"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    modalText: {
        fontSize: "2rem",
        fontWeight: "bold",
        padding: "2rem 0",
        textAlign: "center"
    },
    erroMassage: {
        position: "relative",
        width: 500,
        height: 150,
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "auto",
        fontSize: 13,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2
    }
});

export function ImportQuestion() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();


    //-----API----------
    //試験データを取得する
    function getExamData(args?: any): Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }
    function getQuestion(args?: APIArgsQuestionGet) {
        return api("/api/o-question", "GET", args)
    }

    function putQuestion(args?: any) {
        return api("/api/o-question", "PUT", args)
    }

    //インポート実行
    function onClickImport() {
        if (existQuestion) {
            //既存の問題がある場合は確認ダイアログを表示する
            setModalText(<div className={classNames.modalText}>
                <p>既存の問題・選択肢は削除されます。よろしいですか？</p>
            </div >);
            openConfirmModal(() => saveExcelData());
        }
        else {
            saveExcelData();
        }
    }
    function saveExcelData() {

        const initialExamQuestion: ExamQuestion = {
            executionId: examValue.id,
            name: examValue.name,
            startViewFlag: examValue.startViewFlag,
            startBodyHtml: examValue.startBodyHtml,
            shuffleFlag: examValue.shuffleFlag,
            choiceDeviceFlag: examValue.choiceDeviceFlag,
            afterReadFlag: examValue.afterReadFlag,
            passPoints: examValue.passPoints,
            endViewFlag: examValue.endViewFlag,
            endBodyHtml: examValue.endBodyHtml,
            status: 0,
            modified: examValue.modified.toLocaleString(),
            force: false,
            allPoints: examValue.allPoints,
            questions: importData
        };

        initialExamQuestion.questions.forEach((q: any) => {
            if (q.modified === "") {
                delete q.modified;
            }
        });
        
        putQuestion(initialExamQuestion)
            .then((res: APIResponse<any>) => {
        
                if (res.errorCode !== 20000) {
                    common.alertError(res.errorTitle, res.errorDetail);
                    return;
                }
        
                setIsUploaded(true);
        
            })
            .catch((e: any) => {
                alert(common.ResponseMessages.Error_PostQuestion)
            })
    }

    // Ecxelデータの取り込み
    function loadExcelData(data: any) {
        try {
            const items: any[] = [];
            for (const row of data) {
                if (row.getCell(1).value !== "セクションフラグ") {
                    let item = {
                        SectionFlag: row.values[1] ?? false,
                        AffiliationFlag: row.values[2] ?? false,
                        QuestionText: row.values[3] ?? null,
                        ImageFile: row.values[4],
                        Points: row.values[5] ?? 0,
                        AnswerType: row.values[6] ?? 0,
                        ChoiceShuffleFlag: row.values[7] ?? false,
                        ChoiceNumberingType: row.values[8] ?? 0,
                        ChoiceCount: row.values[9],
                        BodyText1: row.values[10],
                        HitFlag1: row.values[11],
                        BodyText2: row.values[12],
                        HitFlag2: row.values[13],
                        BodyText3: row.values[14],
                        HitFlag3: row.values[15],
                        BodyText4: row.values[16],
                        HitFlag4: row.values[17],
                        BodyText5: row.values[18],
                        HitFlag5: row.values[19],
                        BodyText6: row.values[20],
                        HitFlag6: row.values[21],
                        BodyText7: row.values[22],
                        HitFlag7: row.values[23],
                        BodyText8: row.values[24],
                        HitFlag8: row.values[25],
                        BodyText9: row.values[26],
                        HitFlag9: row.values[27],
                        BodyText10: row.values[28],
                        HitFlag10: row.values[29],
                        BodyText11: row.values[30],
                        HitFlag11: row.values[31],
                        BodyText12: row.values[32],
                        HitFlag12: row.values[33],
                        BodyText13: row.values[34],
                        HitFlag13: row.values[35],
                        BodyText14: row.values[36],
                        HitFlag14: row.values[37],
                        BodyText15: row.values[38],
                        HitFlag15: row.values[39]
                    }
                    items.push(item);
                }
            }

            validateExcelFile(items);

            let maxId = 0;
            // 既存データは論理削除する
            let importQuestion = questionValue;
            if (importQuestion.length > 0) {
                importQuestion.forEach((i: any) => {
                    i.deleteFlag = true;
                    i.modified = dtNow;
                    maxId = i.id;
                    if (i.choices !== null) {
                        i.choices.forEach((c: any) => {
                            c.deleteFlag = true;
                            c.modified = dtNow;
                        })
                    }
                });
            }

            let parentId = 0;
            let parentOrder = 0;
            let idCountUp = 1;
            for (const row of items) {
                let newChoices: any[] = [];
                let importChoice = [];
                let isContinue = true;
                row.BodyText1 !== undefined && row.HitFlag1 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText1, hitFlag: row.HitFlag1 }) : isContinue = false;
                row.BodyText2 !== undefined && row.HitFlag2 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText2, hitFlag: row.HitFlag2 }) : isContinue = false;
                row.BodyText3 !== undefined && row.HitFlag3 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText3, hitFlag: row.HitFlag3 }) : isContinue = false;
                row.BodyText4 !== undefined && row.HitFlag4 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText4, hitFlag: row.HitFlag4 }) : isContinue = false;
                row.BodyText5 !== undefined && row.HitFlag5 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText5, hitFlag: row.HitFlag5 }) : isContinue = false;
                row.BodyText6 !== undefined && row.HitFlag6 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText6, hitFlag: row.HitFlag6 }) : isContinue = false;
                row.BodyText7 !== undefined && row.HitFlag7 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText7, hitFlag: row.HitFlag7 }) : isContinue = false;
                row.BodyText8 !== undefined && row.HitFlag8 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText8, hitFlag: row.HitFlag8 }) : isContinue = false;
                row.BodyText9 !== undefined && row.HitFlag9 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText9, hitFlag: row.HitFlag9 }) : isContinue = false;
                row.BodyText10 !== undefined && row.HitFlag10 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText10, hitFlag: row.HitFlag10 }) : isContinue = false;
                row.BodyText11 !== undefined && row.HitFlag11 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText11, hitFlag: row.HitFlag11 }) : isContinue = false;
                row.BodyText12 !== undefined && row.HitFlag12 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText12, hitFlag: row.HitFlag12 }) : isContinue = false;
                row.BodyText13 !== undefined && row.HitFlag13 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText13, hitFlag: row.HitFlag13 }) : isContinue = false;
                row.BodyText14 !== undefined && row.HitFlag14 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText14, hitFlag: row.HitFlag14 }) : isContinue = false;
                row.BodyText15 !== undefined && row.HitFlag15 !== undefined && isContinue ?
                    importChoice.push({ bodyText: row.BodyText15, hitFlag: row.HitFlag15 }) : isContinue = false;

                for (let i = 0; i < importChoice.length; i++) {
                    newChoices[i] = {
                        id: i + 1,
                        editOrder: i + 1,
                        viewOrder: i + 1,
                        bodyText: importChoice[i].bodyText,
                        hitFlag: importChoice[i].hitFlag,
                        deleteFlag: false
                    };
                }

                if (row.SectionFlag) {
                    parentId = maxId + idCountUp;
                    parentOrder = idCountUp;
                }

                // bodyHtmlの編集
                const contentState = editorState.getCurrentContent();
                const raw = convertToRaw(contentState);
                if (row.QuestionText !== null) {
                    raw.blocks[0].text = row.QuestionText;
                } else {
                    raw.blocks[0].text = " ";
                }

                // 画像の設定
                if (row.ImageFile !== undefined) {
                    raw.blocks.push({ key: "qqqq", text: " ", type: "atomic", depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} });
                    raw.blocks.push({ key: "rrrr", text: "", type: "unstyled", depth: 0, inlineStyleRanges: [], entityRanges: [], data: {} });
                    raw.blocks[1].entityRanges.push({
                        offset: 0,
                        length: 1,
                        key: 0
                    });

                    let newBase64 = null;
                    for (const image of imageData) {
                        if (row.ImageFile === image.name) {
                            newBase64 = image.base64
                            break;
                        }
                    }
                    if (newBase64 === null) {
                        const noImageFile = {
                            name: row.ImageFile,
                            base64: ""
                        };
                        noneImageData.push(noImageFile);
                    }

                    raw.entityMap = ({
                        0: {
                            type: "image", mutability: "IMMUTABLE", data: { src: newBase64 }
                        }
                    });

                }

                const newImportQuestion : any = {
                    id: idCountUp,
                    executionId: parseInt(params.examId),
                    sectionFlag: row.SectionFlag,
                    parentQuestionId: row.AffiliationFlag ? parentId : 0,
                    editOrder: idCountUp,
                    viewOrder: idCountUp,
                    parentOrder: row.AffiliationFlag ? parentOrder : 0,
                    subjectText:(row.QuestionText !== undefined && row.QuestionText !== null) ? row.QuestionText.slice(0, 20) : null,
                    bodyHtml: JSON.stringify(raw, null, 0),
                    points: row.Points,
                    answerType: row.AnswerType,
                    choiceShuffleFlag: row.ChoiceShuffleFlag,
                    choiceNumberingType: row.ChoiceNumberingType,
                    deleteFlag: false,
                    modified: dtNow,
                    startViewFlag: false,
                    choices: newChoices,
                    figures: []
                }
                importQuestion.push(newImportQuestion);
                idCountUp++;
            }
            setImportData(importQuestion);
            setImportCount(items.length);

            if (noneImageData.length !== 1) {
                const list = [];
                noneImageData.shift();
                for (let i = 0; i < noneImageData.length; i++) {
                    if (i !== noneImageData.length - 1) {
                        list.push(noneImageData[i].name + ", ");
                    } else {
                        list.push(noneImageData[i].name);
                    }
                }
                setModalText(<div className={classNames.modalText}>
                    <p>下記の画像ファイルが選択されていませんでした</p>
                    {list}
                </div>);
                openConfirmModal(() => { });
            }

        } catch (e) {
            alert(e);
        }
    }

    //Excelファイルのバリデーション
    const validateExcelFile = (prop:any[]) => {

        var oldSectionFlag = false;
        var oldAffiliationFlag = false;
        var count = 2;
        setErrorMessage("");
        var addErrorMessage = "";

        for (const data of prop) {

            // 選択肢列と正解フラグ列両方に正しい値でない場合、エラー
            const isExactPair = checkExactPair(data);

            // 「セクションフラグ：TRUE、セクション所属フラグ：TRUE」の場合、エラー
            const isParentSection = (data.SectionFlag && data.AffiliationFlag ? false : true);

            //　先頭行に「セクションフラグ：FALSE、セクション所属フラグ：TRUE」がある場合、エラー
            const isChildSection = ((oldSectionFlag === false && oldAffiliationFlag === false) && (data.SectionFlag === false && data.AffiliationFlag === true)) ? false : true;

            //　解答種別と正解フラグの組み合わせが正しくない場合、エラー
            const isSelection = (data.SectionFlag === true && data.AffiliationFlag === false) ? true : checkHitFlag(data);

            if (!isExactPair) {
                addErrorMessage = addErrorMessage + count + "行目の選択肢と正解フラグの組み合わせが正しくありません\n";
            }
            if (!isParentSection) {
                addErrorMessage = addErrorMessage + count + "行目のセクションの親問題の設定が正しくありません\n";
            }
            if (!isChildSection) {
                addErrorMessage = addErrorMessage + count + "行目のセクションの子問題の設定が正しくありません\n";
            }
            if (!isSelection) {
                addErrorMessage = addErrorMessage + count + "行目の解答種別と正解フラグの組み合わせが正しくありません\n";
            }

            oldSectionFlag = data.SectionFlag;
            oldAffiliationFlag = data.AffiliationFlag;
            count++;
        }
        setErrorMessage(addErrorMessage);
    };

    // 選択肢と正解フラグの組み合わせチェック
    const checkExactPair = (row: any) => {
        const textList = [row.BodyText1, row.BodyText2, row.BodyText3, row.BodyText4, row.BodyText5, row.BodyText6, row.BodyText7,
            row.BodyText8, row.BodyText9, row.BodyText10, row.BodyText11, row.BodyText12, row.BodyText13, row.BodyText14, row.BodyText15];
        const hitList = [row.HitFlag1, row.HitFlag2, row.HitFlag3, row.HitFlag4, row.HitFlag5, row.HitFlag6, row.HitFlag7,
            row.HitFlag8, row.HitFlag9, row.HitFlag10, row.HitFlag11, row.HitFlag12, row.HitFlag13, row.HitFlag14, row.HitFlag15];
        let isOk = false;
        for (let i = 0; i < textList.length; i++) {
            if ((textList[i] !== undefined && hitList[i] !== undefined) || (textList[i] === undefined && hitList[i] === undefined)) {
                isOk = true;
            } else {
                isOk = false;
                break;
            }   
        }

        return isOk;
    }

    // 解答種別と正解フラグの整合性チェック
    const checkHitFlag = (row: any) => {
        var hitCount = 0;
        const hitFlagList = [row.HitFlag1, row.HitFlag2, row.HitFlag3, row.HitFlag4, row.HitFlag5, row.HitFlag6, row.HitFlag7,
            row.HitFlag8, row.HitFlag9, row.HitFlag10, row.HitFlag11, row.HitFlag12, row.HitFlag13, row.HitFlag14, row.HitFlag15];
        for (const hit of hitFlagList) {
            if (hit) hitCount += 1;
        }

        if ((row.AnswerType !== 0 && hitCount === 0) || (row.AnswerType === 0 && hitCount !== 1)) {
            return false;
        }

        return true;
    }

    //処理の確認モーダルを表示(実行する関数を渡す)
    const openConfirmModal = (executionFunc: any) => {
        setModalFunc(() => executionFunc);
        setOpenModal(true);
    }
    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState(<div></div>);
    const [modalFunc, setModalFunc] = React.useState<any>();
    const dtNow = new Date().toISOString();

    // -----スタイルの宣言-----
    const classNames = useStyles();
    
    const imageDataValue = {
        name: "",
        base64: ""
    };

    // -----state-----
    const [isUploaded, setIsUploaded] = React.useState(false);
    const [examValue, setExamValue] = React.useState(initialExamStateValue);
    const [questionValue, setQuestionValue] = React.useState<Question[]>([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [importCount, setImportCount] = React.useState(0);
    const [importData, setImportData] = React.useState<Question[]>([]);
    const [existQuestion, setExistQuestion] = React.useState(false);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const [imageData, setImageData] = React.useState([imageDataValue]);

    //acceptedFilesにファイルが格納される(配列に常に一つだけ入る)
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*"

    });
    const files = acceptedFiles.map((file, idx) => (
        <li key={idx}>{file.name}</li>
    ));

    let noneImageData = [imageDataValue];

    // -----use effefct-----
    //ファイルが選択されたときに実行される
    React.useEffect(() => {
        if (acceptedFiles.length === 0) return;

        let isExcel = false;
        acceptedFiles.forEach((file) => {
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                isExcel = true;
            }
        });

        if (!isExcel) {
            setErrorMessage("Excelファイルを選択してください");
            return;
        }

        acceptedFiles.sort((a, b) => {
            const aType = a.type;
            const bType = b.type;
            if (aType < bType) {
                return 1;
            }
            if (aType > bType) {
                return -1;
            }
            return 0;
        });

        acceptedFiles.forEach((file) => {
            // Excel読み込み
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                const reader = new FileReader();
                const ids = new Set();

                reader.onloadend = (e) => {
                    const bufferArray = reader.result;
                    const workbook = new ExcelJS.Workbook();
                    workbook.xlsx
                        .load(bufferArray)
                        .then((sheet: any) => {
                            workbook.worksheets[0].eachRow({ includeEmpty: false }, (row: { values: any[] }) => {
                                if (row) {
                                    ids.add(row);
                                }
                            });
                            if (ids.size === 1) {
                                setErrorMessage("空白のExcelファイルが選択されました");
                                return;
                            } else {
                                loadExcelData(ids);
                            }
                        });
                };
                reader.readAsArrayBuffer(file);
            // 画像ファイル読み込み
            } else if (!file.type.indexOf("image")) {
                const reader = new FileReader();
                reader.onloadend = (ev) => {
                    let importImageData = imageData;
                    const newImageData = {
                        name: file.name,
                        base64: reader.result as string
                    };
                    importImageData.push(newImageData);
                    setImageData(importImageData);
                };
                reader.onerror = (ev) => {
                    alert(ev);
                };
                reader.onabort = (ev) => {
                    alert(ev);
                };
                reader.readAsDataURL(file);
            }
        });
    }, [acceptedFiles]);

    //初回だけ実行
    React.useEffect(() => {

        const argsGetQuestion: APIArgsQuestionGet = { executionId: parseInt(params.examId) }
        getQuestion(argsGetQuestion)
            .then((data: APIResponse<ExamQuestion>) => {
                if (!data) {
                    alert(common.ResponseMessages.Error_GetQuestion);
                    return;
                }

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                if (data.value.questions !== null) {
                    setExistQuestion(data.value.questions.length > 0);
                    setQuestionValue(data.value.questions);
                }

                //試験名を取得
                getExamData({ id: params.examId })
                    .then((exam: APIResponse<Exam[]>) => {

                        if (exam.errorCode !== 20000) {
                            common.alertError(exam.errorTitle, exam.errorDetail);
                            return;
                        }

                        if (exam.value !== null) setExamValue(exam.value[0]);

                    }).catch((err) => {
                        alert(common.ResponseMessages.Error_GetExam);
                    });

            })
            .catch((err: any) => {
                alert(err);
            });

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);

    return (

        <Layout navLinks={normalLinks} buttons={
            [
                { text: goExamRegister.text, handler: () => { go({ pathname: goExamRegister.pathname, state: goExamRegister.state }) } }
            ]
        } width="780px">
            {

                !isUploaded ?

                    <div className={classNames.uploadRoot}>

                        <div >
                            <h4>{examValue.name} 問題インポート</h4>
                        </div>

                        <div>
                            <CustomModal
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            modalFunc={modalFunc}
                            isNotice={true}
                        >
                            {modalText}
                        </CustomModal>
                        </div>

                        <div className={classNames.dropZone}>

                            <div {...getRootProps({ className: 'dropzone' })} className={classNames.fileDropZone}>
                                <input {...getInputProps()} />
                                <p>ドロップまたはクリックしてExcelファイル、<br></br>画像ファイルを選択して下さい</p>
                            </div>

                            <ul hidden={acceptedFiles.length === 0} className={classNames.filePreview}>
                                {files}
                            </ul>

                        </div>

                        <Box hidden={true}>
                            <Editor editorState={editorState} onChange={setEditorState}/>
                        </Box>

                        <div className={classNames.erroMassage} hidden={errorMessage === ""}><h5 style={{ color: "red", whiteSpace: "pre", textAlign: "left" }}>{errorMessage}</h5></div>

                        <ButtonContainer className={classNames.buttonStyle}>
                            <DefaultBackButton className="backBtn" onClick={() => { go({ pathname: "/exam/index/" + params.examId }) }}>戻る</DefaultBackButton>
                            <DefaultButton onClick={onClickImport} disabled={errorMessage !== ""}><CloudUploadIcon fontSize="large" /><span>&nbsp;インポート</span></DefaultButton>
                        </ButtonContainer>

                    </div>

                    :

                    <div className={classNames.uploadRoot}>

                        <div>
                            <h4>{examValue.name} 問題インポート完了</h4>
                        </div>

                        <div>
                            <h5>{importCount}問　インポート完了しました。</h5>
                        </div>

                        <ButtonContainer className={classNames.buttonStyle}>
                            <DefaultButton onClick={() => { go({ pathname: "/exam/index/" + params.examId }) }}>試験一覧へ</DefaultButton>
                            <DefaultButton onClick={() => { go({ pathname: "/edit-question/" + params.examId + "/start-screen" }) }}>問題編集へ</DefaultButton>
                        </ButtonContainer>

                    </div>
            }
        </Layout>
    );
}