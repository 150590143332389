import React from 'react';

import {useDropzone} from 'react-dropzone';
import { createUseStyles } from 'react-jss';
import Checkbox from '@material-ui/core/Checkbox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Layout } from '../Layout';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import * as common  from "../../common";
import {readTextFile}  from "../../common";

import { LearnerUploadTable } from '../Table/LearnerUploadTable';
import { APIResponse} from '../../react-app-env';
import { Exam ,learnerNotify , APIArgsLearnerNotifyPost,learnerCSV ,Learner} from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import {initialExamStateValue}  from '../Exam/Index';
import { initialLearnerStateValue } from '../Learner/Index';
import { CustomModal } from "../StylesUI/CustomModal";
import { JsxElement } from 'typescript';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    uploadRoot: {
        fontSize: common.FONT_SIZE.mainText,
        textAlign:"center",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        },
        "& h5":{
            fontSize: common.FONT_SIZE.mainText,
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    dropZone: {
        position: "relative",
        width: 500,
        marginLeft:"auto",
        marginRight:"auto"
    },
    fileDropZone:{
        width: 500,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderStyle: 'dashed',
        borderRadius: 5,
        marginBottom:16,
        cursor:"pointer"
      },
    filePreview:{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        display: "inline-block",
        height: 32,
        borderRadius: 16,
        listStyle: "none",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 2
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px"
    },
    buttonStyle: {
        display:"block",
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"50px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    notifyCheckBox:{
        fontSize:common.FONT_SIZE.mainText,
        "& svg":{
            fontSize:28
        },
        "& span":{
            paddingLeft:"0"
        }
    },
    modalText: {
        fontSize: "2rem",
        fontWeight: "bold",
        padding: "2rem 0",
        textAlign: "center",
        whiteSpace: "pre"
    }
});



export interface uploadLearnerData{
    learnerNumber:string,
    email:string
}




export function CsvUpload() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();
    

    //-----API----------
    //試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    function postLearnerNotify(args?: APIArgsLearnerNotifyPost):Promise<APIResponse<learnerNotify>> {
        return api("/api/o-learnerNotify", "POST", args)
    }

    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }



    // -----Handler-----
    //アップロード完了時の通知(未実装)
    const handleNoticeClick =(event: React.ChangeEvent<HTMLInputElement>)=>{
        setIsNotify(event.target.checked)
    };


    // CSVから取り込んだ受験者データを送信する
    function uploadLearnerData(args?: any) {
        return api("/api/o-learnerCSV", "POST", args)
    };

    


    //アップロード実行
    function onClickUpload(){
        //APIで受験者データをアップロードして画面遷移
        if(errorMessage === "" && acceptedFiles.length !== 0 && learnerData.length !== 0){

            const totalLearners = learnerStateValue.length + learnerData.length;
            if( totalLearners > examValue.learnerMax ) {
                alert("最大受験者数を超えます。");
                return;
            }

            const massage = "通知の送信には時間がかかる場合があります。\nアップロード後通知の送信を開始してもよろしいでしょうか？";

            const upload = () => {
                uploadLearnerData({ executionId: Number(params.examId), csvData: learnerData })
                    .then((data: any) => {

                        if (data.errorCode !== 20000) {
                            common.alertError(data.errorTitle, data.errorDetail);
                            return;
                        }

                        const count = learnerData.length;
                        setUploadCount(count);
                        setIsUploaded(true);

                        if (isNotify) {
                            sendNotification(data.value);
                        }

                    })
                    .catch((err) => {
                        alert(common.ResponseMessages.Error_PostLearnerCSV);
                    });
            };

            if (isNotify) {
                // アップロード後通知送信する場合モーダル表示
                handleModalOpen(massage, () => {
                    upload();
                });
            } else {
                upload();
            }
        } 
    }


    const sendNotification=(learnerArgs:string[])=>{
        
        postLearnerNotify({executionId:examValue.id,learners:learnerArgs})
        .then((data)=>{

            alert("通知結果\n成功数：" + data.value.pass +"　失敗数：" + data.value.fail +
                    "\n失敗受験者："+data.value.failLearners)
            
        }).catch((err) => {
            alert(err);
        }); 

    }


    // -----汎用関数の宣言-----


    const specifyEmailError=(learnerData:uploadLearnerData[])=>{
        let errorLearners=[];
        for(const data of learnerData){
            if(!common.validateEmailFunc(data.email).correctFlag) errorLearners.push(data.learnerNumber)
        };
        return errorLearners;
    }


    //空白チェック
    const validateBlank=(learnerNumber:string,email:string)=>{
        if(learnerNumber===undefined || email===undefined){
            return true;
        }  
        return false;
    }



    //CSVファイルのバリデーション
    const validateCavFile=(learnerData:uploadLearnerData[])=>{

        let correctFlag = true;

        for(const data of learnerData){

            const isBlankCSV=validateBlank(data.learnerNumber,data.email);
            const isErrorEmail=!common.validateEmailFunc(data.email ?? "").correctFlag;

            if(isBlankCSV){
                correctFlag=false;
                setErrorMessage("正しいフォーマットのCSVファイルを選択して下さい");
                break;
            }else if(isErrorEmail){
                correctFlag=false;
                setErrorMessage("受験番号："+specifyEmailError(learnerData)+"の受験者メールアドレスが正しくありません");
                break;
            }
        }
        if(correctFlag){
             setErrorMessage("");
        }
    };

    function handleModalOpen(modalText: string, modalFunc: any) {
        setIsNotice(false);
        setModalFunction(() => modalFunc);
        setModalText(modalText);
        setOpenModal(true);
    }

    // -----スタイルの宣言-----
    const classNames = useStyles();
    

    

    // -----state-----

    const [isNotify,setIsNotify]=React.useState(false);
    const [isUploaded,setIsUploaded]=React.useState(false);
    const [UploadCount,setUploadCount]=React.useState(0);
    //acceptedFilesにファイルが格納される(配列に常に一つだけ入る)
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({accept:"text/csv, application/vnd.ms-excel"});
    const [learnerData,setLearnerData]=React.useState<uploadLearnerData[]>([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [examValue, setExamValue]= React.useState(initialExamStateValue);
    const [learnerStateValue, setLearnerStateValue]= React.useState<Learner[]>([initialLearnerStateValue]);
    

    const files = acceptedFiles.map((file,idx) => (
        <li key={idx}>{file.name}</li>
    ));
    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [isNotice, setIsNotice] = React.useState(false);
    const [modalText, setModalText] = React.useState<string | JsxElement>("");
    const [modalFunction, setModalFunction] = React.useState<any>(() => { });
    
    
    // -----use effefct-----
    //ファイルが選択されたときに実行される
    React.useEffect(() => {
        if(acceptedFiles.length===0) return;

        const csvFile = acceptedFiles[0];

        readTextFile(csvFile,  "utf-8")
        .then((result)=>{

            //acceptedFilesをJSONに変換、LearnerDataに格納
            common.parseCsv(result, {headers: ["email", "learnerNumber"]}).then((data:any) => {
                data.shift();
                setLearnerData(data);
                validateCavFile(data);
            }); 

        }).catch((err) => {
            alert(err);
        });

    }, [acceptedFiles]);


    //初回だけ実行
    React.useEffect(() => {
        //試験名を取得
        getExamData({id:params.examId})
        .then((data:APIResponse<Exam[]>) => {

            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null) setExamValue(data.value[0]);

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });

        getLearnerData({executionId:params.examId})
            .then((data:APIResponse<Learner[]>) => {

                if (data.errorCode !==20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){
                     setLearnerStateValue( data.value );
                }

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);




    
    return (

        
       
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          } width="780px">
            {

            !isUploaded ?

            <div className={classNames.uploadRoot}>
            
                <CustomModal
                    openModal = {openModal}
                    setOpenModal = {setOpenModal}
                    modalFunc = {modalFunction}
                    isNotice = {isNotice}
                >
                    <div className={classNames.modalText}>{modalText}</div>
                </CustomModal>

                <div >             
                    <h4>{examValue.name} 受験者CSVアップロード</h4>                              
                </div>

                <div className={classNames.dropZone}>

                    <div {...getRootProps({className: 'dropzone'})} className={classNames.fileDropZone}>
                        <input {...getInputProps()} />
                        <p style={{margin:"0"}}>ドロップまたはクリックしてCSVファイルを選択して下さい
                        </p>
                    </div>

                    <ul hidden={acceptedFiles.length===0} className={classNames.filePreview}>
                        {files}
                    </ul>

                </div>

                <div><h5 style={{color:"red"}} hidden={errorMessage===""}>{errorMessage}</h5></div>

                <div hidden={acceptedFiles.length===0 || errorMessage!==""}><LearnerUploadTable learnerData={learnerData}/></div>

                <div className={classNames.notifyCheckBox}>
                    <Checkbox onChange={handleNoticeClick}/>
                    アップロード完了時受験者に通知を送信
                </div>   

                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go({pathname:"/learner/index/"+params.examId }) }}>戻る</DefaultBackButton>
                    <DefaultButton className="uploadBtn" onClick={onClickUpload}><CloudUploadIcon fontSize="large"/><span>&nbsp;アップロード</span></DefaultButton>
                </ButtonContainer>
                
            </div>

            :

            <div className={classNames.uploadRoot}>

                <div>             
                    <h4>{examValue.name} 受験者CSVアップロード完了</h4>                              
                </div>

                <div>
                    <h5>{UploadCount} 件登録しました</h5>
                </div>

                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go({pathname:"/learner/index/"+params.examId}) }}>戻る</DefaultBackButton>
                </ButtonContainer>
                
            </div>
            
            }

        </Layout>

        
    );
}

