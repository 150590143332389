import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";

import { Layout } from '../Layout';
import * as common from "../../common";
import { transitUserValue,initialUserStateValue} from '../AdminUser/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { User ,APIResponse} from '../../react-app-env';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import {CustomModal} from "../StylesUI/CustomModal"

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    adminUserConfirmRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"650px",
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px",
        marginTop:"25px"
    },
    buttonStyle: {
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"30px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        height:"8rem",
        pointerEvents :"none",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& td": {
            width:"260px"
        },
        "& input ,textarea ,.MuiFormHelperText-root":{
            fontSize:common.FONT_SIZE.mainText,
        },
        "& #remark":{
            padding:0,
            lineHeight:1.2
        },
        "& .MuiOutlinedInput-multiline":{
            padding:0
        }
    },
    labelStyle: {
        paddingRight: "60px"
    },
    modalText: {
        fontSize:"2rem",
        fontWeight:"bold",
        padding:"2rem 0",
        textAlign:"center"
    }
}));



export function AdminUserConfirm() {

    // -----定数の定義-----
    //遷移元から受け取る主催者データ
    const location:transitUserValue=useLocation();
    const userValue:User=location.state;



    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();


    
    // -----API-----
    // 主催者ユーザーを取得する
    function getAdminUser(args?:any):Promise<APIResponse<User[]>>{
        return api("/api/o-user", "GET",args)
    }


    // 主催者ユーザデータを送信する
    function sendAdminUserData(args?: any) {
        return api("/api/o-user", "POST", args)
    }

    // 主催者データを変更する
    function updateAdminUserData(args?: any) {
        return api("/api/o-user", "PUT", args)
    }

    // 主催者データを削除する
    function deleteAdminUserData(args?: any) {
        return api("/api/o-user", "DELETE", args)
    }


    // -----Handlerの宣言-----
    //登録処理
    function execute(url:string) {
        switch (params.type) {

            case "register": 
                sendAdminUserData(userStateValue)
                .then((data:APIResponse<User>) => {

                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle , data.errorDetail);
                        return;
                    }

                    handleModalOpen(common.ResponseMessages.Success_PostAdminUser);

                }).catch((err) => {

                    alert(common.ResponseMessages.Error_PostAdminUser);

                }); break;

            case "edit": 
                updateAdminUserData(userStateValue)
                .then((data:APIResponse<User>) => {

                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle , data.errorDetail);
                        return;
                    }

                    handleModalOpen(common.ResponseMessages.Success_PutAdminUser);

                }).catch((err) => {

                    alert(common.ResponseMessages.Error_PutAdminUser);

                }); break;

            case "delete": 
                if(!userValue.deleteFlag){
                    deleteAdminUserData(userStateValue)
                    .then((data:APIResponse<User>) => {

                        if (data.errorCode !== 20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        handleModalOpen(common.ResponseMessages.Success_DisableAdminUser);

                    }).catch((err) => {

                        alert(common.ResponseMessages.Error_DisableAdminUser);

                    })}
                else{
                    updateAdminUserData(getActivationValue())
                    .then((data:APIResponse<User>) => {
                            
                        if (data.errorCode !== 20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        handleModalOpen(common.ResponseMessages.Success_EnableAdminUser);

                    }).catch((err) => {

                        alert(common.ResponseMessages.Error_EnableAdminUser);

                    });  
                    }
                break;
        }
    }

    const goBack=()=>{
        switch (params.type) {
            case "register": go({pathname:"/admin-user/register/"+params.userId,state:userStateValue}); break;
            case "edit": go({pathname:"/admin-user/edit/"+params.userId,state:userStateValue}) ; break;
            case "delete": go({pathname:"/admin-user/edit/"+params.userId,state:userStateValue}); break;
        }
    }


    //ユーザーの有効化
    const getActivationValue=()=>{
      return  {   
            id:userStateValue.id,
            name:userStateValue.name ,
            nameKana:userStateValue.nameKana,
            email:userStateValue.email,
            phoneNumber:userStateValue.phoneNumber,
            note:userStateValue.note,
            modified:userStateValue.modified,
            deleteFlag:false,
            password:userStateValue.password 
        } 
    }



    const getAdminUserByID=()=>{
        getAdminUser({id:params.userId,showDeleted:true})
            .then((data:APIResponse<User[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null && data.value.length !== 0) setUserStateValue(data.value[0])

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetAdminUser);
            });
    }


    function handleModalOpen(modalText:string){
        setOpenModal(true);
        setModalText(modalText);
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();  




    // -----state-----
    const [state, setState] = React.useState({ type: "" });
    const [userStateValue, setUserStateValue] = React.useState<User>(initialUserStateValue);
    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState("");


    //遷移元によって登録、変更、削除に分岐
    React.useEffect(() => {
        if(userValue===undefined){
            go("/admin-user/index"); 
        } else setUserStateValue(userValue);

        switch (params.type) {
            case "register": setState({ type: "登録" }); break;
            case "edit": setState({ type: "編集" }); break;
            case "delete": const type = userValue.deleteFlag ? "有効化" : "無効化"; 
                           setState({ type: type  }); 
                           getAdminUserByID();
                           break;
        }

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);


    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.adminUserConfirmRoot}>

                <h4>主催者ユーザ {state.type} 確認</h4>

                <CustomModal
                    openModal= {openModal}
                    setOpenModal= {setOpenModal}
                    modalFunc= {()=>{go({pathname:"/admin-user/index"})}}
                    isNotice = {true}
                >
                    <div className={classNames.modalText}>{modalText}</div>
                </CustomModal>

                <table>
                    <tbody>
                        <tr className={classNames.formStyle}>
                            <th><label>氏名</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.name}  InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>フリガナ</label></th>
                            <td><TextField style={{width:"200px"}} fullWidth value={userStateValue.nameKana}  InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>電話番号</label></th>
                            <td><TextField style={{width:"150px"}} fullWidth value={userStateValue.phoneNumber} InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>メールアドレス</label></th>
                            <td><TextField fullWidth value={userStateValue.email ?? ""} InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle} hidden={state.type!="登録"}>
                            <th><label>パスワード</label></th>
                            <td><TextField fullWidth value={userStateValue.password ?? ""} InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle} hidden>
                            <th className={classNames.labelStyle}><label>パスワード（確認用）</label></th>
                            <td><TextField fullWidth  InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th><label>備考</label></th>
                            <td><TextField id="remark" multiline rows={3} fullWidth value={userStateValue.note ?? ""}  InputProps={{
                                readOnly: true,disableUnderline: true
                            }}/></td>
                        </tr>
                    </tbody>
                </table>

                <ButtonContainer style={{marginTop:"50px"}} className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={goBack}>戻る</DefaultBackButton>
                    <DefaultButton  onClick={() => { execute("/admin-user/index") }}>実行</DefaultButton>
                </ButtonContainer>
                
            </div>
        </Layout>
    );
}



