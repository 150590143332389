import React from 'react';

import { createUseStyles } from 'react-jss';
import TextField from '@material-ui/core/TextField';
import { useLocation } from "react-router-dom";

import { Layout } from '../Layout';
import * as common from "../../common";
import { transitLearnerValue,initialLearnerStateValue} from '../Learner/Index';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { Learner ,APIArgsLearnerOperation,APIResponse} from '../../react-app-env';
import { Exam } from '../../react-app-env';
import {DefaultButton ,ButtonContainer ,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import {CustomModal} from "../StylesUI/CustomModal"


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    learnerConfirmRoot: {
        fontSize: common.FONT_SIZE.mainText,
        minWidth:"650px",
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    linkStyle: {
        opacity: 0.9,
        backgroundColor:"#4378b6", 
        padding:"6px 16px",
        borderRadius: "4px",
        color:"white",
        marginRight:"5px",
        marginTop:"25px"
    },
    buttonStyle: {
        "& button":{
            fontSize:common.FONT_SIZE.button,
            marginTop:"30px",
            marginRight:"15px"
        },
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    },
    formStyle: {
        height:"8rem",
        pointerEvents :"none",
        marginBottom:"2.5rem",
        display:"flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        "& td": {
            width:"260px"
        },
        "& input ,textarea ,.MuiFormHelperText-root ":{
            fontSize:common.FONT_SIZE.mainText,
        }
    },
    labelStyle: {
        paddingRight: "60px"
    },
    modalText: {
        fontSize:"2rem",
        fontWeight:"bold",
        padding:"2rem 0",
        textAlign:"center"
    }
});



export function LearnerConfirm() {

    // -----定数の定義-----
    //遷移元から受け取る受験者データ
    const location:transitLearnerValue=useLocation();
    const confirmValue:Learner=location.state


    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 f
        api  // API呼び出し
    } = common.useCommon();

    

    // -----API-----
    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    // 受験者データを登録する
    function registerLearnerData(args?: Learner|APIArgsLearnerOperation) {
        return api("/api/o-learner", "POST", args)
    }

    // 受験者データを変更する
    function updateLearnerData(args?: Learner|APIArgsLearnerOperation) {
        return api("/api/o-learner", "PUT", args)
    }

    // 受験者データを削除する
    function deleteLearnerData(args?: Learner|APIArgsLearnerOperation) {
        return api("/api/o-learner", "DELETE", args)
    }

    //試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }



    // -----Handler-----
    function execute(url:string) {
        switch (params.type) {
            case "register":registerLearnerData(getPostAPIArgs())
                            .then((data) => {
                                if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                handleModalOpen(common.ResponseMessages.Sucess_PostLearner);               
                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_PostLearner);
                            });
                            break;

            case "edit"    :updateLearnerData(learnerStateValue)
                            .then((data) => {
                                if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                handleModalOpen(common.ResponseMessages.Sucess_PutLearner);
                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_PutLearner);
                            });
                            break;

            case "delete"    :deleteLearnerData(convertDeleteArgs(learnerStateValue))
                            .then((data) => {
                                if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                handleModalOpen(common.ResponseMessages.Sucess_DeleteLearner);
                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_DeleteLearner);
                            });
            break;
        }
    }

    const goBack=()=>{
        switch (params.type) {
            case "register": go({pathname:"/Learner/register/"+params.examId+"/0",state:learnerStateValue}); break;
            case "edit": go({pathname:"/Learner/edit/"+params.examId+"/"+learnerStateValue.userName,state:learnerStateValue}) ; break;
            case "delete": go({pathname:"/Learner/edit/"+params.examId+"/"+learnerStateValue.userName,state:learnerStateValue}); break;
        }
    }


    // -----汎用関数定義-----

    const getPostAPIArgs=():APIArgsLearnerOperation=>{
        return{
            userName:learnerStateValue.userName,
            executionId:learnerStateValue.executionId,
            learnerNumber:learnerStateValue.learnerNumber ,
            email:learnerStateValue.email,
            checkResult:learnerStateValue.checkResult,
            checkUser:learnerStateValue.checkUser,
            modified:new Date(),
            deleteFlag:learnerStateValue.deleteFlag
        }
    }



    const getLearnerById=()=>{
        getLearnerData({executionId:params.examId,showDeleted:false ,userName:params.userName})
                     .then((data:APIResponse<Learner[]>) => {

                        if (data.errorCode !==20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        if(data.value[0] !== null && data.value.length !== 0) setLearnerStateValue(data.value[0]);

                     }).catch((err) => {
                         alert(common.ResponseMessages.Error_GetLearner);
                     });   
    }


    //受験者削除時、APIの引数用にデータを変換
    const convertDeleteArgs = (learnerData:Learner)=>{
        return {
            userName:learnerData.userName,
            executionId:learnerData.executionId,
            learnerNumber:learnerData.learnerNumber ,
            email:learnerData.email,
            checkResult:learnerData.checkResult,
            checkUser:learnerData.checkUser,
            modified:learnerData.modified,
            deleteFlag:learnerData.deleteFlag
        }
    }


    function handleModalOpen(modalText:string){
        setOpenModal(true);
        setModalText(modalText);
    }

    
    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    // -----state-----
    const [type, setType] = React.useState("");
    const [learnerStateValue,setLearnerStateValue]=React.useState<Learner>(initialLearnerStateValue);
    const [examName, setExamName]= React.useState("");
    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState("");
    


    // -----use effefct-----
    //遷移元によって登録、変更に分岐
    React.useEffect(() => {
        if(confirmValue===undefined){

            go("/learner/index/"+params.examId); 

        }else setLearnerStateValue(confirmValue);

        switch (params.type) {
            case "register": setType("登録"); break;
            case "edit": setType("編集"); break;
            case "delete": setType("削除"); 
                            getLearnerById();
                            break;
        }

        //試験名を取得
        getExamData({id:params.examId})
        .then((data:APIResponse<Exam[]>) => {

            if (data.errorCode !==20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null) setExamName(data.value[0].name);

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetExam);
        });

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);
  



    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            <div className={classNames.learnerConfirmRoot}>

                <h4>{examName} 受験者 {type} 確認画面</h4>

                <CustomModal
                    openModal= {openModal}
                    setOpenModal= {setOpenModal}
                    modalFunc= {()=>{go({pathname:"/Learner/index/"+params.examId})}}
                    isNotice = {true}
                >
                    <div className={classNames.modalText}>{modalText}</div>
                </CustomModal>

                <table>
                    <tbody>
                        <tr className={classNames.formStyle} hidden={type==="登録"}>
                            <th ><label>受験者ID</label></th>
                            <td><TextField fullWidth value={learnerStateValue.userName} name="userName"  InputProps={{
                                readOnly: true,disableUnderline: true 
                            }}/></td>
                        </tr>

                        <tr className={classNames.formStyle}>
                            <th className={classNames.labelStyle}><label>ID（受験番号）</label></th>
                            <td><TextField fullWidth value={learnerStateValue.learnerNumber}  InputProps={{
                                readOnly: true,disableUnderline: true 
                            }}/></td>
                        </tr>
                        
                        <tr className={classNames.formStyle}>
                            <th><label>メールアドレス</label></th>
                            <td><TextField fullWidth value={learnerStateValue.email} InputProps={{
                                readOnly: true,disableUnderline: true 
                            }}/></td>
                        </tr>              
                    </tbody>
                </table>

                <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" onClick={goBack}>戻る</DefaultBackButton>
                        <DefaultButton onClick={() =>{execute("/learner/index/"+params.examId)}}>実行</DefaultButton>
                </ButtonContainer>
                
            </div>
        </Layout>
    );
}
