import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import {RichEditor,toPlainText} from '../RichEditor/RichEditor'
import {Question as IQuestion } from '../../react-app-env';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import * as common from "../../common";

const useStyles = makeStyles((theme) => ({
    sectionRoot: {
        "& svg":{
            fontSize:"2rem"
        },
        fontSize: common.FONT_SIZE.mainText,
    },
    toolbar:{
        color:"white",
        background:"gray",
        minHeight:"36px"
    },
    titleText : {
        margin:"auto",
        fontSize:common.FONT_SIZE.mainText,
        fontWeight:"bold"
    },
    settingRoot: {
        padding: "20px"
    },
    choiceType: {
        "& .MuiRadio-root": {
            paddingLeft: "28px"
        },
        "& .MuiRadio-root:first-child": {
            paddingLeft: "8px"
        }
    }
}));

export function Section(props:{question:IQuestion,onChangeQuestionBodyHtmlHandler:any,onChangeRadioSectionStartViewFlagHandler:any}) {
    const classNames = useStyles();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const startViewFlag = event.target.value === "true" ? true : false;
        
        props.onChangeRadioSectionStartViewFlagHandler(props.question.id, startViewFlag)
    };
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.sectionRoot}>
            <Card >
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        セクション問題設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <Box fontWeight="fontWeightBold" color="#003f71">セクション開始画面</Box>
                    <Box paddingLeft={1} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                        <Radio
                            value={true}
                            onChange={handleChange}
                            checked={props.question?.startViewFlag === true}
                        />
                        使用する
                        <Radio
                            value={false}
                            onChange={handleChange}
                            checked={props.question?.startViewFlag === false}
                        />
                        使用しない
                    </Box>
                    <Box fontWeight="fontWeightBold"  marginTop={2} color="#003f71">説明文</Box>
                    <div>
                        <RichEditor key={props.question?.id} defaultValue={props.question?.bodyHtml} onChange={(text)=>{props.onChangeQuestionBodyHtmlHandler(props.question.id,text,toPlainText(text))}}/>
                    </div>
                </Box>
            </Card>
        </div>
    );
}
