import React from 'react';

import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import { QuestationTable } from '../Table/QuestationTable';
import { PlacesTable } from '../Table/PlacesTable';
import { Exam, ExamQuestion, Question as IQuestion, ExamExtensions, Extension } from '../../react-app-env';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import * as common from "../../common";

const useStyles = makeStyles((theme) => ({
    settingAllocationRoot: {
        "& svg": {
            fontSize: "2rem"
        },
        "& .MuiInputBase-input": {
            fontSize: common.FONT_SIZE.mainText
        }
    },
    toolbar: {
        color: "white",
        background: "gray",
        minHeight: "36px"
    },
    titleText: {
        margin: "auto",
        fontSize: common.FONT_SIZE.mainText,
        fontWeight: "bold"
    },
    settingRoot: {
        padding: "20px"
    },
    choiceType: {
        marginTop: "8px",
        alignItems: "flex-start",
        "& .MuiRadio-root": {
            padding: "2px 4px 4px 28px"
        },
        "& .MuiRadio-root:first-child": {
            padding: "2px 4px 4px 4px"
        },
        "& .MuiTextField-root": {
            paddingLeft: "20px"
        }
    }
}));

const CODE_RECORD = "record";
const CODE_LEAVE = "leave";
const CODE_PLACE = "place";

export function SettingAllocation(props: {
    question: IQuestion[],
    exam: ExamQuestion,
    extensions: ExamExtensions,
    executionPlace: any,
    onChangeRadioShuffleQuestionHandler: any,
    onChangeChoiceDeviceFlagHandler: any,
    onChangeAfterReadFlagHandler: any,
    onChangePassPointsHandler: any,
    onChangeQuestionPointsHandler: any,
    onChangeExtensionHandler: (newValue: ExamExtensions) => any,
    onChangePlaceTextHandler: any,
    onClickDeletePlaceHandler: any,
    onClickAddNewPlaceHandler: any,
    setQuestionHeadnum: any
}) {
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    const classNames = useStyles();

    const toBoolean = (booleanStr: string): boolean => {
        // "true"文字列と比較した結果を返す
        // 念のため小文字化しておく
        return booleanStr.toLowerCase() === "true";
    }

    const [extensionsArray, setExtensionsArray] = React.useState(props.extensions?.extensions ?? []);

    const extLeave = extensionsArray.filter(x => x.code == CODE_LEAVE);
    const bReleaseLeave = extLeave.length > 0;
    const bUseLeave = extLeave.filter(x => x.parameter == 1).length > 0;

    const extRecord = extensionsArray.filter(x => x.code == CODE_RECORD);
    const bReleseRecord = extRecord.length > 0;
    const bUseRecord = extRecord.filter(x => x.parameter == 1).length > 0;

    const extPlace = extensionsArray.filter(x => x.code == CODE_PLACE);
    const bRelesePlace = extPlace.length > 0;

    const toggleExtension = (code: string, bEnable: boolean) => {
        extensionsArray.forEach(x => {
            if (x.code == code) {
                x.parameter = (bEnable ? 1 : 0);
            }
        });
        setExtensionsArray(extensionsArray);
        props.onChangeExtensionHandler({
            ...props.extensions,
            extensions: extensionsArray
        });
    };
    return (
        <div className={classNames.settingAllocationRoot}>
            <Card>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        出題設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <div>
                        <Box fontWeight="fontWeightBold" color="#003f71">シャッフル出題</Box>
                        <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                            <Radio
                                checked={props.exam.shuffleFlag === false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeRadioShuffleQuestionHandler(toBoolean(event?.target.value)); }}
                                value={false}
                            />
                            問題順を固定して出題
                            <Radio
                                checked={props.exam.shuffleFlag === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeRadioShuffleQuestionHandler(toBoolean(event?.target.value)); }}
                                value={true}
                            />
                            問題順をシャッフルして出題
                        </Box>
                        <Box fontWeight="fontWeightBold" color="#003f71">問題文・解答欄の表示形式</Box>
                        <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                            <Radio
                                checked={props.exam.choiceDeviceFlag === false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeChoiceDeviceFlagHandler(toBoolean(event?.target.value)); }}
                                value={false}
                            />
                            PCに問題文と解答欄を表示
                            <Radio
                                checked={props.exam.choiceDeviceFlag === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeChoiceDeviceFlagHandler(toBoolean(event?.target.value)); }}
                                value={true}
                            />
                            PCに問題文、スマートフォンに解答欄を表示
                        </Box>
                        {bReleseRecord ? <React.Fragment>
                            <Box fontWeight="fontWeightBold" color="#003f71">受験中の録画</Box>
                            <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                                <Radio
                                    checked={bUseRecord}
                                    onChange={(event) => {
                                        toggleExtension(CODE_RECORD, true);
                                    }}
                                    value={true}
                                />
                                録画する
                                <Radio
                                    checked={!bUseRecord}
                                    onChange={(event) => {
                                        toggleExtension(CODE_RECORD, false);
                                    }}
                                    value={false}
                                />
                                録画しない
                            </Box>
                            <Box paddingLeft={2}>
                                ※この機能は「問題文・解答欄の表示形式」で「PCに問題文、スマートフォンに解答欄を表示」を選択した場合に有効です
                            </Box>
                        </React.Fragment> : undefined}
                        {bReleaseLeave ? <React.Fragment>
                            <Box fontWeight="fontWeightBold" color="#003f71">途中退席</Box>
                            <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                                <Radio
                                    checked={bUseLeave}
                                    onChange={(event) => {
                                        toggleExtension(CODE_LEAVE, true);
                                    }}
                                    value={true}
                                />
                                許可する
                                <Radio
                                    checked={!bUseLeave}
                                    onChange={(event) => {
                                        toggleExtension(CODE_LEAVE, false);
                                    }}
                                    value={false}
                                />
                                許可しない
                            </Box>
                        </React.Fragment> : undefined}
                        <Box fontWeight="fontWeightBold" color="#003f71">問題文・解答選択肢の試験終了後の閲覧</Box>
                        <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                            <Radio
                                checked={props.exam.afterReadFlag === false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeAfterReadFlagHandler(toBoolean(event?.target.value)); }}
                                value={false}
                            />
                            許可しない
                            <Radio
                                checked={props.exam.afterReadFlag === true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangeAfterReadFlagHandler(toBoolean(event?.target.value)); }}
                                value={true}
                            />
                            許可する
                        </Box>
                        <Box fontWeight="fontWeightBold" color="#003f71">合格点</Box>
                        <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                            {
                              props.exam.questions.filter(q => q.deleteFlag !== true && !q.sectionFlag).reduce((sum, current) => sum + current.points, 0)
                            }
                            点中
                            <TextField style={{ width: "6rem" }} value={props.exam.passPoints} type={"tel"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangePassPointsHandler(parseInt(event.target.value)); }} />
                            点
                        </Box>
                    </div>
                </Box>
            </Card>
            {bRelesePlace ? <Card style={{ marginTop: "16px" }}>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        試験会場コード設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <PlacesTable excutionPlaces={props.executionPlace} onChangePlaceTextHandler={props.onChangePlaceTextHandler} onClickDeletePlaceHandler={props.onClickDeletePlaceHandler} onClickAddNewPlaceHandler={props.onClickAddNewPlaceHandler} />
                </Box>
            </Card> : undefined}
            <Card style={{ marginTop: "16px" }}>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        配点設定
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <QuestationTable question={props.question} onChangeQuestionPointsHandler={props.onChangeQuestionPointsHandler} setQuestionHeadnum={props.setQuestionHeadnum} />
                </Box>
            </Card>
        </div>
    );
}
