import React from 'react';

import { createUseStyles } from 'react-jss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from '@material-ui/core/Box';

import {ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import { Layout } from '../Layout';
import * as common from "../../common";
import { Exam ,APIResponse ,Learner ,AnswerCSV ,resultAnswerDetailData ,ExamQuestion ,Question} from '../../react-app-env';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { initialLearnerStateValue } from '../Learner/Index';
import { initialAnswerStateValue } from '../Csv/ExportAnswer';
import { initialQuestion } from '../EditQuestion/EditQuestion';
import { ResultDetailTable } from '../Table/ResultDetailTable';
import { ResultDetailSummaryTable } from '../Table/ResultDetailSummaryTable';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    resultIndex: {
        fontSize: common.FONT_SIZE.mainText,
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttonStyle: {
        marginTop:"3.2rem",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .MuiSvgIcon-root":{
            fontSize:"1.8rem"
        }
    },
    filterContainer: {
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        "& #learner":{
            width:"20rem"
        }
    }
});




export const initialExamStateValue = {
    id:0,
    name:"" ,
    status:0,
    termType:0,
    shuffleFlag:false,
    numberingType:0,
    acceptStart:"",
    acceptEnd:"",
    executeStart:"" ,
    executeEnd:"",
    executeTime:1,
    learnerMax:1,
    guidanceUrl:"",
    startViewFlag:false,
    startBodyHtml:"",
    endViewFlag:false ,
    endBodyHtml:"",
    allPoints:0,
    passPoints:0,
    deleteFlag:false,
    modified:new Date(),
    choiceDeviceFlag:false,
    afterReadFlag:false,
    acceptExpire:0,
    advanceExplanationVideoUrl:""
};




export function TestResultsLearner() {

    // -----API-----
    // 試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    //試験データを取得する
    function getAnswerData(args?: any):Promise<APIResponse<AnswerCSV[]>> {
        return api("/api/o-answerCSV", "GET", args)
    }

    function getQuestion(args?: any) {
        return api("/api/o-question","GET",args)
    }
    



    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();
    


    // -----Handler-----
    
    



    // -----汎用関数宣言-----

   


    //引数の受験者の総得点を返す
    const getTotalPoints = (learnerNum:string)=>{
        let count = 0;
        for (const answer of answerStateValue) {
            if( answer.learnerNumber === learnerNum ){
                count += answer.points 
            }
        }
        return count;
    }

    //引数の受験者が合格かどうかを返す
    const isPassedLearner = (learnerNum:string)=>{
        return ( getTotalPoints(learnerNum) >= examStateData.passPoints );
    }






    //引数の受験者番号の解答情報をすべて返す
    const getResultDetailData = (learnerNum:string)=>{
        const answerCSV = filterAnswerCSVById(learnerNum);
        const shapedAnswerData = shapeDetailData( questionStateValue ,answerCSV );
        return shapedAnswerData;
    }


    //解答情報を整形する
    const shapeDetailData = (questionArray:Question[] ,answerCSV:AnswerCSV[])=>{
        const questionsArray = removeNoStartViewSection(questionArray);
        let shapedData:resultAnswerDetailData[] = [];

        for (const question of questionsArray) {

            const detailData:resultAnswerDetailData = {
                sectionFlag:question.sectionFlag,
                questionNum:getQuestionHeadnum(question,questionArray),
                isCorrect:isCorrectAnswer( question.id ,answerCSV),
                allocationPoints:question.points,
                questionText:question.bodyHtml
            }

            shapedData.push(detailData);
        }
        return shapedData;
    }


    //引数の受験者のanswerCSVデータを配列で返す
    const filterAnswerCSVById = (learnerNum:string)=>{
        return answerStateValue.filter( answer => answer.learnerNumber === learnerNum )
    }


    //引数の問題IDの解答が正解かどうかを返す
    const isCorrectAnswer = (questionId:number  ,answerCSV:AnswerCSV[])=>{
        let isCorrect = false;
        for (const answer of answerCSV) {
            if( answer.questionId === questionId){
                if(answer.pass){
                    isCorrect = true;
                }else{
                    isCorrect = false;
                    break;
                }
            }
        }
        return isCorrect;
    }
    


    function orderByAsc(a:{editOrder:number},b:{editOrder:number}){
        if( a.editOrder < b.editOrder ) return -1;
        if( a.editOrder > b.editOrder ) return 1;
        return 0;
    };


    //開始画面を持たないセクションを取り除く
    const removeNoStartViewSection=(Questions:Question[])=>{
        if(!Questions){
            return [];
        }

        // ディープコピー     
        let newQuestions = Questions.map(x => Object.assign({}, x));
        newQuestions.sort(orderByAsc);
        newQuestions = newQuestions.filter(q=>q.deleteFlag === false && (q.sectionFlag === false || q.startViewFlag));


        let editOrderArr = Array.from(Array(newQuestions.length), (v, k) => k).map(n=>n+1);
        newQuestions.forEach((q,idx)=>{
            q.editOrder =editOrderArr[idx];
        });

        return newQuestions

    }
    


    //問題番号を返す
    const getQuestionHeadnum =(questionArg:Question,questions:Question[])=>{
        let sectionNum = 0;
        let commonNum = 0;
        let headNum = "";
        let sectionHeadNum = "";

        for (const question of questions) {
        if(question.sectionFlag){ 

            sectionNum =sectionNum + 1;
            if(questionArg.id === question.id){
                return sectionHeadNum = sectionNum.toString();
            } 
        }
        else if(question.parentQuestionId === 0) {
            commonNum = commonNum + 1;

            if(questionArg.id === question.id){
                return headNum = "問" + commonNum;
            }
            
        }
        else{
            commonNum = commonNum + 1;

            if(questionArg.id === question.id){
                return headNum = "問" + commonNum;
            }
        }
            
        }

        return headNum;

    }
   



    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    
    // -----state-----

    const [isLoaded,setIsLoaded] = React.useState(false);
    const [isExsistAnswerData,setIsExsistAnswerData] = React.useState(false);
    const [examStateData, setExamStateData] = React.useState<Exam>(initialExamStateValue);
    const [learnerStateValue, setLearnerStateValue]= React.useState<Learner>(initialLearnerStateValue);
    const [answerStateValue,setAnswerStateValue]=React.useState<AnswerCSV[]>([initialAnswerStateValue])
    const [questionStateValue,setQuestionStateValue]=React.useState<Question[]>([initialQuestion])

    






    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {

        //試験データの取得
        getExamData({id:params.examId})
            .then((data:APIResponse<Exam[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){
                    setExamStateData(data.value[0])
                }

                //受験者データの取得
                getLearnerData({executionId:params.examId ,userName:params.userName})
                    .then((data:APIResponse<Learner[]>) => {

                        if (data.errorCode !==20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        if(data.value !== null){
                            setLearnerStateValue( data.value[0] );
                        }

                        //解答データの取得
                        getAnswerData({executionId:params.examId})
                            .then((data:APIResponse<AnswerCSV[]>) => {
                                //解答データが無い時
                                if (data.errorCode === 40403) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    setIsLoaded(true);
                                    return;
                                }
                                else if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                
                                if(data.value !== null){
                                    setIsExsistAnswerData(true);
                                    setAnswerStateValue(data.value);
                                } 

                                getQuestion({executionId:parseInt(params.examId)})
                                    .then((data:APIResponse<ExamQuestion>) => {
                                        if (data.errorCode !== 20000) {
                                            common.alertError(data.errorTitle , data.errorDetail);
                                            return;
                                        }

                                        setQuestionStateValue(data.value.questions)
                                        setIsLoaded(true);

                                    }).catch((err:any) => {
                                        alert(common.ResponseMessages.Error_GetQuestion);
                                    });

                                

                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_GetExam);
                            });


                    }).catch((err) => {
                        alert(common.ResponseMessages.Error_GetLearner);
                });


            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });

    }, []);
 
    

    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            {isLoaded ?

                <div className={classNames.resultIndex}>
                    
                    <div className={classNames.upperContents}>

                        <div>
                            <h4>{examStateData.name}&nbsp;試験結果詳細</h4>
                        </div>

                    </div>

                    <Box marginTop={3}>
                        <ResultDetailSummaryTable totalPoints={getTotalPoints(learnerStateValue.learnerNumber)} isPassed={isPassedLearner(learnerStateValue.learnerNumber)} />
                    </Box>
                    <Box marginTop={6}>
                        <ResultDetailTable resultData={getResultDetailData(learnerStateValue.learnerNumber)} />
                    </Box> 

                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" onClick={() => { go("/test-results/index/" + examStateData.id ) }}><ArrowBackIosIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;戻る</span></DefaultBackButton>
                    </ButtonContainer>

                </div>

            : <div>Loading...</div>

            }

        </Layout>
    );
}
