import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import { Localization } from "material-table";
import {toPlainText} from '../RichEditor/RichEditor'

import { resultAnswerDetailData } from '../../react-app-env';
import * as common from "../../common";
import { Question } from '../EditQuestion/Question';
import { TableRow } from 'material-ui';
import { TableCell } from '@material-ui/core';
import Table from 'material-ui/Table';



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({

    adminUserTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        "& .MuiTableCell-root":{
            fontSize:common.FONT_SIZE.tableBody
        },
        "& .MuiTableCell-head":{
            fontSize:common.FONT_SIZE.tableHeader
        }
    }
}));


//material-tableの日本語化変数
export const localizationData: Localization = {
    error: "エラー",
    body: {
      emptyDataSourceMessage: "表示するレコードがありません。",
      filterRow: {
        filterTooltip: "フィルター",
      },
      editRow: {
        saveTooltip: "保存",
        cancelTooltip: "キャンセル",
        deleteText: "この行を削除しますか？",
      },
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
    },
    header: {
      actions: "",
    },
    grouping: {
      groupedBy: "グループ化:",
      placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
      firstTooltip: "最初のページ",
      firstAriaLabel: "最初のページ",
      previousTooltip: "前のページ",
      previousAriaLabel: "前のページ",
      nextTooltip: "次のページ",
      nextAriaLabel: "次のページ",
      labelDisplayedRows: "{from}-{to} 全{count}件",
      labelRowsPerPage: "ページあたりの行数:",
      lastTooltip: "最後のページ",
      lastAriaLabel: "最後のページ",
      labelRowsSelect: "行",
    },
    toolbar: {
      addRemoveColumns: "列の追加、削除",
      nRowsSelected: "{0} 行選択",
      showColumnsTitle: "列の表示",
      showColumnsAriaLabel: "列の表示",
      exportTitle: "出力",
      exportAriaLabel: "出力",
      searchTooltip: "検索",
      searchPlaceholder: "氏名検索",
    }
  };



interface Props {
    resultData:resultAnswerDetailData[];
}



export function ResultDetailTable(props:Props) {

    // ---定数------
    



    // -----共通関数の宣言-----
    const {
        go // 画面遷移 
    } = common.useCommon();
    



    // -----Handler-----


   


    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    // -----state-----


    
    return (
        <div className={classNames.adminUserTableRoot}>
 
            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                    { title: '問番号', field: 'questionNum' ,sorting: false ,searchable:false ,align:"left" 
                        ,headerStyle: {
                          minWidth: 140 ,
                          paddingLeft:"3rem"
                        },cellStyle: {
                          paddingLeft:"3rem"
                        },
                        render: rowdata => <span style={rowdata.questionNum.includes("問") ? {} : {border: "solid 1px", padding: "0px 7px"}}>{rowdata.questionNum}</span>
                    }, 
                    { title: '結果', field: 'isCorrect' ,sorting: false ,searchable:false ,align:"center" ,headerStyle: {
                        minWidth: 100,
                      }},
                    { title: '得点/配点', field: 'allocationPoints' , sorting: false ,searchable:false ,align:"center",headerStyle: {
                        minWidth: 120,
                      }},
                    { title: '問題件名', field: 'questionText' , sorting: false ,searchable:false ,align:"center",
                    headerStyle: {
                      minWidth: 800,
                    },}
                ]}
                data={
                    props.resultData.map( (data) =>  (
                        data.sectionFlag ?
                        {
                            questionNum: data.questionNum ,
                            questionText:toPlainText(data.questionText)
                        }
                        :
                        {
                            questionNum: data.questionNum,
                            isCorrect: data.isCorrect ? "正解" : "不正解" ,
                            allocationPoints:(data.isCorrect ? data.allocationPoints : "0") + " / " + data.allocationPoints + " 点",
                            questionText: toPlainText(data.questionText)
                        }
                    ))
                }
               
                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    draggable: false,
                    search:false,
                    toolbar:false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    }
                }}

                style = {{
                  width:"100%"
                }}

                localization={localizationData}
               
            />


        </div>
    );
}
