import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import * as common from "../../common";
import { DefaultButton } from "../StylesUI/CommonLayouts";
import { TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    placeTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& .MuiInputBase-input ,.MuiTableCell-head": {
            fontSize: common.FONT_SIZE.mainText,
        },
        "& #placeField": {
            lineHeight: "2rem",
            fontSize: common.FONT_SIZE.mainText,
            width: "120%"
        },
        "& input ,textarea ,.MuiFormHelperText-root ,.MuiInputBase-root": {
            fontSize: common.FONT_SIZE.mainText,
        },
    }
}));

interface Props {
    excutionPlaces: [{ id: number, placeCode: any, placeName: any }],
    onClickDeletePlaceHandler: any,
    onClickAddNewPlaceHandler: any,
    onChangePlaceTextHandler: any
}

export function PlacesTable(props: Props) {
    const classNames = useStyles();

    return (
        <div>
            <TableContainer component={Paper} className={classNames.placeTableRoot}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" style={{ width: 250 }} align="left">
                                <div style={{ color: "#003f71" }}>試験会場コード</div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: 360 }} align="left">
                                <div style={{ color: "#003f71" }}>試験会場名称</div>
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                                <div style={{ color: "#003f71" }}>
                                    <DefaultButton onClick={(event) => { props.onClickAddNewPlaceHandler() }}>コードを追加</DefaultButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.excutionPlaces?.map((excutionPlaces: { id: number, placeCode: any, placeName: any }) => (
                            <TableRow key={excutionPlaces.id}>
                                <TableCell component="th" scope="row" align="left">
                                    <TextField id="placeField" style={{ width: "20rem" }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangePlaceTextHandler(excutionPlaces.id, event.target.value, excutionPlaces.placeName) }} value={excutionPlaces.placeCode}
                                        variant="standard" required error={excutionPlaces.placeCode == "" && excutionPlaces.placeName != ""} helperText={excutionPlaces.placeCode == "" && excutionPlaces.placeName != "" ? "試験会場コードを入力してください" : "" }></TextField>
                                </TableCell>
                                <TableCell component="th" scope="row" align="left">
                                    <TextField id="placeName" style={{ width: "30rem" }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { props.onChangePlaceTextHandler(excutionPlaces.id, excutionPlaces.placeCode, event.target.value) }} value={excutionPlaces.placeName}></TextField>
                                </TableCell>
                                <TableCell align="left"><Button onClick={(event) => { props.onClickDeletePlaceHandler(excutionPlaces.id) }} ><DeleteOutlineOutlinedIcon /></Button> </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}