import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Radio from '@material-ui/core/Radio';
import {RichEditor} from '../RichEditor/RichEditor'
import { Exam, ExamQuestion } from '../../react-app-env';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import * as common from "../../common";

const useStyles = makeStyles((theme) => ({
    editQeustionDivideScreenRoot: {
        "& svg":{
            fontSize:"2rem"
        },
        fontSize: common.FONT_SIZE.mainText,
    },
    toolbar:{
        color:"white",
        background:"gray",
        minHeight:"36px"
    },
    titleText : {
        margin:"auto",
        fontSize:common.FONT_SIZE.mainText,
        fontWeight:"bold"
    },
    settingRoot: {
        padding: "20px"
    },
    choiceType: {
        "& .MuiRadio-root": {
            paddingLeft: "28px"
        },
        "& .MuiRadio-root:first-child": {
            paddingLeft: "8px"
        }
    }
}));

export function DevideScreen(props:{editorKey:string,onChangeRadioViewFlagHandler:any,onChangeViewBodyHtmlHandler:any, screenName:string,curViewFlag:boolean,curBodyHtml:string}) {    
    const classNames = useStyles();


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const viewFlag = event.target.value === "true" ? true : false;
        
        props.onChangeRadioViewFlagHandler(viewFlag);
    };
    
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.editQeustionDivideScreenRoot}>
            <Card>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        {props.screenName + "画面設定"}
                    </h6>
                </Toolbar>
                <Box p={1} className={classNames.settingRoot}>
                    <Box fontWeight="fontWeightBold">{props.screenName +  "画面"}</Box>
                    <Box paddingLeft={1} marginBottom={2} display="flex" justifyContent="flex-start" alignItems="center" className={classNames.choiceType}>
                        <Radio
                            onChange={handleChange}
                            value={true}
                            checked={ props.curViewFlag=== true}
                        />
                        使用する
                        <Radio
                            onChange={handleChange}
                            value={false}
                            checked={ props.curViewFlag=== false}
                        />
                        使用しない
                    </Box>
                    <Box fontWeight="fontWeightBold">説明文</Box>
                    <div>
                        <RichEditor key={props.editorKey} defaultValue={props.curBodyHtml} onChange={(text)=>{props.onChangeViewBodyHtmlHandler(text)}}/>
                    </div>
                </Box>
            </Card>
        </div>
    );
}
