import React from 'react';

import { Choice, Exam as IExam,ExamQuestion,Question as IQuestion } from '../../../react-app-env';
import {RichEditor} from '../../RichEditor/RichEditor'
import Button from '@material-ui/core/Button';
import * as common from "../../../common";
import { Box } from '@material-ui/core';
import {DefaultButton ,ButtonContainer} from "../../StylesUI/CommonLayouts";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    rowContainer: {
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        "& button":{
            fontSize:common.FONT_SIZE.button
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& h3":{
            fontSize:common.FONT_SIZE.title,
            fontWeight:"bold",
            color: "#00468b"
        }
    },
    buttonStyle: {
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    }
  }));

export function PreviewStartScreen(props:{examQuestion : ExamQuestion}){

    const {
        history,
        params,
        getUser, // ログインユーザー取得
        go, // 画面遷移 
        logout, // 強制ログアウト
        api  // API呼び出し
    } = common.useCommon();

    const classNames = useStyles();
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.rowContainer}>
            <Box marginBottom={4}>
                <h3>{props.examQuestion.name}</h3>
            </Box>
            <Box marginBottom={4}>
                <RichEditor  defaultValue={props.examQuestion.startBodyHtml}  readOnly={true}/>
            </Box>
            <div>
               <ButtonContainer className={classNames.buttonStyle}> <DefaultButton onClick={()=>{go("/edit-question/" + props.examQuestion.executionId + "/preview/1")}} >開始する</DefaultButton></ButtonContainer>
            </div>
        </div>
    );
}
