import React from 'react';

import { createUseStyles } from 'react-jss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {ButtonContainer,DefaultBackButton, DefaultButton}  from '../StylesUI/CommonLayouts';
import { Layout } from '../Layout';
import * as common from "../../common";
import { Exam, APIResponse, Learner, AnswerCSV, APIArgsQuestionGet, ExamQuestion } from '../../react-app-env';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { initialLearnerStateValue } from '../Learner/Index';
import { initialAnswerStateValue } from '../Csv/ExportAnswer';
import { ResultSummaryTable } from '../Table/ResultSummaryTable';
import { LearnerResultTable } from '../Table/LearnerResultTable';
import { initialQuestion } from '../EditQuestion/EditQuestion';
import { PercentageOfCorrectChart } from './AnalysisChart/percentageOfCorrectChart';
import { DiscriminantIndex } from './AnalysisChart/DiscriminantIndex';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    resultIndex: {
        fontSize: common.FONT_SIZE.mainText,
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttonStyle: {
        marginTop:"1.5rem",
        marginBottom:"3rem",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .rightBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .leftBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .MuiSvgIcon-root":{
            fontSize:"1.8rem"
        },
        "& .leftBtn":{
            marginRight:"1.5rem"
        }

    },
    filterContainer: {
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        "& #learner":{
            width:"20rem"
        }
    }
});




const initialExamQuestion: ExamQuestion = {
    executionId: 999999,
    name: "初期値",
    startViewFlag: false,
    startBodyHtml: "初期値",
    shuffleFlag: false,
    choiceDeviceFlag: false,
    afterReadFlag: false,
    passPoints: 999999,
    endViewFlag: false,
    endBodyHtml: "初期値",
    status: 999999,
    modified: "初期値",
    force: false,
    allPoints: 999999,
    questions: [initialQuestion]
}



export function ResultsAnalysisIndex() {

    // -----API-----
    // 試験データを取得する
    function getQuestion(args?: APIArgsQuestionGet) {
        return api("/api/o-question", "GET", args)
    }

    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    //試験データを取得する
    function getAnswerData(args?: any):Promise<APIResponse<AnswerCSV[]>> {
        return api("/api/o-answerCSV", "GET", args)
    }



    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();
    


    // -----Handler-----
    
    



    // -----汎用関数宣言-----




    //引数の受験者の正答数を返す
    const getCorrectNum = (learner:Learner ,answer:AnswerCSV[]) => {
        let count = 0;
        let targetAnswers = answer.filter(function(a){return  learner.userName == String(a.learnerID)}); //対象受験者の解答情報を抽出
        let preQuestionId = 0
        let isCorrect = true
        let key = 0

        //解答情報を問題ごとに並ぶようにソート
        targetAnswers.sort(function(a:AnswerCSV,b:AnswerCSV){
            if (a.questionId > b.questionId ) return -1;
            if (b.questionId > a.questionId ) return 1;
          
            return 0;
        }) 

        const answerCount = targetAnswers.length; //解答情報の数

        //一件ずつ判定し、問題ごとに正答かどうかを判定
        for (const answer of targetAnswers) {
            key++;

            //1回目は除く
            if(answer.questionId !== preQuestionId && key !== 1){
                if(isCorrect) count++;
                isCorrect = true
            }

            if( !answer.pass ){
                isCorrect = false;
            }

            preQuestionId = answer.questionId
            //最後の解答情報の時、正答判定をする
            if(key === answerCount) if(isCorrect) count++;
        }
        
        return count;
    
    }


    //問題数を返す
    const getQuestionNum= (exam:ExamQuestion)=>{
        let count = 0;
        const questions = exam.questions;

        for (const q of questions) {
            if(!q.sectionFlag) count ++;
        }

        return count;
    }

    //実際に試験を受けた人数を返す
    const getNumberOfTestedLearner = (learners:Learner[])=>{
        let count = 0;
        for (const learner of learners) {
            if(learner.executionStartDatetime !== null) count++;
        }
        return count;
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    
    // -----state-----

    const [isLoaded,setIsLoaded] = React.useState(false);
    const [isExsistAnswerData,setIsExsistAnswerData] = React.useState(false);
    const [examStateData, setExamStateData] = React.useState<ExamQuestion>(initialExamQuestion);
    const [learnerStateValue, setLearnerStateValue]= React.useState<Learner[]>([initialLearnerStateValue]);
    const [answerStateValue,setAnswerStateValue]=React.useState<AnswerCSV[]>([initialAnswerStateValue]);

    const [questionNum,setQuestionNum] = React.useState(0);
    const [viewContents,setViewContents] = React.useState("CorrectChart");

    


    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {

        //試験データの取得
        const argsGetQuestion: APIArgsQuestionGet = { executionId: parseInt(params.examId) }
        getQuestion(argsGetQuestion)
            .then((exam: APIResponse<ExamQuestion>) => {
                if (!exam) {
                    alert(common.ResponseMessages.Error_GetQuestion);
                    return;
                }

                if (exam.errorCode !== 20000) {
                    common.alertError(exam.errorTitle, exam.errorDetail);
                    return;
                }

                if(exam.value !== null){
                    setExamStateData(exam.value)
                    setQuestionNum(getQuestionNum(exam.value))
                }

                //受験者データの取得
                getLearnerData({executionId:params.examId})
                    .then((data:APIResponse<Learner[]>) => {

                        if (data.errorCode !==20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        if(data.value !== null){
                            setLearnerStateValue( data.value );
                        }

                        //解答データの取得
                        getAnswerData({executionId:params.examId})
                            .then((data:APIResponse<AnswerCSV[]>) => {
                                //解答データが無い時
                                if (data.errorCode === 40403) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    setIsLoaded(true);
                                    return;
                                }
                                else if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                
                                if(data.value !== null){
                                    setIsExsistAnswerData(true);
                                    setAnswerStateValue(data.value);
                                } 

                                setIsLoaded(true);

                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_GetExam);
                            });


                    }).catch((err) => {
                        alert(common.ResponseMessages.Error_GetLearner);
                });


            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });

    }, []);
 
    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            {isLoaded ?

                <div className={classNames.resultIndex}>
                    
                    <div className={classNames.upperContents}>

                        <div>
                            <h4>{examStateData.name}&nbsp;成績集計分析</h4>
                        </div>


                    </div>

                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultButton style={{backgroundColor:viewContents == "CorrectChart" ? "#ffa500" :"#4378b6"}} className="leftBtn" onClick={() => { setViewContents("CorrectChart") }}><span style={{paddingBottom:"0.2rem"}}>&nbsp;正答率</span></DefaultButton>
                        <DefaultButton style={{backgroundColor:viewContents == "DiscriminantIndex" ? "#ffa500" :"#4378b6"}} className="rightBtn" onClick={() => { setViewContents("DiscriminantIndex") }}><span style={{paddingBottom:"0.2rem"}}>&nbsp;識別指数表</span></DefaultButton>
                    </ButtonContainer>

                    {
                        isExsistAnswerData 
                        ?
                            <div>
                                {viewContents == "CorrectChart" && <PercentageOfCorrectChart questionNum={questionNum} answerStateValue={answerStateValue} learnerStateValue={learnerStateValue} getCorrectNum={getCorrectNum}/>}
                                {viewContents == "DiscriminantIndex" && <DiscriminantIndex questionNum={questionNum} learnerNum={getNumberOfTestedLearner(learnerStateValue)} examStateData={examStateData} answerStateValue={answerStateValue} learnerStateValue={learnerStateValue}/>}
                            </div>
                        :
                            <div></div>
                    }



                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" onClick={() => { go("/exam/index") }}><ArrowBackIosIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;戻る</span></DefaultBackButton>
                    </ButtonContainer>

                </div>

            : <div>Loading...</div>

            }

        </Layout>
    );
}
