import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Chart from 'react-google-charts'

import { Exam, APIResponse, Learner, AnswerCSV, APIArgsQuestionGet, ExamQuestion, Question } from '../../../react-app-env';

import * as common from "../../../common";

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { forwardRef } from 'react';

import { localizationData } from '../../Table/ResultDetailTable';
import { toPlainText } from '../../RichEditor/RichEditor';



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({

    styleRoot: {
        fontSize: common.FONT_SIZE.mainText,
        "& .MuiTableCell-root":{
            fontSize:common.FONT_SIZE.tableBody
        },
        "& .MuiTableCell-head":{
            fontSize:common.FONT_SIZE.tableHeader
        }

    }
}));




interface Props {
    questionNum:number;
    learnerNum:number;
    examStateData:ExamQuestion;
    answerStateValue:AnswerCSV[];
    learnerStateValue:Learner[];
}



export function DiscriminantIndex(props:Props) {

    // ---定数------



    // -----共通関数の宣言-----
    const {
        go // 画面遷移 
    } = common.useCommon();
    



    // -----Handler-----


   


    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    // -----関数-----
    const generateChartData = (propsData:Props) => {


    }

    //問題番号を返す
    const getQuestionHeadnum =(questionArg:Question ,questions:Question[])=>{
        let sectionNum = 0;
        let commonNum = 0;
        let headNum = "";
        let sectionHeadNum = "";

        for (const question of questions) {
        if(question.sectionFlag){ 
            sectionNum =sectionNum + 1;

            if(questionArg.id === question.id){
                return sectionHeadNum = sectionNum.toString();
            } 
        }
        else if(question.parentQuestionId === 0) {
            commonNum = commonNum + 1;

            if(questionArg.id === question.id){
                return headNum = "問" + commonNum;
            }
            
        }
        else{
            commonNum = commonNum + 1;

            if(questionArg.id === question.id){
                return headNum = "問" + commonNum;
            }
        }
            
        }

        return headNum;

    }

    //対象の問題に対する正答者数を返す
    const getCorrectNum = (question:Question ,answerValue:AnswerCSV[]) => {
        let count = 0;
        let targetAnswer = answerValue.filter(function(a){return  question.id === a.questionId});

        //複数選択肢の時
        if(question.answerType === 1){
            let preLearnerId = 0
            let isCorrect = true
            let key = 0

            //解答情報を受験者毎に並ぶようにソート
            targetAnswer.sort(function(a:AnswerCSV,b:AnswerCSV){
                if (a.learnerID > b.learnerID ) return -1;
                if (b.learnerID > a.learnerID ) return 1;
              
                return 0;
            }) 
            
            const answerCount = targetAnswer.length; //解答情報の数

            //一件ずつ判定し、受験者ごとに正答かどうかを判定
            for (const answer of targetAnswer) {
                key++;

                //一回目は除く
                if(answer.learnerID !== preLearnerId && key !== 1){
                    if(isCorrect) count++;
                    isCorrect = true
                }

                if( !answer.pass ){
                    isCorrect = false;
                }
                preLearnerId = answer.learnerID

                //最後の解答情報の時、正答判定をする
                if(key === answerCount) if(isCorrect) count++;
            }
            return count;

        }else{ //択一選択肢の時

            for (const answer of targetAnswer) {
                if( answer.pass ){
                    count ++;
                }
            }
            return count;

        }
        
    }

    //対象の問題に対する誤答者数を返す(無回答者は除く)
    const getIncorrectNum = (question:Question ,answerValue:AnswerCSV[]) => {
        let count = 0;
        let targetAnswer = answerValue.filter(function(a){return  question.id === a.questionId});

        //複数選択肢の時
        if(question.answerType === 1){
            let preLearnerId = 0
            let isCorrect = true
            let isNoAnswer = true
            let key = 0

            //解答情報を受験者毎に並ぶようにソート
            targetAnswer.sort(function(a:AnswerCSV,b:AnswerCSV){
                if (a.learnerID > b.learnerID ) return -1;
                if (b.learnerID > a.learnerID ) return 1;
              
                return 0;
            }) 
            
            const answerCount = targetAnswer.length; //解答情報の数

            //一件ずつ判定し、受験者ごとに誤答かどうかを判定
            for (const answer of targetAnswer) {
                key++;

                //一回目は除く
                if(answer.learnerID !== preLearnerId && key !== 1){
                    if(!isCorrect && !isNoAnswer) count++;
                    isCorrect = true
                    isNoAnswer = true
                }

                if( !answer.pass ){
                    isCorrect = false;
                }
                if( answer.selectedNum !== 0 ){
                    isNoAnswer = false;
                }

                preLearnerId = answer.learnerID

                //最後の解答情報の時、誤答判定をする
                if(key === answerCount){
                    if(!isCorrect && !isNoAnswer) count++;
                } 
            }
            return count;

        }else{ //択一選択肢の時

            for (const answer of targetAnswer) {
                if( !answer.pass && answer.selectedNum !== 0 ){ //selectedNumが0の場合は無回答なので除く
                    count ++;
                }
            }
            return count;

        }

    }


    //対象の問題に対する無回答者数を返す
    const getNoAnsweNum = (question:Question ,answerValue:AnswerCSV[]) => {
        const noAnswerNum = props.learnerNum - getCorrectNum(question ,answerValue) - getIncorrectNum(question ,answerValue)
        return noAnswerNum
    }

    //引数の数字に（）を付ける
    const addParentheses = (text:string) => {
        return "(" + text + ")"
    }


    //引数の受験者の総得点を返す
    const getTotalPoints = (learnerNum:string ,answerValue:AnswerCSV[])=>{
        let count = 0;
        for (const answer of answerValue) {
            if( answer.learnerNumber === learnerNum ){
                count += answer.points 
            }
        }
        return count;
    }


    //成績が上位25%のユーザーを抽出
    const extractHighScoreUser = (users:Learner[] ,answerValue:AnswerCSV[]) =>{
        const highScoreUserNum = Math.round(props.learnerNum * 0.25)

        //成績上位順に並ぶようにソート
        const targetUsers = users.sort(function(a:Learner,b:Learner){
            if (getTotalPoints(a.learnerNumber ,answerValue) > getTotalPoints(b.learnerNumber ,answerValue) ) return -1;
            if (getTotalPoints(b.learnerNumber ,answerValue) > getTotalPoints(a.learnerNumber ,answerValue) ) return 1;
          
            return 0;
        }) 

        return targetUsers.slice(0 ,highScoreUserNum)

    }

    //成績が下位25%のユーザーを抽出
    const extractLowScoreUser = (users:Learner[] ,answerValue:AnswerCSV[]) =>{
        const lowScoreUserNum = Math.round(props.learnerNum * 0.25)

        //成績下位順に並ぶようにソート
        const targetUsers = users.sort(function(a:Learner,b:Learner){
            if (getTotalPoints(a.learnerNumber ,answerValue) > getTotalPoints(b.learnerNumber ,answerValue) ) return 1;
            if (getTotalPoints(b.learnerNumber ,answerValue) > getTotalPoints(a.learnerNumber ,answerValue) ) return -1;
          
            return 0;
        }) 

        return targetUsers.slice(0 ,lowScoreUserNum)
    }

    //引数の問題かつユーザーの解答情報を抽出する関数
    const extractAnswerByUser = (question:Question ,users:Learner[] ,answerValue:AnswerCSV[]) =>{
        //対象の問題の解答情報を抽出
        const targetAnswers = answerValue.filter(function(a){return  question.id === a.questionId});

        //さらに対象のユーザーの解答情報を抽出
        const answerArray = targetAnswers.filter(function(answer:AnswerCSV){
            let isExsist = false;
            for (const user of users) {
                if(user.userName === String(answer.learnerID)) isExsist = true
            }
            return isExsist
        })

        return answerArray
    }


    //識別指数を算出する
    const calculateDiscriminantIndex = (question:Question ,users:Learner[] ,answerValue:AnswerCSV[]) =>{
        //成績上位25%、下位25%のユーザーを抽出
        const highScoreUsers = extractHighScoreUser(users ,answerValue)
        const lowScoreUsers = extractLowScoreUser(users ,answerValue)

        //成績上位25%、下位25%のユーザーの解答情報を抽出
        const highScoreAnswes = extractAnswerByUser(question ,highScoreUsers ,answerValue)
        const lowScoreAnswes = extractAnswerByUser(question ,lowScoreUsers ,answerValue)

        //成績上位25%のユーザーでの正答者数、誤答者数を算出
        const A = getCorrectNum(question ,highScoreAnswes)     //上位25%のユーザーでの正答者数
        const B = getIncorrectNum(question ,highScoreAnswes) //上位25%のユーザーでの誤答者数
        //成績下位25%のユーザーでの正答者数、誤答者数を算出
        const C = getCorrectNum(question ,lowScoreAnswes)       //下位25%のユーザーでの正答者数
        const D = getIncorrectNum(question ,lowScoreAnswes)   //下位25%のユーザーでの誤答者数

        console.log("問：" + question.viewOrder + "識別子数関係" + A + " " + B + " " + C + " " + D)

        //識別指数を算出
        const top = (A * D) - (B * C) 
        const bottom = Math.sqrt((A + B) * (C + D) * (A + C) * (B + D))
        const discriminantIndex = roundDecimal(top / bottom ,2)
        if(isNaN(discriminantIndex)) return "計算不可"
        const retVal = discriminantIndex > 0 ? "+" + String(discriminantIndex) : String(discriminantIndex)
        return retVal
    }


    // 四捨五入
    function roundDecimal(value:number, n:number) {
        return Math.round(value * Math.pow(10, n) ) / Math.pow(10, n);
    }


    
    return (
        <div className={classNames.styleRoot}>

            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                    { title: '問番号', field: 'questionNum' ,sorting: false ,searchable:false ,align:"left" 
                        ,headerStyle: {
                          minWidth: 60,
                          paddingLeft:"3rem"
                        },cellStyle:{
                          paddingLeft:"3rem"
                        }
                    }, 
                    { title: '正答者数', field: 'correctNum' ,sorting: false ,searchable:false ,align:"center" ,headerStyle: {
                        minWidth: 120,
                      }},
                    { title: '(率)', field: 'percentOfCorrect' , sorting: false ,searchable:false ,align:"center",headerStyle: {
                        minWidth: 100,
                      }},
                    { title: '誤答者数', field: 'incorrectNum' ,sorting: false ,searchable:false ,align:"center" ,headerStyle: {
                        minWidth: 120,
                      }},
                    { title: '(率)', field: 'percentOfincorrect' , sorting: false ,searchable:false ,align:"center",headerStyle: {
                        minWidth: 100,
                      }},
                    { title: '無回答者数', field: 'noAnsweNum' ,sorting: false ,searchable:false ,align:"center" ,headerStyle: {
                        minWidth: 120,
                      }},
                    { title: '(率)', field: 'percentOfnoAnswer' , sorting: false ,searchable:false ,align:"center",headerStyle: {
                        minWidth: 100,
                      }},
                    { title: '識別指数', field: 'discriminantIndex' , sorting: false ,searchable:false ,align:"center",headerStyle: {
                          minWidth: 100,
                    }},
                ]}
                data={
                    props.examStateData.questions.map( (data) =>  (
                        data.sectionFlag ?

                        {
                            questionNum: getQuestionHeadnum(data ,props.examStateData.questions)
                        }

                        :

                        {
                            questionNum: getQuestionHeadnum(data ,props.examStateData.questions),
                            correctNum: getCorrectNum(data ,props.answerStateValue),
                            percentOfCorrect: addParentheses(String(getCorrectNum(data ,props.answerStateValue) / props.learnerNum * 100)) ,
                            incorrectNum: getIncorrectNum(data ,props.answerStateValue),
                            percentOfincorrect: addParentheses(String(getIncorrectNum(data ,props.answerStateValue) / props.learnerNum * 100)) ,
                            noAnsweNum: getNoAnsweNum(data ,props.answerStateValue),
                            percentOfnoAnswer: addParentheses(String(getNoAnsweNum(data ,props.answerStateValue) / props.learnerNum * 100))   ,
                            discriminantIndex: calculateDiscriminantIndex(data ,props.learnerStateValue ,props.answerStateValue)
                        }
 
                    ))
                }
               
                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    draggable: false,
                    search:false,
                    toolbar:false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    }
                }}

                style = {{
                  width:"100%"
                }}

                localization={localizationData}
               
            />
            
        </div>
    );
}
