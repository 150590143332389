import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { createUseStyles } from 'react-jss';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Layout } from '../Layout';
import { AdminUserTable } from '../Table/AdminUserTable';
import * as common from "../../common";
import { User ,APIResponse} from '../../react-app-env';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import {DefaultButton,ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    adminUserIndexRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            fontSize: common.FONT_SIZE.title,
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            height:"50px",
        },
        "& .MuiOutlinedInput-input":{
            padding: "15.5px 14px"
        },
        "& button":{
            fontSize: common.FONT_SIZE.button
        },
        "& .MuiInputBase-input":{
            fontSize: common.FONT_SIZE.mainText
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    examListContainer: {
        position: "relative"
    },
    buttonStyle: {
        marginTop:"3.2rem",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .MuiSvgIcon-root":{
            fontSize:"1.8rem"
        }
    }
});


export interface transitUserValue { 
    pathname: string,
    state :  User
} ;


export const initialUserStateValue:User = {
    id:"",
    name:"" ,
    nameKana:"",
    email:"",
    phoneNumber:"",
    note:"",
    modified:new Date(),
    deleteFlag:false,
    password:""
};


export function AdminUserIndex(props: any) {

    // -----API-----
    // 主催者ユーザーを取得する
    function getAdminUser(args?:any):Promise<APIResponse<User[]>>{
        return api("/api/o-user", "GET",args)
    }



     // -----共通関数の宣言-----
     const {
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();

    
    // -----Handler-----

    //無効化ユーザーを含むかの切り替え
    const switchIncludingInvalid =()=>{

        if(containsInvalid){
            setContainsInvalid(false);
            //無効ユーザを除くデータをAPIで取得
            excludeInvalidUser();
        }else{
            setContainsInvalid(true);
            //無効ユーザも含むデータをAPIで取得
            includeInvalidUser();
        }

    }



    // -----汎用関数の宣言-----

  
    //無効化ユーザを含まない
    const excludeInvalidUser=()=>{
        getAdminUser()
        .then((data:APIResponse<User[]>) => {
            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null){
                setUserStateValue(data.value);
            }

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetAdminUser);
        });
    }

    //無効化ユーザを含む
    const includeInvalidUser = ()=>{
        getAdminUser({showDeleted:true})
        .then((data:APIResponse<User[]>) => {
            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null){
                setUserStateValue(data.value);
            }

        }).catch((err) => {
            alert(common.ResponseMessages.Error_GetAdminUser);
        });
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();




    // -----state-----
    const [userStateValue,setUserStateValue] = React.useState<User[]>([initialUserStateValue]);

    //無効フラグ
    const [isLoaded,setIsLoaded] = React.useState(false);
    const [containsInvalid,setContainsInvalid] = React.useState(false);

    // -----use effefct-----
    React.useEffect(() => {
        // サーバーから認証してデータ取得
        getAdminUser()
        .then((data:APIResponse<User[]>) => {

            if (data.errorCode !== 20000) {
                common.alertError(data.errorTitle , data.errorDetail);
                return;
            }

            if(data.value !== null){
                const adminUserData = data.value ;
                setUserStateValue(adminUserData);
            }

            setIsLoaded(true);

        })
        .catch((err) => {
            alert(common.ResponseMessages.Error_GetAdminUser);
        });

        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, []);
    

 

    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>

        {isLoaded ? 

            <div className={classNames.adminUserIndexRoot}>

                <div className={classNames.upperContents}>

                    <div>
                        <div>{props.match.params.id}</div>
                        <h4>主催者ユーザ一覧</h4>
                    </div>

                </div>


                <div className={classNames.examListContainer}>

                    <ButtonContainer className={classNames.buttonStyle} style={{marginBottom:"1.2rem"}}>
                        <DefaultBackButton className="backBtn" onClick={() => { go("/") }}><ArrowBackIosIcon /><span>&nbsp;戻る</span></DefaultBackButton>
                        <DefaultButton  onClick={() => { go({pathname:"/admin-user/register/0" })  }}><AddCircleOutlineIcon /><span>&nbsp;新規登録</span></DefaultButton>
                    </ButtonContainer>

                    <AdminUserTable containsInvalid={containsInvalid} adminUserData={userStateValue} switchIncludingInvalid={switchIncludingInvalid}/>

                </div>

                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go("/") }}><ArrowBackIosIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;戻る</span ></DefaultBackButton>
                    <DefaultButton  onClick={() => { go({pathname:"/admin-user/register/0" })  }}><AddCircleOutlineIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;新規登録</span></DefaultButton>
                </ButtonContainer>

            </div>

        : <div>Loading...</div>

        }
        </Layout>
    );
}
