import { initialExamStateValue} from '../Exam/Index';


export const editQuestionLinks = [
    {
      text : "戻る",
      to : "/"
    }
  ];
  export const normalLinks = [
    {
      text : "トップ",
      to : "/"
    },
    {
        text : "試験一覧",
        to : "/Exam/Index"
    },
    {
        text : "主催者ユーザ一覧",
        to : "/admin-user/index"
    }
  ]

  export const goExamRegister={
    text: "新規試験登録",
    pathname:"/exam/register/0",
    state:undefined 
  }