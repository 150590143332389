import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Chart from 'react-google-charts'

import { Exam, APIResponse, Learner, AnswerCSV, APIArgsQuestionGet, ExamQuestion } from '../../../react-app-env';

import * as common from "../../../common";



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({

    styleRoot: {
        fontSize: common.FONT_SIZE.mainText,

    }
}));


const HistogramData = [
    ["受験者","正答率"],
    ['0001', 5],
    ['0002', 7],

]

const chartOptions = {
    legend: { position: "none" },
    hAxis: {
        title: '正答率(%)',
        ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80 ,90 ,100]
    },
    // vAxis: {title: '受験者数'},
    histogram: {
        bucketSize: 7,
        minValue: 0,
        maxValue: 100
    }
}

interface Props {
    questionNum:number;
    answerStateValue:AnswerCSV[];
    learnerStateValue:Learner[];
    getCorrectNum:(x: Learner, y: AnswerCSV[]) => number;
}



export function PercentageOfCorrectChart(props:Props) {

    // ---定数------



    // -----共通関数の宣言-----
    const {
        go // 画面遷移 
    } = common.useCommon();
    



    // -----Handler-----


   


    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    // -----関数-----
    const generateChartData = (propsData:Props) => {
        const chartData = sortUsers(propsData.learnerStateValue).map( function(learner){ 
            return ["受験番号：" + String(learner.learnerNumber), Math.round(propsData.getCorrectNum(learner,propsData.answerStateValue) / propsData.questionNum * 100)]
        });
        chartData.unshift(["受験者","正答率"])
        return chartData
    }

    
    const sortUsers = (users:Learner[]) => {
        users.sort(function(a:Learner,b:Learner){
            if (a.learnerNumber > b.learnerNumber ) return 1;
            if (b.learnerNumber > a.learnerNumber ) return -1;
          
            return 0;
        })
        return users
    } 


    
    return (
        <div className={classNames.styleRoot}>
            
            <h2>正答率ヒストグラムチャート</h2>
            <Chart
            width={'100rem'}
            height={'60rem'}
            chartType="Histogram"
            loader={<div>Loading Chart</div>}
            data={generateChartData(props)}
            options={chartOptions}
            rootProps={{ 'data-testid': '5' }}
            />
            
        </div>
    );
}
