import React from 'react';

import { createUseStyles } from 'react-jss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {ButtonContainer,DefaultBackButton}  from '../StylesUI/CommonLayouts';
import { Layout } from '../Layout';
import * as common from "../../common";
import { Exam ,APIResponse ,Learner ,AnswerCSV, CheckCheat } from '../../react-app-env';
import {normalLinks,goExamRegister} from '../NavLinks/NavLinks';
import { initialLearnerStateValue } from '../Learner/Index';
import { initialAnswerStateValue } from '../Csv/ExportAnswer';
import { ResultSummaryTable } from '../Table/ResultSummaryTable';
import { LearnerResultTable } from '../Table/LearnerResultTable';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    resultIndex: {
        fontSize: common.FONT_SIZE.mainText,
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom:"30px",
            fontSize:common.FONT_SIZE.title
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    buttonStyle: {
        marginTop:"3.2rem",
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        },
        "& .MuiSvgIcon-root":{
            fontSize:"1.8rem"
        }
    },
    filterContainer: {
        display:"flex",
        justifyContent: "space-around",
        alignItems:"center",
        "& #learner":{
            width:"20rem"
        }
    }
});




export const initialExamStateValue = {
    id:0,
    name:"" ,
    status:0,
    termType:0,
    shuffleFlag:false,
    numberingType:0,
    acceptStart:"",
    acceptEnd:"",
    executeStart:"" ,
    executeEnd:"",
    executeTime:1,
    learnerMax:1,
    guidanceUrl:"",
    startViewFlag:false,
    startBodyHtml:"",
    endViewFlag:false ,
    endBodyHtml:"",
    allPoints:0,
    passPoints:0,
    deleteFlag:false,
    modified:new Date(),
    choiceDeviceFlag:false,
    afterReadFlag:false,
    acceptExpire:0,
    advanceExplanationVideoUrl:""
};

const HAS_CHEAT = "不正あり";


export function TestResultsIndex() {

    // -----API-----
    // 試験データを取得する
    function getExamData(args?: any):Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    // 受験者データを取得する
    function getLearnerData(args?: any):Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    // 試験データを取得する
    function getAnswerData(args?: any):Promise<APIResponse<AnswerCSV[]>> {
        return api("/api/o-answerCSV", "GET", args)
    }

    // 録画データを取得する
    function getMovieUrl(userName: string, isEnv = false): Promise<APIResponse<{ sas: string; expired: string }>> {
        return api("/api/o-storage", "GET", { userName, isEnv });
    }

    // 不正データを取得する
    function getCheckCheats(executionId: number, userName: string): Promise<APIResponse<CheckCheat[]>> {
        return api("/api/o-learnerCheck", "GET", { executionId, userName });
    }


    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();
    


    // -----Handler-----
    const handleClickSortOrder = () => {
        if (sortOrder === "ASC") setSortOrder("DESC");
        else setSortOrder("ASC");
    }

    function switchOrder(learnerArray: Learner[]) {
        let retVal: Learner[] = [];
        switch (sortOrder) {
            case "ASC":
                retVal = ascSortLearner(learnerArray);
                break;
            case "DESC":
                retVal = descSortLearner(learnerArray);
                break;
        }
        return retVal;
    }

    //降順ソート
    function descSortLearner(learnerArray: Learner[]) {
        return learnerArray.sort(function (a: Learner, b: Learner) {
            return a.learnerNumber.localeCompare(b.learnerNumber) * -1;
        });
    }

    //昇順ソート
    function ascSortLearner(learnerArray: Learner[]) {
        return learnerArray.sort(function (a: Learner, b: Learner) {
            return a.learnerNumber.localeCompare(b.learnerNumber);
        });
    }

    // -----汎用関数宣言-----

    //実際に試験を受けた人数を返す
    const getNumberOfTestedLearner = ()=>{
        let count = 0;
        for (const learner of originLearners) {
            if(learner.executionStartDatetime !== null) count++;
        }
        return count;
    }


    //引数の受験者の総得点を返す
    const getTotalPoints = (learnerNum:string)=>{
        let count = 0;
        for (const answer of answerStateValue) {
            if( answer.learnerNumber === learnerNum ){
                count += answer.points 
            }
        }
        return count;
    }

    //引数の受験者が合格かどうかを返す
    const isPassedLearner = (learnerNum:string)=>{
        return ( getTotalPoints(learnerNum) >= examStateData.passPoints );
    }


    //合格者数を返す
    const getNumberOfSuccessfulLearner = ()=>{
        let count = 0;
        for (const learner of originLearners) {
            if( isPassedLearner(learner.learnerNumber) ) count += 1;
        }
        return count;
    }


    //合格率を返す
    const getPassRate = ()=>{
        let rate = 0;
        const successNum = getNumberOfSuccessfulLearner();
        const testedNum = getNumberOfTestedLearner();

        return successNum / testedNum * 100 ;
    }


    //平均点を返す
    const getAveragePoint = ()=>{
        let average = 0;
        let total = 0;
        let learnerNum = getNumberOfTestedLearner();

        for (const learner of originLearners) {
            total += getTotalPoints(learner.learnerNumber);
        }
        average = total / learnerNum;
        return Math.round(average);
    }

    //合否を通知する
    const resultNotification=(learnerArgs:string[])=>{
        if(!window.confirm("選択したユーザに合否通知を送信しますか？")) return;

    }


     //表示する受験者を絞り込む
     const filterLearner=(event: React.ChangeEvent<{ name?: string; value: any }>)=>{
        event.preventDefault();

        const learners = getFilteredLearners(event.target.value);
        setLearnerStateValue(learners);

        setLearnerFilterTerm(event.target.value)
    };


    const getFilteredLearners=(term:string)=>{
        let learners:Learner[] = [];

        switch (term) {
            case "all":
                learners = originLearners.concat();
                break;
        
            case "passed":
                learners = getPassedLearners();
            break;

            case "failure":
                learners = getFailureLearners();
            break;

            case "illegal":
                learners = getIllegalLearners();
            break;
        }

        return learners;
    }

    const getPassedLearners = ()=>{
        let learners = originLearners.concat();
        return learners.filter( (learner:Learner) => isPassedLearner(learner.learnerNumber) );
    }

    const getFailureLearners = ()=>{
        let learners = originLearners.concat();
        return learners.filter( (learner:Learner) => (!isPassedLearner(learner.learnerNumber) && learner.executionStartDatetime !== null ) );
    }

    const getIllegalLearners = ()=>{
        let learners = originLearners.concat();
        return learners.filter( (learner:Learner) => learner.checkResult === HAS_CHEAT  );
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    
    // -----state-----

    const [isLoaded,setIsLoaded] = React.useState(false);
    const [isExsistAnswerData,setIsExsistAnswerData] = React.useState(false);
    const [examStateData, setExamStateData] = React.useState<Exam>(initialExamStateValue);
    const [learnerStateValue, setLearnerStateValue]= React.useState<Learner[]>([initialLearnerStateValue]);
    const [originLearners, setOriginLearners]= React.useState<Learner[]>([initialLearnerStateValue]);
    const [answerStateValue,setAnswerStateValue]=React.useState<AnswerCSV[]>([initialAnswerStateValue])
    const [learnerFilterTerm,setLearnerFilterTerm] = React.useState("all");
    const [sortOrder, setSortOrder] = React.useState("ASC");


    const learnerFilterForm = 
        <div className={classNames.filterContainer} >
            <Select
                value={learnerFilterTerm}
                onChange={filterLearner}
                label=""
                id="learner"
            >
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={"all"}>すべての受験者を表示</MenuItem>
                {/*<MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={"passed"}>合格者のみを表示</MenuItem>*/}
                {/*<MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={"failure"}>不合格者のみを表示</MenuItem>*/}
                <MenuItem style={{fontSize:common.FONT_SIZE.mainText}} value={"illegal"}>不正判定のある受験者のみを表示</MenuItem>
            </Select>
        </div>




    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {

        //試験データの取得
        getExamData({id:params.examId})
            .then((data:APIResponse<Exam[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle , data.errorDetail);
                    return;
                }

                if(data.value !== null){
                    setExamStateData(data.value[0])
                }

                //受験者データの取得
                getLearnerData({executionId:params.examId})
                    .then(async (data:APIResponse<Learner[]>) => {

                        if (data.errorCode !==20000) {
                            common.alertError(data.errorTitle , data.errorDetail);
                            return;
                        }

                        let learners: Learner[] = [];
                        if (data.value !== null) {
                            learners = data.value;
                            for (let learner of learners) {
                                if (learner.executionEndDatetime !== null) {
                                    //受験済の場合は録画と不正の情報を受験者データに追加
                                    await getMovieUrl(learner.userName).then((sasData: APIResponse<{ sas: string; expired: string }>) => {
                                        if (sasData.value !== null) learner.checkDate = "録画済";
                                    });
                                    await getCheckCheats(learner.executionId, learner.userName).then((cheatData: APIResponse<CheckCheat[]>) => {
                                        if (cheatData.value.length > 0) {
                                            learner.checkResult = HAS_CHEAT;
                                            learner.checkUser = cheatData.value[0].adminUserName ?? "";
                                        }
                                    });
                                }
                            }
                        }

                        //解答データの取得
                        getAnswerData({executionId:params.examId})
                            .then((data:APIResponse<AnswerCSV[]>) => {
                                //解答データが無い時
                                if (data.errorCode === 40403) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    setIsLoaded(true);
                                    return;
                                }
                                else if (data.errorCode !==20000) {
                                    common.alertError(data.errorTitle , data.errorDetail);
                                    return;
                                }
                                
                                if(data.value !== null){
                                    setIsExsistAnswerData(true);
                                    setAnswerStateValue(data.value);
                                } 

                            }).catch((err) => {
                                alert(common.ResponseMessages.Error_GetExam);
                            });

                        return learners;

                    }).then((learners: Learner[] | undefined) => {

                        if (learners && learners.length > 0) {
                            setLearnerStateValue(learners);
                            setOriginLearners(learners);
                        }
                        setIsLoaded(true);

                    }).catch((err) => {
                        alert(common.ResponseMessages.Error_GetLearner);
                });


            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });

    }, []);
 
    

    
    return (
        <Layout navLinks={normalLinks} buttons={
            [
              { text: goExamRegister.text, handler: () => { go({pathname:goExamRegister.pathname,state:goExamRegister.state })} }
            ]
          }>
            {isLoaded ?

                <div className={classNames.resultIndex}>
                    
                    <div className={classNames.upperContents}>

                        <div>
                            <h4>{examStateData.name}&nbsp;試験結果一覧</h4>
                        </div>

                    </div>

                    {
                        isExsistAnswerData 
                        ?
                            <ResultSummaryTable  numberOfLearner={originLearners.length} numberOfTestedLearner={getNumberOfTestedLearner()} numberOfSuccessfulLearner={getNumberOfSuccessfulLearner()} passRate={getPassRate()} averagePoints={getAveragePoint()}></ResultSummaryTable>
                        :
                            <div></div>
                    }

                    <LearnerResultTable examData={examStateData} learnerData={learnerStateValue} resultNotification={resultNotification} getTotalPoints={getTotalPoints} isPassedLearner={isPassedLearner} learnerFilterForm={learnerFilterForm}
                        sortOrder={sortOrder} handleClickSortOrder={handleClickSortOrder} switchOrder={switchOrder}
                    />


                    <ButtonContainer className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" onClick={() => { go("/exam/index") }}><ArrowBackIosIcon /><span style={{paddingBottom:"0.2rem"}}>&nbsp;戻る</span></DefaultBackButton>
                    </ButtonContainer>

                </div>

            : <div>Loading...</div>

            }

        </Layout>
    );
}
