import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Question as IQuestion } from '../../react-app-env';
import TextField from '@material-ui/core/TextField';
import * as common from "../../common";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    questionTable: {
        maxHeight:"600px",
        overflow:"auto",
        "& .MuiInputBase-input ,.MuiTableCell-root":{
            fontSize:common.FONT_SIZE.tableBody
        }
      },
}));

interface Props {
    question: IQuestion[],
    onChangeQuestionPointsHandler: any,
    setQuestionHeadnum: any
}

export function QuestationTable(props:Props) {
  const classNames = useStyles();
    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得


    return (
        <div /*className={classNames.homeRoot}*/>
            <TableContainer component={Paper} className={classNames.questionTable}>
                <Table /*className={classes.table}*/>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" component="th" scope="row" style={{ width: 120 }}><div style={{ color: "#003f71" }}>セクション<br></br>問題番号</div></TableCell>
                            <TableCell component="th" scope="row" style = {{width: 100}}><div style = {{color:"#003f71"}}>配点</div></TableCell>
                            <TableCell align="left" ><div style = {{color:"#003f71"}}>問題文</div></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.question?.filter(q=>q.deleteFlag!==true).map((data:IQuestion) => (
                            <TableRow key={data.id}>
                                <TableCell>
                                    <div>
                                        {data.parentQuestionId !== 0 ? <ArrowRightIcon fontSize={"small"} /> : ""} 
                                        <span style={data.sectionFlag ? { border: "solid 1px", padding: "0px 5px" } : { fontWeight: "bold" }}>{props.setQuestionHeadnum(data, props.question)}</span>
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <div hidden={data.sectionFlag}>
                                        <TextField  style = {{width: "4rem"}}  value={data.points} onChange={(event:React.ChangeEvent<HTMLInputElement>)=>{props.onChangeQuestionPointsHandler(data.id,parseInt(event.target.value));}}/>
                                        点
                                    </div>
                                </TableCell>
                                <TableCell align="left">
                                    <div hidden={data.sectionFlag}>
                                        {data.subjectText}...
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
