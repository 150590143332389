import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import { Localization } from "material-table";

import { uploadLearnerData } from '../Csv/Upload';
import * as common from "../../common";



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    homeRoot: {
        fontSize: common.FONT_SIZE.mainText,
        width:700,
        marginLeft:"auto",
        marginRight:"auto",
        "& .MuiTableCell-root": {
            fontSize: "1.2rem"
        },
        "& .MuiTableCell-head": {
            fontSize: "1.3rem"
        },
        "& .MuiTableCell-alignRight svg": {
            fontSize: "16px"
        },
        "& .MuiIconButton-label svg": {
            fontSize: "20px"
        },
        "& .MuiInput-root":{
            fontSize: "1.2rem"
        }
    }
}));


//material-tableの日本語化変数
const localizationData: Localization = {
    error: "エラー",
    body: {
      emptyDataSourceMessage: "表示するレコードがありません。",
      filterRow: {
        filterTooltip: "フィルター",
      },
      editRow: {
        saveTooltip: "保存",
        cancelTooltip: "キャンセル",
        deleteText: "この行を削除しますか？",
      },
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
    },
    header: {
      actions: "",
    },
    grouping: {
      groupedBy: "グループ化:",
      placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
      firstTooltip: "最初のページ",
      firstAriaLabel: "最初のページ",
      previousTooltip: "前のページ",
      previousAriaLabel: "前のページ",
      nextTooltip: "次のページ",
      nextAriaLabel: "次のページ",
      labelDisplayedRows: "{from}-{to} 全{count}件",
      labelRowsPerPage: "ページあたりの行数:",
      lastTooltip: "最後のページ",
      lastAriaLabel: "最後のページ",
      labelRowsSelect: "行",
    },
    toolbar: {
      addRemoveColumns: "列の追加、削除",
      nRowsSelected: "{0} 行選択",
      showColumnsTitle: "列の表示",
      showColumnsAriaLabel: "列の表示",
      exportTitle: "出力",
      exportAriaLabel: "出力",
      searchTooltip: "検索",
      searchPlaceholder: "検索",
    }
  };


interface Props {
    learnerData: uploadLearnerData[]
}



export function LearnerUploadTable(props: Props) {
    // -----定数の定義-----
    const learnerData: uploadLearnerData[] = props.learnerData ?? [];


    // -----Handler-----



    // -----スタイルの宣言-----
    const classNames = useStyles();



    // -----state-----



    return (
        <div  className={classNames.homeRoot}>

            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                    { title: '受験者ID(受験番号)', field: 'leaenerNum' ,cellStyle:{ fontWeight: "bold" }},
                    { title: 'メールアドレス', field: 'mail' }
                ]}
                data={
                    learnerData.map((data, idx) => (
                        { 
                            leaenerNum: data.learnerNumber, 
                            mail: data.email
                        }
                ))
                }

                style = {{
                    width:"50%",
                    minWidth:"700px"
                }}

                options={{
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment:"left",
                    showTextRowsSelected:false,
                    showTitle: false,
                    //filtering: true, 
                    draggable: false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    }
                }}
                localization={localizationData}
            />

        </div>
    );
}
