import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import { Exam,ExamQuestion,Question } from '../../react-app-env';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import {DefaultButton ,DefaultBackButton} from "../StylesUI/CommonLayouts";
import { makeStyles } from '@material-ui/core/styles';
import * as common from "../../common";

const useStyles = makeStyles((theme) => ({
    editQuestionConfirmRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
    },
    toolbar:{
        color:"white",
        background:"gray",
        minHeight:"36px"
    },
    titleText : {
        margin:"auto",
        fontSize:common.FONT_SIZE.mainText,
        fontWeight:"bold"
    },
    formStyle: {
        height:"70px",
        "& td": {
            width:"260px"
        }
    },
    labelStyle: {
        paddingRight: "60px",
        color:"#003f71"
    },
    buttonStyle: {
        "& button:hover": {
            backgroundColor:"#003f71", 
        },
        "& button:focus": {
            outline:"none !important", 
        },
        "& .backBtn:hover": {
            backgroundColor:"#003f71",
            color:"white" 
        }
    }
}));

export function Confirm(props:{examQuestion:ExamQuestion,question:Question[],onClickSaveHandler:any}) {    
    const classNames = useStyles();

    // コンポーネント関数内でuseStyles呼び出してCSS生成
    // 戻り値で、クラス名を取得
    return (
        <div className={classNames.editQuestionConfirmRoot}>
            <Card>
                <Toolbar className={classNames.toolbar}>
                    <h6 className={classNames.titleText}>
                        保存確認
                    </h6>
                </Toolbar>
                <Box p={1}>

                    <table>
                        <tbody>
                            <tr className={classNames.formStyle}>
                                <th className={classNames.labelStyle}><label>試験名称</label></th>
                                <td> {props.examQuestion.name}</td>
                            </tr>
                            <tr className={classNames.formStyle}>
                                <th className={classNames.labelStyle}><label>問題数</label></th>
                                {/* <td> {props.question.length}</td> */}
                                <td> {props.question.length - props.question.filter(val => {
                                    if(val.sectionFlag) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }).length}</td>
                            </tr>
                            <tr className={classNames.formStyle}>
                                <th className={classNames.labelStyle}><label>出題</label></th>
                                <td> {props.examQuestion.shuffleFlag ? "シャッフル出題" : "固定出題"}</td>
                            </tr>
                            <tr className={classNames.formStyle}>
                                <th className={classNames.labelStyle}><label>合格点</label></th>
                                <td>
                                    {props.examQuestion.questions.filter(q => q.deleteFlag !== true && !q.sectionFlag).reduce((sum, current) => sum + current.points, 0)}
                                        点中　{props.examQuestion.passPoints}点で合格
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{marginTop:"12px"}} className={classNames.buttonStyle}>
                        <DefaultBackButton className="backBtn" style={{marginRight:"8px"}} onClick={()=>{props.onClickSaveHandler(true)}}>下書き保存</DefaultBackButton>
                        <DefaultButton  style={{marginRight:"8px"}} onClick={()=>{props.onClickSaveHandler(false)}}>問題作成完了</DefaultButton>
                    </div>
                </Box>
            </Card>
        </div>
    );
}
