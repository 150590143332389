import { makeStyles } from '@material-ui/core/styles';
import React from 'react';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';


import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Localization } from "material-table";


import * as common from "../../common";
import { Exam } from '../../react-app-env';
import { transitLearnerValue } from '../Learner/Index';
import { transitExamValue, initialExamStateValue } from '../Exam/Index';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    examTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .MuiTableCell-root": {
            fontSize: common.FONT_SIZE.tableBody,
        },
        "& .MuiTableCell-head": {
            fontSize: common.FONT_SIZE.tableHeader
        },
        "& .MuiTableCell-alignRight svg": {
            fontSize: "30px"
        }
    },
    columnName: {
        backgroundColor: "#7c7c80",
        opacity: 0.8
    },
    columnNameStyle: {
        fontWeight: "bold",
        fontSize: common.FONT_SIZE.tableBody,
        color: "#ffffff"
    },
    ordercolumnStyle: {
        fontWeight: "bold",
        fontSize: common.FONT_SIZE.tableHeader,
        color: "#ffffff",
        cursor: "pointer",
        "& span": {
            display: "inline-block",
            verticalAlign: "middle"
        }
    },
    pageNation: {
        "& .MuiTypography-body2 ,.MuiTablePagination-input ,.MuiTablePagination-root": {
            fontSize: common.FONT_SIZE.tablePagenation
        }
    },
    contextMenu: {
        "& .MuiMenuItem-root": {
            fontSize: common.FONT_SIZE.tableBody,
        }
    },
    checkBoxContainer: {
        display: "flex",
        alignItems: "center",
        fontSize: common.FONT_SIZE.mainText,
        "& svg": {
            fontSize: 28
        },
        "& span": {
            paddingLeft: "0"
        }
    }
}));


//material-tableの日本語化変数
const localizationData: Localization = {
    error: "エラー",
    body: {
        emptyDataSourceMessage: "表示するレコードがありません。",
        filterRow: {
            filterTooltip: "フィルター",
        },
        editRow: {
            saveTooltip: "保存",
            cancelTooltip: "キャンセル",
            deleteText: "この行を削除しますか？",
        },
        addTooltip: "追加",
        deleteTooltip: "削除",
        editTooltip: "編集",
    },
    header: {
        actions: "",
    },
    grouping: {
        groupedBy: "グループ化:",
        placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
        firstTooltip: "最初のページ",
        firstAriaLabel: "最初のページ",
        previousTooltip: "前のページ",
        previousAriaLabel: "前のページ",
        nextTooltip: "次のページ",
        nextAriaLabel: "次のページ",
        labelDisplayedRows: "{from}-{to} 全{count}件",
        labelRowsPerPage: "ページあたりの行数:",
        lastTooltip: "最後のページ",
        lastAriaLabel: "最後のページ",
        labelRowsSelect: "行",
    },
    toolbar: {
        addRemoveColumns: "列の追加、削除",
        nRowsSelected: "{0} 行選択",
        showColumnsTitle: "列の表示",
        showColumnsAriaLabel: "列の表示",
        exportTitle: "出力",
        exportAriaLabel: "出力",
        searchTooltip: "検索",
        searchPlaceholder: "試験名称検索",
    }
};


const initialState = {
    mouseX: null,
    mouseY: null,
};

type orderFunc = (examArray: Exam[]) => Exam[]

interface Props {
    containsFinished?: boolean,
    yearMonthSelectForm?: any,
    examData: Exam[],
    getNumberOfLearner: any,
    numberOfLearner: number,
    switchOrder: orderFunc,
    handleClickSortOrder: any,
    sortOrder: string,
    switchIncludingTested?: any
}

interface tableRecord {
    examName: string;
    executeDate: string;
    executeTime: string;
    examStatus: string;
    examId: number;
}


export function ExamTable(props: Props) {
    // -----定数の定義-----
    const examData: Exam[] = props.examData ?? [];

    const isTopPage = props.switchIncludingTested === undefined

    // -----共通関数の宣言-----
    const {
        go
    } = common.useCommon();



    // -----Handler-----

    const handleClose = () => {
        setState(initialState);
        setSelectedExam(initialExamStateValue);
    };

    const handleClickContextMenu = (event: any, examId: number) => {
        event.preventDefault();

        const examData = getExamDataById(examId);
        setSelectedExam(examData);

        if (checkAfterTheStart(examData)) {
            setIsAfterTheStart(true)
        }
        else {
            setIsAfterTheStart(false)
        };

        if (checkAfterTheEnd(examData)) {
            setIsAfterTheEnd(true)
        }
        else {
            setIsAfterTheEnd(false)
        };

        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });

        props.getNumberOfLearner(examId);

    }

    const getExamDataById = (examId: number) => {
        const retValExam = examData.filter(exam => exam.id === examId);
        return retValExam[0];
    }


    const checkAfterTheStart = (examData: Exam) => {
        const d1 = new Date(examData.executeStart);
        const d2 = new Date();
        const time = d2.getTime() - d1.getTime();

        const minutes = Math.floor(time / (1000 * 60));
        if (minutes < 0) return false;
        return true;
    }


    const checkAfterTheEnd = (examData: Exam) => {
        const d1 = new Date(examData.executeEnd);
        const d2 = new Date();
        const time = d2.getTime() - d1.getTime();

        if (time < 0) return false;
        return true;
    }


    // -----汎用関数定義-----

    const goEditQuestion = () => {
        const selectedExamId = String(selectedExam.id);
        go("/edit-question/" + selectedExamId + "/start-screen");
    }


    function getTransitLearnerValue(url: string): transitLearnerValue {
        const info = {
            pathname: url,
            state: {
                userName: "",
                executionId: selectedExam.id,
                learnerNumber: "",
                email: "",
                acceptDatetime: "",
                noticeDatetime: "",
                executionStartDatetime: "",
                executionEndDatetime: "",
                checkDate: "",
                checkResult: "",
                modified: new Date(),
                checkUser: "",
                deleteFlag: false
            }
        };
        return info;
    }



    function getTransitExamValue(url: string): transitExamValue {
        const info = {
            pathname: url,
            state: {
                id: selectedExam.id,
                name: selectedExam.name,
                status: selectedExam.status,
                termType: selectedExam.termType,
                shuffleFlag: selectedExam.shuffleFlag,
                numberingType: selectedExam.numberingType,
                acceptStart: selectedExam.acceptStart,
                acceptEnd: selectedExam.acceptEnd,
                executeStart: selectedExam.executeStart,
                executeEnd: selectedExam.executeEnd,
                executeTime: selectedExam.executeTime,
                learnerMax: selectedExam.learnerMax,
                guidanceUrl: selectedExam.guidanceUrl,
                startViewFlag: selectedExam.startViewFlag,
                startBodyHtml: selectedExam.startBodyHtml,
                endViewFlag: selectedExam.endViewFlag,
                endBodyHtml: selectedExam.endBodyHtml,
                allPoints: selectedExam.allPoints,
                passPoints: selectedExam.passPoints,
                deleteFlag: selectedExam.deleteFlag,
                modified: selectedExam.modified,
                choiceDeviceFlag: selectedExam.choiceDeviceFlag,
                afterReadFlag: selectedExam.afterReadFlag,
                acceptExpire: selectedExam.acceptExpire,
                advanceExplanationVideoUrl: selectedExam.advanceExplanationVideoUrl
            }
        };
        return info;
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();




    // -----state-----
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);

    const [selectedExam, setSelectedExam] = React.useState<Exam>(initialExamStateValue);
    const [isAfterTheStart, setIsAfterTheStart] = React.useState(false);
    const [isAfterTheEnd, setIsAfterTheEnd] = React.useState(false);





    return (
        <div className={classNames.examTableRoot} >

            <MaterialTable
                icons={{
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                }}
                columns={[
                    { title: '試験名称', field: 'examName', sorting: false, cellStyle: { fontWeight: "bold" } },
                    {
                        title: '試験期間', field: 'executeDate', searchable: false,
                        customSort: () => props.handleClickSortOrder()
                    },
                    { title: '試験時間', field: 'executeTime', sorting: false, searchable: false },
                    { title: '試験問題', field: 'examStatus', sorting: false, searchable: false },

                ]}
                data={
                    props.switchOrder(examData).map((data, idx) => (
                        {
                            examName: data.name,
                            executeDate: common.dateFormat(data.executeStart, "LLL") + "～" + common.dateFormat(data.executeEnd, "LLL"),
                            executeTime: data.executeTime + "分",
                            examStatus: data.status === 1 ? "正書" : "下書き",
                            examId: data.id,
                        }
                    ))
                }
                actions={[
                    {
                        icon: () => <MoreHorizIcon />,
                        tooltip: 'メニューを開く',
                        onClick: (event, rowData: any) => handleClickContextMenu(event, rowData.examId),
                        position: "row"
                    },
                    {
                        icon: () => isTopPage ? {} : props.yearMonthSelectForm,
                        isFreeAction: true,
                        onClick: () => { },
                        hidden: props.switchIncludingTested === undefined
                    },
                    {
                        icon: () => <div className={classNames.checkBoxContainer}>
                            <Checkbox checked={isTopPage ? false : props.containsFinished} />
                            終了した試験を含む
                        </div>,
                        isFreeAction: true,
                        onClick: () => isTopPage ? {} : props.switchIncludingTested(),
                        hidden: props.switchIncludingTested === undefined
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    draggable: false,
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        marginLeft: 16
                    },
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight: "bold"
                    }
                }}
                localization={localizationData}
            />



            <Menu
                className={classNames.contextMenu}
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <MenuItem disabled={isAfterTheStart} onClick={() => { go(getTransitExamValue("/exam/edit/" + selectedExam.id)) }}>試験の編集</MenuItem>
                <MenuItem disabled={isAfterTheStart} onClick={() => { go(getTransitExamValue("/exam/confirm/delete")) }}>試験の削除</MenuItem>
                <MenuItem disabled={isAfterTheStart && !isAfterTheEnd} onClick={() => { go(getTransitLearnerValue("/learner/index/" + selectedExam.id)) }}>受験者一覧</MenuItem>
                <MenuItem disabled={isAfterTheStart} onClick={() => { selectedExam.learnerMax > props.numberOfLearner ? go(getTransitLearnerValue("/learner/register/" + selectedExam.id + "/0")) : alert("最大受験者数に達しています") }}>受験者新規登録</MenuItem>
                <MenuItem disabled={isAfterTheEnd} onClick={() => { goEditQuestion() }}>問題・解答の編集</MenuItem>
                <MenuItem disabled={selectedExam.status === 1/*正書*/ || isAfterTheEnd} onClick={() => { go("/importQuestion/" + selectedExam.id) }}>問題インポート</MenuItem>
                <MenuItem disabled={!isAfterTheEnd || selectedExam.status === 0/*下書き*/} onClick={() => { go("/csv/ExportAnswer/" + selectedExam.id) }}>解答データのエクスポート</MenuItem>
                <MenuItem disabled={!isAfterTheEnd || selectedExam.status === 0} onClick={() => { go("/csv/tkccExportAnswer/" + selectedExam.id) }}>解答データのエクスポート(TKCC様)</MenuItem>
                <MenuItem disabled={!isAfterTheEnd || selectedExam.status === 0} onClick={() => { go("/test-results/index/" + selectedExam.id) }}>試験結果</MenuItem>
                <MenuItem disabled={!isAfterTheEnd || selectedExam.status === 0} onClick={() => { go("/test-results-analysis/" + selectedExam.id) }}>成績集計分析</MenuItem>
            </Menu>
        </div>
    );
}
