import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import * as common from "../../common";
import {Exam, ExamQuestion, Question as IQuestion } from '../../react-app-env';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import { Divider, withWidth } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import {DefaultButton ,ButtonContainer} from "../StylesUI/CommonLayouts";
import { CustomModal } from "../StylesUI/CustomModal"
import { makeStyles } from '@material-ui/core/styles';
import MediaQuery from "react-responsive";

const useStyles = makeStyles((theme) => ({
  editQuestionSideBarRoot: {
    "& svg":{
        fontSize: common.FONT_SIZE.button
    },
    "& .MuiTypography-body1":{
      fontSize: common.FONT_SIZE.sideBarText,
    },
    fontSize: common.FONT_SIZE.sideBarText,
  },
  hasParentQuestion: {
    paddingLeft: "16px"
  },
  sideBarItem:{
    display:"flex",
    // background: "white"
  },
  activePage:{
    background: "#7fb911",
    color:"white",
    cursor: "pointer"
  },
  disabled:{
    background: "lightgray"
  },
  btn_addQuestion:{
    display: "block",
    margin: "0 0 0 auto",
    minWidth:"5rem"
  },
  sideBar: {
    maxHeight:"600px",
    overflow:"auto",
    color:"#003f71",
    lineBreak: 'anywhere'
  },
  sideBarText : {
    fontSize:common.FONT_SIZE.sideBarText,
  },
  toolbar:{
    color:"white",
    background:"gray",
    minHeight:"36px"
  },
  titleText : {
    margin:"auto",
    fontSize:common.FONT_SIZE.mainText,
    fontWeight:"bold"
  },
  btn_inListitem:{
    padding:"0px 18px 2px",
    margin:"1rem 0",
    fontSize:"1rem",
    minWidth:"5rem"
  },
  buttonStyle: {
    "& button:hover": {
      backgroundColor:"#003f71", 
    },
    "& button:focus": {
      outline:"none !important", 
    },
    "& button:disabled": {
      backgroundColor: "#a6a6a6",
      border: "solid 1px #a6a6a6",
    }
  },
  sideBarHeaderNum:{
    "& #headNum":{
      fontWeight:"bold"
    }
  },
  questionEditIcon:{
    textAlign:"right",
    margin:"auto 8px",
  },
  changeOrder_buttonIconStyle: {
    "& button": {
      backgroundColor: "#e97132",
      border: "solid 1px #e97132",
    },
    "& button:hover": {
      backgroundColor: "#c04f15",
    },
    "& button:focus": {
      outline: "none !important",
    }
  },
  modalText: {
    fontSize: "2rem",
    fontWeight: "bold",
    padding: "2rem 0",
    textAlign: "center",
  }
}));

export function SideBar(props: { exam: ExamQuestion, question: IQuestion[], addNewSectionQuestionHandler: any, deleteQuestionHandler: any, addNewQuestionHandler: any, addNewSectionHadnler: any, setQuestionHeadnum: any, focusUpdate: any, onChangeQuestionOrder: any, onChangeReorderChoiceFlag: any}) {
  const classNames = useStyles();

  const {
    history,
    params,
    getUser, // ログインユーザー取得
    go, // 画面遷移 
    logout, // 強制ログアウト
    api  // API呼び出し
} = common.useCommon();

  const activeItemKey = (params.category === "question" || params.category === "section") ? params.id.toString() : params.category;
  const [state, setState] = React.useState({ activeItemKey: activeItemKey });
  const [reorderFlag, setReorderFlag] = React.useState(false);

  //モーダル関係
  const [openModal, setOpenModal] = React.useState(false);
  const [modalFunction, setModalFunction] = React.useState<any>();
  const [modalText, setModalText] = React.useState<JSX.Element>(<div />);

  // モーダル表示
  const openConfirmModal = (executionFunc: any, modalText: JSX.Element) => {
    setModalFunction(() => executionFunc);
    setModalText(modalText);
    setOpenModal(true);
  }

  // 問題の削除ボタン押下モーダル
  const confirmModalElement = <div className={classNames.modalText}>
    <p>問題を削除しますか？</p>
  </div>

  if (activeItemKey !== state.activeItemKey){
    setState({activeItemKey:activeItemKey});
  }
  // 戻り値で、クラス名を取得
  const questions = props.question;

  const onClickListItem=(listItemKey:string,url:string)=>{
    setState({activeItemKey: listItemKey});
  
    props.focusUpdate();
    props.onChangeReorderChoiceFlag(false);
    
    go(url);
  }

  const getSideBarItemClasName = (id : string, activeKey:string, disabled:boolean) =>{
    if (id.toString() === activeKey){
      return classNames.activePage;
    }

    return disabled ? classNames.disabled : "";
  }

  // 問題リストの順序入れ替え
  const moveQuestion = (questionId: number, moveUp: boolean, sectionFlag: boolean) => {
    sectionFlag ? onClickListItem(questionId.toString(), "/edit-question/" + props.exam.executionId + "/section/" + questionId) : onClickListItem(questionId.toString(), "/edit-question/" + props.exam.executionId + "/question/" + questionId);
    props.onChangeQuestionOrder(questionId, moveUp);
  }

  let contents = questions.length === 0
  ? <p></p> : questions.map((question:IQuestion, index:number) => (
    <Box key={question.id} className={getSideBarItemClasName(question.id.toString(), state.activeItemKey,question.sectionFlag && !question.startViewFlag ? true : false)}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
                                                                                                                                                                 
        <ListItem button className={question.parentQuestionId !== -1 ? classNames.hasParentQuestion : ""} onClick={() => { question.sectionFlag ?  onClickListItem(question.id.toString(), "/edit-question/" + props.exam.executionId+"/section/" + question.id) : onClickListItem(question.id.toString(),"/edit-question/" + props.exam.executionId+"/question/" + question.id) }}>
          <Grid container className={classNames.sideBarHeaderNum}>

            <MediaQuery query="(min-width: 1200px)">
              <Grid item xs={5} id={"headNum"}>
                {question.parentQuestionId !==0 ? <ArrowRightIcon fontSize={"small"}/>:"" } 
                <span style={question.sectionFlag ? {border: "solid 1px", padding: "0px 5px"} : { fontWeight: "bold" }}>{ props.setQuestionHeadnum(question,props.question) }</span>
              </Grid>
              <Grid item xs={7} > {question.subjectText + "..."} </Grid>
            </MediaQuery>

            <MediaQuery query="(max-width: 1200px)">
              <Grid item xs={5} id={"headNum"}>
                {question.parentQuestionId !==0 ? <ArrowRightIcon fontSize={"small"}/>:"" } 
                <span style={question.sectionFlag ? {border: "solid 1px", padding: "0px 5px"} : { fontWeight: "bold" }}>{ props.setQuestionHeadnum(question,props.question) }</span>
              </Grid>
              <Grid item xs={7} > {question.subjectText + "..."} </Grid>
            </MediaQuery>

          </Grid>   
        </ListItem>

          <div className={classNames.questionEditIcon}>
            {
              reorderFlag ?
                <>
                  <ButtonContainer className={classNames.changeOrder_buttonIconStyle}> <DefaultButton className={classNames.btn_inListitem} onClick={() => moveQuestion(question.id, true, question.sectionFlag)} style={{ marginBottom: "2px", fontSize: "1rem" }}><ArrowDropUpIcon /></DefaultButton></ ButtonContainer>
                  <ButtonContainer className={classNames.changeOrder_buttonIconStyle}> <DefaultButton className={classNames.btn_inListitem} onClick={() => moveQuestion(question.id, false, question.sectionFlag)} style={{ marginTop:"2px", fontSize: "1rem" }}><ArrowDropDownIcon /></DefaultButton></ ButtonContainer>
                </> :
                <>
                  {question.sectionFlag === true ? <ButtonContainer className={classNames.buttonStyle}> <DefaultButton className={classNames.btn_inListitem} onClick={(event) => { props.addNewSectionQuestionHandler(question.id) }} style={{ marginBottom: "2px", fontSize: "1rem" }}><AddIcon fontSize={"small"} /></DefaultButton></ ButtonContainer> : "" }
                  <ButtonContainer className={classNames.buttonStyle}> <DefaultButton style={{ fontSize: "1rem" }} className={classNames.btn_inListitem} onClick={() => { openConfirmModal(() => { props.deleteQuestionHandler(question.id)}, confirmModalElement) }} ><DeleteOutlineOutlinedIcon fontSize={"small"} /></DefaultButton></ ButtonContainer>
                </>
            }
          </div>
        </div>
        <Divider />
      </Box>
    ));

return (
  <div className={classNames.editQuestionSideBarRoot}>

    <Card>
      <Toolbar className={classNames.toolbar}>
        <h6 className={classNames.titleText}>
            問題リスト
        </h6>
      </Toolbar>
      <Box p={1} display={"flex"} flexDirection={"row-reverse"} style={{minWidth: "180px"}}>
        <Box className={reorderFlag ? classNames.changeOrder_buttonIconStyle : classNames.buttonStyle}>
          <DefaultButton onClick={() => setReorderFlag(!reorderFlag)} style={{display:"block", margin:"0 0 0 5px", minWidth: "4rem"} }>{reorderFlag ? <CloseIcon/> : <SwapVertIcon />}</DefaultButton>
        </Box>
        <Box marginLeft={1} className={classNames.buttonStyle}>
          <DefaultButton onClick={props.addNewSectionHadnler} className={classNames.btn_addQuestion} style={{ fontSize: "1.4rem" }} disabled={reorderFlag }>セクション追加</DefaultButton>
        </Box>
        <Box className={classNames.buttonStyle}>
          <DefaultButton onClick={props.addNewQuestionHandler} className={classNames.btn_addQuestion} style={{ fontSize: "1.4rem" }} disabled={reorderFlag }>問題追加</DefaultButton>
        </Box>
      </Box>

      <List className={classNames.sideBar}>
        <Divider />
        <ListItem style={{cursor: "pointer"}} key="startScreen" onClick={() => { onClickListItem("startScreen","/edit-question/" + props.exam?.executionId+"/start-screen") }} className={getSideBarItemClasName("start-screen", state.activeItemKey,!props.exam?.startViewFlag)}>
            <ListItemText primary="開始画面"  />
        </ListItem>
        <Divider />
        {contents}
        <ListItem style={{cursor: "pointer"}} key="endScreen" onClick={() => { onClickListItem("endScreen", "/edit-question/" + props.exam?.executionId+"/end-screen") }} className={getSideBarItemClasName("end-screen", state.activeItemKey,!props.exam?.endViewFlag)}>
          <ListItemText primary="終了画面"  />
        </ListItem>
        <Divider />
      </List>
    </Card>

    <CustomModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalFunc={modalFunction}
    >
      {modalText}
    </CustomModal>

  </div>
  );
}
