import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Localization } from "material-table";


import * as common from "../../common";
import { User } from '../../react-app-env';


// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    adminUserTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& .MuiTableCell-root":{
            fontSize:common.FONT_SIZE.tableBody
        },
        "& .MuiTableCell-head":{
            fontSize:common.FONT_SIZE.tableHeader
        },
        "& .MuiMenuItem-root ":{
            fontSize:common.FONT_SIZE.tablePagenation
        },
        // "& .MuiTableRow-hover":{
        //     "& :hover":{
        //         backgroundColor: "rgba(245, 0, 87, 0.08)"
        //     }
        // }
    },
    checkBoxContainer:{
        display:"flex",
        alignItems:"center",
        fontSize:common.FONT_SIZE.mainText,
        "& svg":{
            fontSize:28
        },
        "& span":{
            paddingLeft:"0"
        }
    }
}));


//material-tableの日本語化変数
const localizationData: Localization = {
    error: "エラー",
    body: {
      emptyDataSourceMessage: "表示するレコードがありません。",
      filterRow: {
        filterTooltip: "フィルター",
      },
      editRow: {
        saveTooltip: "保存",
        cancelTooltip: "キャンセル",
        deleteText: "この行を削除しますか？",
      },
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
    },
    header: {
      actions: "",
    },
    grouping: {
      groupedBy: "グループ化:",
      placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
      firstTooltip: "最初のページ",
      firstAriaLabel: "最初のページ",
      previousTooltip: "前のページ",
      previousAriaLabel: "前のページ",
      nextTooltip: "次のページ",
      nextAriaLabel: "次のページ",
      labelDisplayedRows: "{from}-{to} 全{count}件",
      labelRowsPerPage: "ページあたりの行数:",
      lastTooltip: "最後のページ",
      lastAriaLabel: "最後のページ",
      labelRowsSelect: "行",
    },
    toolbar: {
      addRemoveColumns: "列の追加、削除",
      nRowsSelected: "{0} 行選択",
      showColumnsTitle: "列の表示",
      showColumnsAriaLabel: "列の表示",
      exportTitle: "出力",
      exportAriaLabel: "出力",
      searchTooltip: "検索",
      searchPlaceholder: "氏名検索",
    }
  };



interface Props {
    containsInvalid:boolean,
    adminUserData: User[],
    switchIncludingInvalid:any
}

interface tableRecord {
    name: string;
    phoneNum: string;
    note: string;
    adminUserId: string;
    isInvalid:boolean
}


export function AdminUserTable(props:Props) {

    // ---定数------
    const adminUserData = props.adminUserData ?? [];



    // -----共通関数の宣言-----
    const {
        go // 画面遷移 
    } = common.useCommon();
    



    // -----Handler-----


    //主催者ユーザ変更ページへ遷移
    function handleClick(id:string){
        const adminUser = getAdminUserDataById(id);
        const info={ 
            pathname: "/admin-user/edit/"+adminUser.id,
            state : { 
                id:adminUser.id ?? "",
                name:adminUser.name ?? "",
                nameKana:adminUser.nameKana ?? "",
                email:adminUser.email ?? "",
                phoneNumber:adminUser.phoneNumber ?? "",
                note:adminUser.note ?? "",
                modified:adminUser.modified ?? "",
                deleteFlag:adminUser.deleteFlag,
                password:adminUser.password
                    } 
            } ;

        go(info);
    }

    const getAdminUserDataById = (userId:string)=>{
        const retValUser = adminUserData.filter( user => user.id === userId );
        return retValUser[0];
    }



    // -----スタイルの宣言-----
    const classNames = useStyles();


    

    // -----state-----


    
    return (
        <div className={classNames.adminUserTableRoot}>
 
            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                { title: '氏名', field: 'name' ,cellStyle:{ fontWeight: "bold" } },
                { title: '電話番号', field: 'phoneNum' ,sorting: false ,searchable:false},
                { title: '役割', field: 'note' , sorting: false ,searchable:false}
                
                ]}
                data={
                    adminUserData.map((data, idx) => (
                    { 
                        name: data.name, 
                        phoneNum: data.phoneNumber, 
                        note: data.note,
                        adminUserId:data.id,
                        isInvalid:data.deleteFlag
                    }
                    ))
                }
                actions={[
                    {
                      icon: ()=> <div className={classNames.checkBoxContainer}>
                                      <Checkbox  checked={ props.containsInvalid }/>
                                      無効ユーザを含む
                                  </div>,
                      isFreeAction: true,
                      onClick: () => props.switchIncludingInvalid()
                    }
                ]}
                onRowClick={(event,rowData:any) => {
                    handleClick(rowData.adminUserId);
                }}
                options={{
                    actionsColumnIndex: -1,
                    showTitle: false,
                    draggable: false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    },
                    rowStyle: rowData => (
                        rowData.isInvalid ?
                        {
                            backgroundColor: 'silver'
                        } : {}
                        
                    )
                }}
                localization={localizationData}
               
            />


        </div>
    );
}
