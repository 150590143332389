import AppBar from '@material-ui/core/AppBar/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import MediaQuery from "react-responsive";

import { Link as RouterLink } from 'react-router-dom';
import { AppContext } from "../App";
import { APP_DISP_NAME } from '../common';
import { LoginMenu } from './api-authorization/LoginMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
  }),
);

/**
 * 全体のレイアウト
 * @param props 
 */
export function Layout(props: {
  navLinks?: { text: string, to: string }[];
  buttons?: { text: string, handler: (event: React.MouseEvent) => void }[];
  children: NonNullable<React.ReactNode>;
  headerRemainingTime?: any;
  headerSlider?: any;
  width?: "auto" | string,
  fullScreen?: any
}) {
  const { appContext } = React.useContext(AppContext);
  const classes = useStyles();

  // navBarリンクを生成
  const links = React.useMemo(() => {
    let links = [
      <Link key={0} color="inherit" component={RouterLink} to="/">トップ</Link>,
      <Link key={1} color="inherit" component={RouterLink} to="/exam/Index">試験一覧</Link>,
      <Link key={2} color="inherit" component={RouterLink} to="/admin-user/Index">主催者ユーザ一覧</Link>
    ];
    if (typeof(props.navLinks) !== "undefined") {
      links = props.navLinks.map((x, index) => {
        const key = "" + index + "_" + Math.random().toString();
        if (x.to === "") {
          return <p key={key}>{x.text.length > 35 ? x.text.substring(0,35) + "..." : x.text}</p>;
        }
        else {
          return <Link key={key} color="inherit" component={RouterLink} to={x.to}>{x.text.length > 35 ? x.text.substring(0, 35) + "..." : x.text}</Link>;
        }
      });
    }
    return <Grid container style={{flexWrap: "nowrap"}}>{links}</Grid>;
  }, [props.navLinks]);

  // ボタンを生成
  const buttons = React.useMemo(() => {
    if (typeof (props.buttons) !== "undefined") {
      return props.buttons.map((x, index) => {
        const key = "" + index + "_" + Math.random().toString();
        return (<Grid key={key} item><Button className="navBarRight" variant="contained" onClick={x.handler}>{x.text}</Button></Grid>);
      });
    } else {
      return [];
    }
  }, [props.buttons]);


  let mainStyle = {};
  let questionFlag = false;
  switch (props.width) {
    case undefined:
      mainStyle = {minWidth:"944px"};
      break;
    case "auto":
      mainStyle = {minWidth:"944px"};
      break;
    case "question":
      mainStyle = { margin: "70px auto 0px", width: "100%", height: "100%" ,minWidth:"944px"};
      questionFlag = true;
      break;
    default:
      mainStyle = { margin: "140px auto", width: props.width ,minWidth:"944px"}
      break;
  }

  const mainQuestion = { margin: "140px auto 0px", width: "100%" ,minWidth:"944px"};
  const questionContainer = { backgroundColor:"white" ,height: "100%"};

  const isExsistRemainingTime = props.headerRemainingTime === (undefined || "") ? false : true;
  const isExsistSlider = props.headerSlider === (undefined || "") ? false : true;

  /* 上部ナビゲーション */
  const naviMenu = 
    <Grid container justify="space-between" id="navBar" style={{minWidth:"1024px", flexWrap:"nowrap"}}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb" separator=" ">
          {links}
        </Breadcrumbs>
      </Grid>
      {isExsistRemainingTime ? 
        <div>
          <Grid item>
            <Grid container className="buttonZone" style={{ flexWrap: "nowrap" }}>
              {buttons}
            </Grid>
          </Grid>
          {/*<Grid item >
            {props.headerRemainingTime}
          </Grid>*/}
          <Grid item >
            <Grid container>
                <Grid item hidden={!isExsistRemainingTime} style={{ marginRight: "10px" }}>
                    {props.headerRemainingTime}
                </Grid>
                <Grid>
                    {props.fullScreen}
                </Grid>
            </Grid>
          </Grid>
        </div>
        :
        <Grid item>
          <Grid container className="buttonZone" style={{ flexWrap: "nowrap" }}>
            {buttons}
          </Grid>
        </Grid>
      }   
    </Grid>

  

  return (
    <div style={ questionFlag ? questionContainer : {} }>

      {/* ヘッダー */}
      <AppBar position="static" >
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <div id="siteTitle">
                <Link component={RouterLink} color="inherit" to="/">
                  {APP_DISP_NAME}
              </Link>
              </div>
            </Grid>

            <Grid item>
              <LoginMenu shouldShowUserName = {true} />
            </Grid>
            
          </Grid>
        </Toolbar>

        { naviMenu }

        
        {isExsistSlider && props.headerSlider}

      </AppBar>

      {/* メイン */}
      {questionFlag ?
          <div style={mainQuestion}>
            {props.children}
          </div>
          :
        <div id="main" style={mainStyle}>
          <div id="mainCon" >
            {props.children}
          </div>
        </div>
      }

      {/* 処理中表示用オーバーレイ */}
      <Backdrop className={classes.backdrop} open={appContext.backdropIsopen} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>
  );
}
